export type APIBadgeProps = {
  icon: StatEntry["cabinet"]["type"];
  color: string;
  label: string | number;
};

export const APIBadge = ({ icon, color, label }: APIBadgeProps) => {
  return (
    <div className="p-0.5 flex flex-col justify-center items-center">
      <img
        src={`/icons/ad_cabinets/${icon.toLowerCase()}.svg`}
        className={`fill-${color} w-4 h-4`}
        alt={`${icon} icon`}
      ></img>
      <span className="block mt-0.5 text-[0.6rem]">{label}</span>
    </div>
  );
};
