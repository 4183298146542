export const QUERY_ACCESS_TOKEN = "auth/api/v1/login/";
export const QUERY_REFRESH_TOKEN = "auth/api/v1/refresh";
export const QUERY_RESET_PASSWORD = "auth/api/v1/users/reset-password";
export const QUERY_VERIFY_TOKEN = "auth/api/v1/users/verify-token";
export const QUERY_CHANGE_PASSWORD_WITH_TOKEN =
  "auth/api/v1/users/change-password-with-token";
export const QUERY_ACTIVATE_WITH_TOKEN = "auth/api/v1/activate-user";
export const QUERY_AGENCY_DIRECTOR_REGISTER =
  "auth/api/v1/users/register-agency-director";

export const QUERY_CREATE_SYSTEM_ADMIN =
  "auth/api/v1/users/create-system-admin";
export const QUERY_READ_SYSTEM_ADMIN = "auth/api/v1/users/system-admin";
export const QUERY_UPDATE_SYSTEM_ADMIN =
  "auth/api/v1/users/system-admin-status";
export const QUERY_DELETE_SYSTEM_ADMIN = "auth/api/v1/users";

export const QUERY_READ_AGENCY_BY_DIRECTOR =
  "/dashboard/api/v1/agency/get-my-agency";
export const QUERY_READ_AGENCY_BY_MANAGER =
  "/dashboard/api/v1/agency/get-my-agency-by-manager";
export const QUERY_CREATE_AGENCY = "dashboard/api/v1/agency/create";
export const QUERY_GET_AGENCIES = "/dashboard/api/v1/agency";
export const QUERY_UPDATE_AGENCY = "/dashboard/api/v1/agency";
export const QUERY_UPDATE_AGENCY_STATUS = "/dashboard/api/v1/agency/status";
export const QUERY_DELETE_AGENCY = "/dashboard/api/v1/agency";
export const QUERY_APPROVE_AGENCY = "/dashboard/api/v1/agency/approve-agency";
export const QUERY_CREATE_AGENCY_BY_SYS_ADMIN =
  "/dashboard/api/v1/agency/create-by-sys-admin";

export const QUERY_CREATE_CLIENT = "/dashboard/api/v1/client/create";
export const QUERY_READ_CLIENTS = "/dashboard/api/v1/client/all";
export const QUERY_READ_CLIENTS_BY_AGENCY =
  "/dashboard/api/v1/client/all/by-agency";
export const QUERY_READ_CLIENTS_BY_BIN = "/dashboard/api/v1/client/all/by-bin";
export const QUERY_UPDATE_CLIENT_STATUS = "/dashboard/api/v1/client/status";
export const QUERY_UPDATE_CLIENT = "/dashboard/api/v1/client";
export const QUERY_DELETE_CLIENT = "/dashboard/api/v1/client";

export const QUERY_BRANDS = "/dashboard/api/v1/brand/";

export const QUERY_READ_CONNECTORS_BY_CLIENT =
  "/integration/api/v1/connectors/clients";
export const QUERY_READ_CONNECTORS_BY_AGENCY =
  "/dashboard/api/v1/client/all/with-api";
export const QUERY_CREATE_CREDS = "/integration/api/v1/credentials/";
export const QUERY_CREATE_CONNECTOR = "/integration/api/v1/connector/";
export const QUERY_UPDATE_CONNECTOR_STATUS = "/integration/api/v1/connector/";
export const QUERY_UPDATE_CONNECTOR = "/integration/api/v1/connector/";
export const QUERY_DELETE_CONNECTOR = "/integration/api/v1/connector/";

export const QUERY_READ_STATS = "/integration/api/v1/statistics";

export const QUERY_READ_ALL_USERS_BY_AGENCY =
  "/dashboard/api/v1/agency-users/all";
export const QUERY_CREATE_USER_IN_AGENCY =
  "/dashboard/api/v1/agency-users/add-user";
export const QUERY_DELETE_USER_IN_AGENCY = "/dashboard/api/v1/agency-users";
export const QUERY_UPDATE_USER_IN_AGENCY =
  "/dashboard/api/v1/agency-users/clients";
export const QUERY_READ_USER_BY_EMAIL = "/auth/api/v1/users/by-email";

export const QUERY_UPDATE_USER_STATUS = "/auth/api/v1/users/user-status";
export const QUERY_UPDATE_USER = "/auth/api/v1/users";

export const QUERY_READ_ALL_EXCHANGE_RATES = "/dashboard/api/v1/exchange-rate";
export const QUERY_CREATE_EXCHANGE_RATE = "/dashboard/api/v1/exchange-rate";
export const QUERY_UPDATE_EXCHANGE_RATE = "/dashboard/api/v1/exchange-rate";
export const QUERY_DELETE_EXCHANGE_RATE = "/dashboard/api/v1/exchange-rate";

// export const QUERY_MEDIAPLAN_PLAN_FACT = "/dashboard/api/v1/mediaplan/plan_fact";
export const QUERY_PLAN_FACT_CAMPAIGNS =
  "/dashboard/api/v1/plan_fact/campaigns";
export const QUERY_PLAN_FACT_ADS = "/dashboard/api/v1/plan_fact/ads";

// export const QUERY_MEDIAPLAN_PLAN_FACT_FILTER =
//   "/dashboard/api/v1/mediaplan/plan_fact_filters";

export const QUERY_MEDIAPLAN_PLAN_FACT_CAMPAIGNS_FILTER =
  "/dashboard/api/v1/plan_fact/campaigns_filter";
export const QUERY_MEDIAPLAN_PLAN_FACT_ADS_FILTER =
  "/dashboard/api/v1/plan_fact/ads_filter";

export const QUERY_MEDIAPLAN_DELETE =
  "/dashboard/api/v1/mediaplan/delete-mediaplan";
export const QUERY_MEDIAPLAN_UPDATE =
  "/dashboard/api/v1/mediaplan/update-mediaplan";
export const QUERY_MEDIAPLAN_UPDATE_ROW =
  "/dashboard/api/v1/mediaplan/update-mediaplan-row";

export const QUERY_MEDIAPLAN_GET = "/dashboard/api/v1/mediaplan/get-mediaplan";
export const QUERY_MEDIAPLAN_GET_ROW =
  "/dashboard/api/v1/mediaplan/get-mediaplan-row";

export const QUERY_MEDIAPLAN_CREATE =
  "/dashboard/api/v1/mediaplan/create-mediaplan";
export const QUERY_MEDIAPLAN_UPLOAD =
  "/dashboard/api/v1/mediaplan/upload-mediaplan";

export const QUERY_ME = "/auth/api/v1/users/me";
