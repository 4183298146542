import { IconChevronDown } from "@tabler/icons-react";
import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";

type PickerProps = {
  options: string[];
  defaultOption?: number;
  handleChange: (value: string) => void;
  className?: string;
  yTranslateOverride?: string;
  colors?: Partial<{
    selected: string;
    options: string;
  }>;
  optionMappings?: {
    [key: string]: string;
  };
};

const Picker = ({
  handleChange,
  className,
  options,
  defaultOption,
  yTranslateOverride,
  colors,
  optionMappings,
}: PickerProps) => {
  const [selected, setSelected] = useState(options[defaultOption ?? 0]);

  useEffect(() => {
    setSelected(options[defaultOption ?? 0]);
  }, [defaultOption]);

  return (
    <Select
      IconComponent={IconChevronDown}
      onChange={(e) => {
        setSelected(e.target.value);
        handleChange(e.target.value as string);
      }}
      className={`${
        className ? className : ""
      } text-md font-inter font-bold inline-flex justify-center gap-2 p-1`}
      value={selected}
      sx={{
        height: "2rem",
        "&>.MuiSelect-select": {
          padding: "0",
          pr: "30px !important",
          ml: "15px",
          lineHeight: "1",
          color: colors?.selected || "#000",
          backgroundColor: "transparent",
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
        "& .MuiSelect-root": {
          position: "relative",
        },
        "&>fieldset": { border: "none" },
        "&>input:focus": {
          backgroundColor: "transparent",
        },
        "& .MuiSelect-icon": {
          color: colors?.selected || "#000",
          transform: "translateY(-30%)",
          "&Open": {
            transform: "rotate(180deg) translate(-15%, 30%)",
            zIndex: 1301,
          },
        },
        "& svg": {
          transition: "transform 0.1s ease-in-out",
        },
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        sx: {
          "& .MuiMenu-paper[style]": {
            transform: `translateY(${
              yTranslateOverride || "-0.3rem"
            }) translateX(0.45rem) !important`,
            boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.15)",
            borderRadius: "10px",
            "& .MuiList-padding": {
              padding: "0",
            },
          },
          "& .MuiMenuItem-root": {
            lineHeight: "1.25rem",
            color: colors?.options || "#000",
            fontWeight: 700,
            fontFamily: "Inter",
            pl: "0.75rem",
            py: "0.5rem",
            backgroundColor: "transparent !important",
            "&:hover": {
              backgroundColor: "#F5F5F5 !important",
            },
          },
        },
      }}
    >
      <MenuItem key={defaultOption} value={selected}>
        {(optionMappings && selected in optionMappings)
          ? optionMappings[selected]
          : selected}
      </MenuItem>

      {options.map(
        (option, index) =>
          option !== selected && (
            <MenuItem key={index} value={option}>
              {(optionMappings && option in optionMappings)
                ? optionMappings[option]
                : option}
            </MenuItem>
          ),
      )}
    </Select>
  );
};

export default Picker;
