import { ReactNode } from "react";

/**
 * @param {ReactNode} title - Заголовок страницы (Можно передать h1)
 * @param {ReactNode} btns - Кнопки в правом верхнем углу
 * @param {ReactNode} children - Основной контент страницы
 */

type CommonLayoutProps = {
  title: ReactNode;
  btns?: ReactNode;
  className?: string;
  children: ReactNode;
};

const CommonLayout = ({ title, btns, children }: CommonLayoutProps) => {
  return (
    <>
      <div className="flex flex-row justify-between items-center p-2 h-[3.25rem]">
        <div>{title}</div>
        <div className="flex flex-row h-9">{btns}</div>
      </div>
      {children}
    </>
  );
};
export default CommonLayout;
