import { Box } from "@mui/system";
import { ComplexListProps, PaginatedComplexList } from "shared/ui/list";
import { BtnsManageApi } from "./BtnsManageApi";
import { useReadConnectorsByAgencyQuery } from "entities/connectors";
import { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { TextInput } from "shared/ui/form";
import { IconSearch } from "@tabler/icons-react";
import { Picker } from "shared/ui/components";
import { Button, useTheme } from "@mui/material";
import { useLocale } from "entities/i18n";
import { ADS_SYSTEMS_READABLE } from "shared/constants";
export interface IClient {
  id: number;
  name: string;
  apis: {
    id: number;
    name: string;
    cabinet: string;
    active: boolean;
  }[];
}

type Props = {
  // clients: Client[];
  agencyId: number;
  handleAdd: () => void;
  // changes: number;
  // setchanges: any;
};

interface IConnector {
  type: string;
  name: string;
  is_active: boolean;
  agency_id: number;
  client_id: number;
  client_name: string;
  id: number;
}

type RowsEl = {
  clientName: {
    content: React.ReactNode;
    specialCellStyles?: string;
  };
  cabinet: {
    content: React.ReactNode;
  };
  api: {
    content: React.ReactNode;
  };
  btns: {
    content: React.ReactNode;
  };
};

export const ClientsApis = ({ agencyId, handleAdd }: Props) => {
  const [clientsWithConnectors, setclientsWithConnectors] = useState<
    ClientWithConnectors[]
  >([]);

  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const paramsClientName = useMemo(
    () => searchParams.get("client_name"),
    [searchParams]
  );

  const { palette } = useTheme();
  const { admin: i18n, core: i18nCore } = useLocale();

  const [page, setPage] = useState(1);
  const [nameFilter, setNameFilter] = useState<string | null>(paramsClientName);
  const [adsSystemFilter, setAdsSystemFilter] = useState<string | null>(null);
  const [cabinetNameFilter, setCabinetNameFilter] = useState<string | null>(
    null
  );

  const {
    isLoading,
    data,
    isUninitialized,
    refetch: rtkRefetch,
  } = useReadConnectorsByAgencyQuery(agencyId);
  const refetch = () => {
    !isUninitialized && rtkRefetch();
  };

  useEffect(() => {
    if (isLoading) return;

    const arr: ClientWithConnectors[] = [];

    data &&
      data.result &&
      data?.result?.forEach((el) => {
        const { name, id, is_active, bin, connectors } = el;

        arr.push({
          name,
          id,
          connectors,
          is_active,
          bin,
        });
      });

    setclientsWithConnectors(arr);
  }, [isLoading, data]);

  /*   const rows: RowsEl[] = useMemo(() => {
    const result: RowsEl[] = [];

    // grouping apis by client
    // let prevClientName: string | number = "";

    clientsWithConnectors.forEach((el) => {
      const { connectors } = el;
      const client_id = el.id;
      const client_name = el.name;

      // const isClientTheSame = client_id === prevClientName;
      // if (!isClientTheSame) prevClientName = client_id;

      connectors.forEach((connector, i: number) => {
        const { type, id, name, is_active } = connector;

        result.push({
          clientName: {
            content:
              i === 0 ? (
                <Link
                  className="font-bold text-grey-600 no-underline hover:underline"
                  to={`/admin/dashboard/${agencyId}/${client_id}`}
                >
                  {" "}
                  {client_name}{" "}
                </Link>
              ) : (
                ""
              ),
            specialCellStyles: i === 0 ? "" : "border-transparent",
            // content: client_id,
          },
          cabinet: {
            content: type,
          },
          api: {
            content: name,
          },
          btns: {
            content: (
              <BtnsManageApi isActive={is_active} apiId={id} apiName={name} />
            ),
          },
        });
      });
    });

    return result;
  }, [clientsWithConnectors]); */

  const rows: ComplexListProps["rows"] = useMemo(() => {
    const clientsHaveConnectors = clientsWithConnectors.filter(
      (e) => e.connectors
    );
    const clientsFiltered = clientsHaveConnectors.filter((e) =>
      e.name.toLowerCase().includes(nameFilter?.toLowerCase() || "")
    );
    const result: ComplexListProps["rows"] = [];
    clientsFiltered.forEach((client) => {
      const { name, id, connectors } = client;
      let nameAdded = false;
      const connectorsFilteredByName = connectors.filter((e) =>
        e.name.toLowerCase().includes(cabinetNameFilter?.toLowerCase() || "")
      );
      const connectorsFilteredByAdsSystem = connectorsFilteredByName.filter(
        (e) =>
          ADS_SYSTEMS_READABLE[e.type] === adsSystemFilter ||
          adsSystemFilter === null
      );
      connectorsFilteredByAdsSystem.forEach((connector) => {
        if (result.length % 10 === 0) nameAdded = false;
        const {
          type,
          id: connectorId,
          name: connectorName,
          is_active,
        } = connector;
        result.push({
          clientName: {
            cellSx: {
              borderTop: nameAdded ? "none" : "1px solid #E5E5E5",
              borderBottom: "none",
            },
            content: nameAdded ? (
              <></>
            ) : (
              <Link
                className="font-bold text-grey-600 no-underline hover:underline"
                to={`/admin/dashboard/${agencyId}/${id}`}
              >
                {(() => {
                  nameAdded = true;
                  return name;
                })()}
              </Link>
            ),
          },
          cabinet: {
            content: type,
          },
          api: {
            content: connectorName,
          },
          btns: {
            content: (
              <BtnsManageApi
                isActive={is_active}
                apiId={connectorId}
                apiName={connectorName}
                refetch={refetch}
              />
            ),
          },
        });
      });
    });
    return result;
  }, [
    clientsWithConnectors,
    nameFilter,
    adsSystemFilter,
    cabinetNameFilter,
    refetch,
  ]);

  return (
    <Box>
      <div className="flex flex-row justify-between items-end h-15 mt-5 mb-3">
        <div className="flex flex-row gap-[2rem] items-end">
          <TextInput
            id="search"
            placeholder={i18n.clientName}
            icon={<IconSearch size={16} color={palette.grey[400]} />}
            className="w-[15rem]"
            value={nameFilter || ""}
            onChange={(e) => {
              setNameFilter(e.target.value);
            }}
          />
          <div className="flex flex-row items-end h-15">
            <div className="flex flex-col gap-2 h-15">
              <label
                htmlFor="system"
                className="text-[0.7rem] font-bold text-black ml-4"
              >
                {i18n.cabinet}
              </label>
              <Picker
                options={["Все", ...Object.values(ADS_SYSTEMS_READABLE)]}
                defaultOption={0}
                handleChange={setAdsSystemFilter}
                colors={{
                  selected: palette.grey[500],
                  options: palette.text.primary,
                }}
              />
            </div>
            <TextInput
              id="search"
              placeholder={i18n.clients.cabinetName}
              icon={<IconSearch size={16} color={palette.grey[400]} />}
              className="w-[15rem]"
              onChange={(e) => {
                setCabinetNameFilter(e.target.value);
              }}
            />
          </div>
        </div>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className="bg-primary text-white normal-case h-10"
          onClick={handleAdd}
        >
          {i18nCore.add}
        </Button>
      </div>
      {data && (
      //! ==============================================
      //! TODO: This is a list of all clients with their apis
      //!
      //! Because the server returns a list of clients,
      //! and each client has a list of apis,
      //! the server pagination is unpredictable.
      //!
      //! So we need to be able to query the server for only the connectors
      //! Or remove the pagination from the response altogether
      //! and do it on the client side.
      //!
      //! Either way the endpoint needs to be changed.
      //! ==============================================

        <PaginatedComplexList.client
          rowsPerPage={10}
          data={{
            cols: [
              {
                name: "clientName",
                title: "Клиент",
              },
              {
                name: "cabinet",
                title: "Рекламный кабинет",
              },
              {
                name: "api",
                title: "API",
              },
              {
                name: "btns",
                title: "",
              },
            ],
            rows,
          }}
        />
      )}
    </Box>
  );
};
