import AuthRoutes from "./authRoutes";
import MainRoutes from "./mainRoutes";
import SuperAdminRoutes from "./superAdminRoutes";
import AdminRoutes from "./adminRoutes";

export {
  AuthRoutes,
  MainRoutes,
  SuperAdminRoutes,
  AdminRoutes
}
