/* eslint-disable indent */
import { useAuthAction, useAuthSelector } from "entities/auth";
import { useAdminAction } from "entities/admin";
import { ROUTES } from "shared/constants";

import {
  IconBriefcase2,
  IconGraph,
  IconLogout2,
  IconUser,
  IconUsersGroup,
  IconUserShield,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useLocale } from "entities/i18n";
import { useClearClientsCache } from "entities/clients";
import { useClearAgencyUsersCache } from "entities/users";
import {
  ProfileMenu as ProfileMenuUI,
  ProfileMenuItem,
} from "shared/ui/navigation";
import { useEffect, useMemo } from "react";
import { Auth } from "shared/types";
import { useAgency } from "entities/admin";

const ProfileMenu = () => {
  const i18n = useLocale().core;
  const auth = useAuthSelector();
  const { user } = auth;
  const { agencyId } = useAgency();

  const { logout } = useAuthAction();
  const clearAgencyUsersCache = useClearAgencyUsersCache();
  const clearClientsCache = useClearClientsCache();
  const { deleteAgency } = useAdminAction();

  const navigate = useNavigate();

  const menuActionHandler = (action: string, auth: Auth) => {
    switch (action) {
      case "profile":
        navigate("/profile");
        break;
      case "sysAdmin":
        navigate("/system-admin");
        break;
      case "agency":
        auth.user &&
        auth.user?.roles
          .map((r) => r.code)
          .filter((r) => ["ROOT", "MAIN_ADMIN", "SYSTEM_ADMIN"].includes(r))
          .length > 0
          ? navigate("/admin/agencies")
          : navigate("/agency/register");
        break;
      case "clients":
        navigate("/clients");
        break;
      case "logout":
        deleteAgency();
        clearAgencyUsersCache();
        clearClientsCache();
        logout();
        break;
      default:
        break;
    }
  };
  // ? navigate("/admin/agencies")
  // : navigate("/agency/register"));

  /*   const handleAdminClients = () => {
    navigate(`${ROUTES.adminAgency}/${agencyId || 17}`);
  }; */

  type ProfileMenuItemWithRoles = ProfileMenuItem & { roles: string[] };

  const rootMenuItems = useMemo<ProfileMenuItemWithRoles[]>(
    () => [
      {
        label: i18n.profile,
        icon: IconUser,
        action: "profile",
        roles: [
          "ROOT",
          "MAIN_ADMIN",
          "SYSTEM_ADMIN",
          "AGENCY_DIRECTOR",
          "MANAGER",
        ],
      },
      {
        label: i18n.admins,
        icon: IconUserShield,
        action: "sysAdmin",
        roles: ["ROOT", "MAIN_ADMIN"],
      },
      {
        label: i18n.agencies,
        icon: IconBriefcase2,
        action: "agency",
        roles: ["ROOT", "SYSTEM_ADMIN"],
      },
      {
        label: i18n.agenciesData,
        icon: IconBriefcase2,
        action: "agency",
        roles: ["AGENCY_DIRECTOR"],
      },
      {
        label: i18n.clients,
        icon: IconUsersGroup,
        action: "clients",
        roles: agencyId === null ? [] : ["ROOT", "AGENCY_DIRECTOR", "MANAGER"],
      },
      /*     {
      label: "Clients APIs",
      icon: IconList,
      action: "AdminClients",
      roles: ["ROOT", "MAIN_ADMIN", "SYSTEM_ADMIN"],
    }, */
      {
        label: i18n.logout,
        icon: IconLogout2,
        action: "logout",
        roles: [
          "ROOT",
          "MAIN_ADMIN",
          "SYSTEM_ADMIN",
          "AGENCY_DIRECTOR",
          "MANAGER",
        ],
      },
    ],
    [i18n, agencyId],
  );

  const menuItems = useMemo(
    () =>
      rootMenuItems
        .filter(
          (item) =>
            user &&
            user.roles.filter((r) => item.roles.includes(r.code)).length > 0,
        )
        .map((item) => ({ ...item, roles: undefined })) as ProfileMenuItem[],
    [auth, rootMenuItems],
  );

  return (
    <ProfileMenuUI
      title={user ? user.full_name : ""}
      items={menuItems}
      onAction={menuActionHandler}
    />
  );
};

export default ProfileMenu;
