// react
import { useEffect, useState, useRef, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";

// material-ui
import {
  Button,
  FormHelperText,
  Grid,
  Link,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import MuiIconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";

// third party
import * as Yup from "yup";
import { Formik } from "formik";
import { IconAt, IconEye, IconEyeClosed } from "@tabler/icons-react";

// project import
import {
  useSession,
  useAuthAction,
  useLazyAccessTokenQuery,
  verifyToken,
  useLazyActivateWithTokenQuery,
} from "entities/auth";
import { AnimateButton } from "shared/ui";
import { useLocale } from "entities/i18n";

// ============================|| JWT - LOGIN ||============================ //

export default ({ role }: { role: "user" | "system-admin" | "superadmin" }) => {
  const theme = useTheme();
  const [session, setSession] = useSession();
  const { loggein } = useAuthAction();
  const tokenFromStore = useSelector(
    (state: any) => state.auth.activationToken
  );
  const i18n = useLocale();

  const iconProps = {
    color: theme.palette.grey[400],
    size: 20,
    strokeWidth: 2.5,
  };

  const entranceText = useMemo(() => {
    switch (role) {
      case "system-admin":
        return i18n.auth.adminEntrance;
      case "superadmin":
        return i18n.auth.superAdminEntrance;
      default:
        return i18n.auth.entrance;
    }
  }, [role]);

  const [queryAuth, { isLoading, isError, data }] = useLazyAccessTokenQuery();
  const [queryActivate, queryActivateState] = useLazyActivateWithTokenQuery();

  useEffect(() => {
    if (session.access) {
      loggein(session);
    }
  }, [session.access]);

  const scriptedRef = useRef(true);

  useEffect(
    () => () => {
      scriptedRef.current = false;
    },
    []
  );

  useEffect(() => {
    if (data?.result?.access && verifyToken(data.result.access)) {
      setSession(data.result);
    }
  }, [data]);

  useEffect(() => {
    if (queryActivateState.isSuccess) {
      setSession(queryActivateState.data.result);
    }
  }, [queryActivateState]);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Formik
        initialValues={{
          // email: "root@gmail.com",
          // password: "testpass123",
          email: "",
          password: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(i18n.auth.errors.emailFormat)
            .max(255)
            .required(i18n.auth.errors.fieldRequired),
          password: Yup.string()
            .max(255)
            .required(i18n.auth.errors.fieldRequired),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            if (tokenFromStore) {
              await queryActivate({ token: tokenFromStore, ...values });
            }
            await queryAuth(values);
            if (scriptedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3} className="font-open-sans">
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  className="font-inter"
                >
                  <Typography
                    variant="subtitle2"
                    color="dark"
                    component="span"
                    justifyContent="center"
                    alignItems="center"
                    className="font-inter"
                    sx={{
                      fontWeight: 700,
                      fontSize: "36px",
                    }}
                  >
                    {entranceText}
                  </Typography>
                  {isError && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                    >
                      {i18n.auth.errors.wrongCredentials}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <OutlinedInput
                    id="email-login"
                    type="email"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="E-mail"
                    fullWidth
                    error={Boolean(touched.email && errors.email)}
                    startAdornment={
                      <InputAdornment position="start">
                        <MuiIconButton
                          aria-label="toggle email visibility"
                          edge="start"
                        >
                          <IconAt {...iconProps} />
                        </MuiIconButton>
                      </InputAdornment>
                    }
                  />
                  {touched.email && errors.email && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                    >
                      {errors.email}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.password && errors.password)}
                    id="-password-login"
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <MuiIconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="start"
                        >
                          {showPassword ? (
                            <IconEyeClosed {...iconProps} />
                          ) : (
                            <IconEye {...iconProps} />
                          )}
                        </MuiIconButton>
                      </InputAdornment>
                    }
                    placeholder="Пароль"
                  />
                  {touched.password && errors.password && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-password-login"
                    >
                      {errors.password}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              {data?.status_code == 2005 && (
                <Grid item xs={12}>
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-email-login"
                  >
                    {i18n.auth.errors.notConfirmed}
                  </FormHelperText>
                </Grid>
              )}
              {data?.status_code == 2006 && (
                <Grid item xs={12}>
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-email-login"
                  >
                    {i18n.auth.errors.wrongCredentials}
                  </FormHelperText>
                </Grid>
              )}
              {errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Grid>
              )}
              <Grid item xs={12}>
                <AnimateButton
                  type="scale"
                  direction="right"
                  offset={10}
                  scale={{
                    hover: 1.05,
                    tap: 0.95,
                  }}
                >
                  <Button
                    disableElevation
                    disabled={isLoading}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    className="bg-primary text-white normal-case"
                  >
                    {i18n.auth.login}
                  </Button>
                </AnimateButton>
              </Grid>
              <Grid item xs={12} sx={{ mt: -1 }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  className="font-inter"
                >
                  {role != "system-admin" && (
                    <Link
                      component={RouterLink}
                      to="/register"
                      color="text.primary"
                      className="no-underline"
                    >
                      {i18n.auth.registration}
                    </Link>
                  )}
                  <Link
                    component={RouterLink}
                    to="/reset-password"
                    color="text.primary"
                    className="no-underline"
                  >
                    {i18n.auth.passwordRecovery}
                  </Link>
                </Stack>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};
