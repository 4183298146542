import { useLocale } from "entities/i18n";
import { SUPPORT_CLIENT_URL } from "shared/constants";

const styleOnModerationEl = {
  gridRow: "1 / 2",
  gridColumn: "1 / 2",
};

const styleOnModerationWrapper = {
  gridTemplateRows: "auto",
  gridTemplateColumns: "auto",
};

export const OnModerationMessage = () => {
  const i18n = useLocale().admin.agency;

  return (
    <div
      style={styleOnModerationWrapper}
      className="fixed top-0 left-0 grid items-center w-screen h-screen"
    >
      <div
        style={styleOnModerationEl}
        className="opacity-70 w-full h-full z-10 bg-white"
      ></div>
      <div
        style={styleOnModerationEl}
        className="text-center max-w-[905px] z-50 m-auto w-full flex flex-col gap-8 text-4xl text-red-500 font-bold "
      >
        <div> {i18n.dataSuccessfullySent}</div>
        <div>{i18n.weWillConfirm}</div>
        <div>
          {i18n.ifUrgently}
          <br />
          <a
            className="underline text-red-500"
            href={SUPPORT_CLIENT_URL}
            target="_blank"
            rel="noreferrer"
          >
            {i18n.writeToSupport}
          </a>
        </div>
      </div>
    </div>
  );
};
