import { IconButton, Palette, Tooltip } from "@mui/material";
import { ColumnName } from "./getColumns";
import { CabinetBadge } from "shared/ui/icons";
import { adsSystems } from "shared/constants";
import * as datesLib from "shared/lib/dates";
import * as metricsLib from "shared/lib/metrics";
import { IconEdit, IconExclamationCircle } from "@tabler/icons-react";
import { Locale } from "shared/assets/i18n";
import { Picker } from "shared/ui/components";
import {
  colorCodeCompletion,
  colorCodeRelativeCompletion,
  estimateBudgetRecommendedDaily,
  getCampaignLink,
  getCurrencySymbol,
  handleChangeCurrency,
} from "./utils";
import { defaultFallbackCurrency } from "shared/constants";

const rowMonetaryMetrics = [
  "budgetFact",
  "budgetRecommendedDaily",
  "cpcFact",
  "cpuFact",
  "cpvFact",
  "cpaFact",
  "cpmFact",
];

const rowPlanMetrics = [
  "budgetPlan",
  "cpcPlan",
  "cpuPlan",
  "cpvPlan",
  "cpaPlan",
  "cpmPlan",
];

export const transformer = (
  i18n: Locale["dashboard"],
  palette: Palette,
  handleEditRow: (rowIndex: number) => void,
  userRole: "admin" | "director",
  rates: Record<adsSystems, Record<Currency, number>>,
  displayCurrency: Currency,
  clientId?: string,
  agencyId?: string,
) => {
  return (
    stats: PlanFactEntry,
    statsImmutable: PlanFactEntry,
    rowIndex: number,
    setters: SettersType,
  ) => {
    const row = {} as Record<ColumnName, React.ReactNode>;

    const { brand, mediaplan, campaign, cabinet } = stats;
    row.id = rowIndex + 1;
    row.brand = brand;
    row.campaignId = campaign.id;
    // row.campaign = campaign.name;
    row.campaign =
      ((userRole === "admin" && agencyId && clientId) ||
        (userRole === "director" && clientId)) &&
        campaign.id
        ? getCampaignLink(userRole, campaign, clientId, agencyId)
        : campaign.name;
    // row.mediaplanId = <Link to={`/mediaplan/${mediaplan.id}`} className="text-black">{mediaplan.id}</Link>;
    row.mediaplanId = mediaplan.id;
    // row.mediaplan = mediaplan.type;
    row.mediaplan = mediaplan.name;
    row.cabinet = (
      <CabinetBadge
        icon={cabinet.type.trim().split(" ")[0] as adsSystems}
        color="primary"
        title={cabinet.name}
      />
    );

    const { status, date, days, ad_pricing } = stats;

    let { currency } = stats;

    row.status = status;
    const [startDate, endDate] = [
      // new Date(date.start_date),
      // new Date(date.date_end),
      date.start_date,
      date.end_date,
    ];
    row.date = `${startDate} - ${endDate}`;
    // row.date = `${datesLib.formatDate(startDate)} - ${datesLib.formatDate(
    //   endDate,
    // )}`;

    const daysPassed =
      startDate !== "None" && endDate !== "None" && startDate && endDate
        ? datesLib.getDaysPassed(startDate, endDate)
        : null;
    row.daysPassed = daysPassed;
    row.purchaseUnit = ad_pricing;
    // row.currency = (
    //   <Picker
    //     options={["USD", "KZT", "RUB"]}
    //     defaultOption={["USD", "KZT", "RUB"].indexOf(
    //       currency ? currency.toUpperCase() : defaultFallbackCurrency,
    //     )}
    //     handleChange={(val: string) =>
    //       handleChangeCurrency(val as Currency, statsImmutable, setters, rates[cabinet.type.trim().split(" ")[0] as adsSystems])}
    //     className="min-w-[3.75rem]"
    //   />
    // );
    const keyMetricOptions = [
      "budget",
      "clicks",
      "impressions",
      "conversions",
      "reach",
    ] as const;

    const keyMetricOptionsI18n = keyMetricOptions.reduce(
      (acc, curr) => {
        acc[curr] = i18n[curr];
        return acc;
      },
      {} as Record<(typeof keyMetricOptions)[number], string>,
    );

    let keyMetric = "impressions" as (typeof keyMetricOptions)[number];
    if ("key_metric" in stats) {
      keyMetric =
        (stats.key_metric as (typeof keyMetricOptions)[number] | null) ??
        "impressions";
    }

    const convertMonetaryMetric = (value: number) => {
      return currencyAvailable
        ? value /
        rates[
        cabinet.type.trim().split(" ")[0].toUpperCase() as adsSystems
        ][displayCurrency]
        : value;
    };

    const cabinetValueToKZT = (value: number) => {
      return currencyAvailable
        ? value *
        rates[
        cabinet.type.trim().split(" ")[0].toUpperCase() as adsSystems
        ][
          ["USD", "RUB", "KZT"].includes(currency)
            ? (currency as Currency)
            : displayCurrency
        ]
        : value;
    };

    // const convertMonetaryMetricFromKZT = (value: number) => {
    //   if (displayCurrency === "KZT") return value;
    //   if (currencyAvailable) {
    //     return (
    //       value /
    //       rates[cabinet.type.trim().split(" ")[0].toUpperCase() as adsSystems][displayCurrency]
    //     );
    //   }
    //   return value;
    // };

    row.keyMetric = (
      <Picker
        options={[...keyMetricOptions]}
        defaultOption={keyMetricOptions.indexOf(
          "key_metric" in stats
            ? (stats.key_metric as (typeof keyMetricOptions)[number] | null) ??
            "impressions"
            : "impressions",
        )}
        // handleChange={(val: string) => {
        //   setters.setKeyMetric(val as KeyMetric);
        // }}
        handleChange={(val: string) => {
          keyMetric = val as (typeof keyMetricOptions)[number];
          setters["main"]({
            key_metric: val as (typeof keyMetricOptions)[number],
          });
        }}
        optionMappings={keyMetricOptionsI18n}
        className="min-w-[3.75rem]"
      />
    );
    const currencyAvailable =
      cabinet.type.trim().split(" ")[0].toUpperCase() in rates;
    row.currency = !currencyAvailable ? (
      <Tooltip
        title={`Предоставьте курсы обмена валют для рекламной системы ${cabinet.type.trim().split(" ")[0]}.`}
      >
        <div className="flex items-center justify-center">
          <IconExclamationCircle
            color={palette.error.main}
            strokeWidth={2}
            size={14}
            className="mr-2"
          />
          {`${getCurrencySymbol("KZT")} / ${currency ? getCurrencySymbol(currency as Currency) : "—"
          }`}
        </div>
      </Tooltip>
    ) : (
      getCurrencySymbol(displayCurrency)
    );

    const { budget } = stats;
    row.budgetPlan = budget.plan
      ? metricsLib.prettifyNumber(
        displayCurrency === "KZT" || !currencyAvailable
          ? budget.plan
          : convertMonetaryMetric(budget.plan),
        2,
      )
      : null;

    row.budgetFact = budget.fact
      ? metricsLib.prettifyNumber(
        !currencyAvailable
          ? budget.fact
          : convertMonetaryMetric(
            budget.fact *
            rates[
            cabinet.type
              .trim()
              .split(" ")[0]
              .toUpperCase() as adsSystems
            ][
              ["USD", "RUB", "KZT"].includes(currency)
                ? (currency as Currency)
                : displayCurrency
            ],
          ),
        2,
      )
      : null;
    row.budgetResult =
      budget.plan && budget.fact
        ? metricsLib.percentify(budget.fact, budget.plan, "string")
        : null;
    row.budgetRecommendedDaily =
      startDate !== "None" &&
        endDate !== "None" &&
        startDate &&
        endDate &&
        currencyAvailable
        ? estimateBudgetRecommendedDaily({
          budgetPlan: budget.plan,
          budgetFact: budget.fact
            ? budget.fact *
            rates[
            cabinet.type.trim().split(" ")[0].toUpperCase() as adsSystems
            ][
              ["USD", "RUB", "KZT"].includes(currency)
                ? (currency as Currency)
                : displayCurrency
            ]
            : null,
          startDate,
          endDate,
          numberCallback: convertMonetaryMetric,
        })
        : null;

    //import all KPIs from stats
    const {
      kpi_cpa,
      kpi_cpc,
      kpi_cpm,
      kpi_cpu,
      kpi_ctr,
      kpi_cpv,
      kpi_vtr,
      kpi_cr,
    } = stats;

    const kpiKey = `kpi_${ad_pricing.toLowerCase()}` as
      | "kpi_cpa"
      | "kpi_cpc"
      | "kpi_cpm"
      | "kpi_cpu"
      | "kpi_ctr"
      | "kpi_cpv"
      | "kpi_vtr"
      | "kpi_cr";
    const kpiEntry = stats[keyMetric];

    row.kpiPlan =
      keyMetric && stats[keyMetric].plan
        ? metricsLib.prettifyNumber(
          Number(
            keyMetric === "budget"
              ? convertMonetaryMetric(stats[keyMetric].plan!).toFixed(2)
              : stats[keyMetric].plan!.toFixed(2),
          ),
        )
        : null;
    row.kpiFact =
      keyMetric && stats[keyMetric].fact
        ? metricsLib.prettifyNumber(
          Number(
            keyMetric === "budget"
              ? convertMonetaryMetric(
                currencyAvailable
                  ? stats[keyMetric].fact! *
                  rates[
                  cabinet.type
                    .trim()
                    .split(" ")[0]
                    .toUpperCase() as adsSystems
                  ][
                    ["USD", "RUB", "KZT"].includes(currency)
                      ? (currency as Currency)
                      : displayCurrency
                  ]
                  : stats[keyMetric].fact!,
              ).toFixed(2)
              : stats[keyMetric].fact!.toFixed(2),
          ),
        )
        : null;

    const daysPassedArray = daysPassed
      ? daysPassed.split("/").map(Number)
      : [0, 0];

    row.kpiCompletion =
      kpiEntry.plan && kpiEntry.fact && currencyAvailable ? (
        keyMetric === "budget" ? (
          (() => {
            const recountedFact =
              kpiEntry.fact *
              rates[
              cabinet.type.trim().split(" ")[0].toUpperCase() as adsSystems
              ][
                ["USD", "RUB", "KZT"].includes(currency)
                  ? (currency as Currency)
                  : displayCurrency
              ];
            return (
              <div
                style={{
                  color:
                    colorCodeCompletion(kpiEntry.plan, recountedFact) ||
                    "rgb(36, 36, 36)",
                }}
              >
                {metricsLib.percentify(recountedFact, kpiEntry.plan, "string")}
              </div>
            );
          })()
        ) : (
          <div
            style={{
              color:
                colorCodeCompletion(kpiEntry.plan, kpiEntry.fact) ||
                "rgb(36, 36, 36)",
            }}
          >
            {metricsLib.percentify(kpiEntry.fact, kpiEntry.plan, "string")}
          </div>
        )
      ) : null;

    row.cpcPlan = kpi_cpc.plan
      ? metricsLib.prettifyNumber(
        Number(convertMonetaryMetric(kpi_cpc.plan).toFixed(2)),
      )
      : null;
    row.cpcFact = kpi_cpc.fact
      ? metricsLib.prettifyNumber(
        Number(
          convertMonetaryMetric(cabinetValueToKZT(kpi_cpc.fact)).toFixed(2),
        ),
      )
      : null;
    row.cpcCompletion =
      kpi_cpc.plan && kpi_cpc.fact
        ? metricsLib.percentify(
          cabinetValueToKZT(kpi_cpc.fact),
          kpi_cpc.plan,
          "string",
        )
        : null;
    row.ctrPlan = kpi_ctr.plan
      ? metricsLib.prettifyNumber(Number(kpi_ctr.plan.toFixed(2))) + " %"
      : null;
    row.ctrFact = kpi_ctr.fact
      ? metricsLib.prettifyNumber(Number(kpi_ctr.fact.toFixed(2))) + " %"
      : null;
    row.ctrCompletion = kpi_ctr.plan && kpi_ctr.fact && (
      <div>
        {(kpi_ctr.plan > kpi_ctr.fact ? "-" : "+") +
          metricsLib.prettifyNumber(
            Number(Math.abs(kpi_ctr.fact - kpi_ctr.plan).toFixed(2)),
          ) +
          " %"}
      </div>
    );

    row.cpuPlan = kpi_cpu.plan
      ? metricsLib.prettifyNumber(
        Number(convertMonetaryMetric(kpi_cpu.plan).toFixed(2)),
      )
      : null;
    row.cpuFact = kpi_cpu.fact
      ? metricsLib.prettifyNumber(
        Number(
          convertMonetaryMetric(cabinetValueToKZT(kpi_cpu.fact)).toFixed(2),
        ),
      )
      : null;
    row.cpuCompletion = kpi_cpu.plan && kpi_cpu.fact && (
      <div
        style={{
          color:
            colorCodeCompletion(
              kpi_cpu.plan,
              cabinetValueToKZT(kpi_cpu.fact),
            ) || "rgb(36, 36, 36)",
        }}
      >
        {metricsLib.percentify(
          cabinetValueToKZT(kpi_cpu.fact),
          kpi_cpu.plan,
          "string",
        )}
      </div>
    );

    row.cpvPlan = kpi_cpv.plan
      ? metricsLib.prettifyNumber(
        Number(convertMonetaryMetric(kpi_cpv.plan).toFixed(2)),
      )
      : null;
    row.cpvFact = kpi_cpv.fact
      ? metricsLib.prettifyNumber(
        Number(
          convertMonetaryMetric(cabinetValueToKZT(kpi_cpv.fact)).toFixed(2),
        ),
      )
      : null;
    row.cpvCompletion = kpi_cpv.plan && kpi_cpv.fact && (
      <div
        style={{
          color:
            colorCodeCompletion(
              kpi_cpv.plan,
              cabinetValueToKZT(kpi_cpv.fact),
            ) || "rgb(36, 36, 36)",
        }}
      >
        {metricsLib.percentify(
          cabinetValueToKZT(kpi_cpv.fact),
          kpi_cpv.plan,
          "string",
        )}
      </div>
    );

    row.vtrPlan = kpi_vtr.plan
      ? metricsLib.prettifyNumber(Number(kpi_vtr.plan.toFixed(2))) + " %"
      : null;
    row.vtrFact = kpi_vtr.fact
      ? metricsLib.prettifyNumber(Number(kpi_vtr.fact.toFixed(2))) + " %"
      : null;
    row.vtrCompletion = kpi_vtr.plan && kpi_vtr.fact && (
      <div>
        {(kpi_vtr.plan > kpi_vtr.fact ? "-" : "+") +
          metricsLib.prettifyNumber(
            Number(Math.abs(kpi_vtr.fact - kpi_vtr.plan).toFixed(2)),
          ) +
          " %"}
      </div>
    );

    row.crPlan = kpi_cr.plan
      ? metricsLib.prettifyNumber(Number(kpi_cr.plan.toFixed(2))) + " %"
      : null;
    row.crFact = kpi_cr.fact
      ? metricsLib.prettifyNumber(Number(kpi_cr.fact.toFixed(2))) + " %"
      : null;
    row.crCompletion = kpi_cr.plan && kpi_cr.fact && (
      <div>
        {(kpi_cr.plan > kpi_cr.fact ? "-" : "+") +
          metricsLib.prettifyNumber(
            Number(Math.abs(kpi_cr.fact - kpi_cr.plan).toFixed(2)),
          ) +
          " %"}
      </div>
    );

    row.cpmPlan = kpi_cpm.plan
      ? metricsLib.prettifyNumber(
        Number(convertMonetaryMetric(kpi_cpm.plan).toFixed(2)),
      )
      : null;
    row.cpmFact = kpi_cpm.fact
      ? metricsLib.prettifyNumber(
        Number(
          convertMonetaryMetric(cabinetValueToKZT(kpi_cpm.fact)).toFixed(2),
        ),
      )
      : null;
    row.cpmCompletion = kpi_cpm.plan && kpi_cpm.fact && (
      <div
        style={{
          color:
            colorCodeCompletion(
              kpi_cpm.plan,
              cabinetValueToKZT(kpi_cpm.fact),
            ) || "rgb(36, 36, 36)",
        }}
      >
        {metricsLib.percentify(
          cabinetValueToKZT(kpi_cpm.fact),
          kpi_cpm.plan,
          "string",
        )}
      </div>
    );

    row.cpaPlan = kpi_cpa.plan
      ? metricsLib.prettifyNumber(
        Number(convertMonetaryMetric(kpi_cpa.plan).toFixed(2)),
      )
      : null;
    row.cpaFact = kpi_cpa.fact
      ? metricsLib.prettifyNumber(
        Number(
          convertMonetaryMetric(cabinetValueToKZT(kpi_cpa.fact)).toFixed(2),
        ),
      )
      : null;
    row.cpaCompletion = kpi_cpa.plan && kpi_cpa.fact && (
      <div
        style={{
          color:
            colorCodeCompletion(
              kpi_cpa.plan,
              cabinetValueToKZT(kpi_cpa.fact),
            ) || "rgb(36, 36, 36)",
        }}
      >
        {metricsLib.percentify(
          cabinetValueToKZT(kpi_cpa.fact),
          kpi_cpa.plan,
          "string",
        )}
      </div>
    );

    const { impressions, clicks, conversions, installs, frequency, reach } =
      stats;

    row.impressionsPlan = impressions.plan
      ? metricsLib.prettifyNumber(Math.floor(impressions.plan))
      : null;
    row.impressionsFact = impressions.fact
      ? metricsLib.prettifyNumber(Math.floor(impressions.fact))
      : null;
    row.impressionsCompletion = impressions.plan && impressions.fact && (
      <div
        style={{
          color:
            colorCodeRelativeCompletion(
              daysPassedArray[0],
              daysPassedArray[1],
              impressions.plan,
              impressions.fact,
            ) || "rgb(36, 36, 36)",
        }}
      >
        {metricsLib.percentify(impressions.fact, impressions.plan, "string")}
      </div>
    );

    row.clicksPlan = clicks.plan
      ? metricsLib.prettifyNumber(Math.floor(clicks.plan))
      : null;
    row.clicksFact = clicks.fact
      ? metricsLib.prettifyNumber(Math.floor(clicks.fact))
      : null;
    row.clicksCompletion = clicks.plan && clicks.fact && (
      <div
        style={{
          color:
            colorCodeRelativeCompletion(
              daysPassedArray[0],
              daysPassedArray[1],
              clicks.plan,
              clicks.fact,
            ) || "rgb(36, 36, 36)",
        }}
      >
        {metricsLib.percentify(clicks.fact, clicks.plan, "string")}
      </div>
    );

    row.conversionsPlan = conversions.plan
      ? metricsLib.prettifyNumber(Math.floor(conversions.plan))
      : null;
    row.conversionsFact = conversions.fact
      ? metricsLib.prettifyNumber(Math.floor(conversions.fact))
      : null;
    row.conversionsCompletion = conversions.plan && conversions.fact && (
      <div
        style={{
          color:
            colorCodeRelativeCompletion(
              daysPassedArray[0],
              daysPassedArray[1],
              conversions.plan,
              conversions.fact,
            ) || "rgb(36, 36, 36)",
        }}
      >
        {metricsLib.percentify(conversions.fact, conversions.plan, "string")}
      </div>
    );

    row.installsPlan = installs.plan
      ? metricsLib.prettifyNumber(Math.floor(installs.plan))
      : null;
    row.installsFact = installs.fact
      ? metricsLib.prettifyNumber(Math.floor(installs.fact))
      : null;
    row.installsCompletion = installs.plan && installs.fact && (
      <div
        style={{
          color:
            colorCodeRelativeCompletion(
              daysPassedArray[0],
              daysPassedArray[1],
              installs.plan,
              installs.fact,
            ) || "rgb(36, 36, 36)",
        }}
      >
        {metricsLib.percentify(installs.fact, installs.plan, "string")}
      </div>
    );

    row.frequencyPlan = frequency.plan
      ? metricsLib.prettifyNumber(frequency.plan, 2)
      : null;
    row.frequencyFact = frequency.fact
      ? metricsLib.prettifyNumber(frequency.fact, 2)
      : null;
    row.frequencyCompletion = frequency.plan && frequency.fact && (
      <div
        style={{
          color:
            colorCodeCompletion(frequency.plan, frequency.fact, true) ||
            "rgb(36, 36, 36)",
        }}
      >
        {metricsLib.percentify(frequency.fact, frequency.plan, "string")}
      </div>
    );

    row.reachPlan = reach.plan
      ? metricsLib.prettifyNumber(Math.floor(reach.plan))
      : null;
    row.reachFact = reach.fact
      ? metricsLib.prettifyNumber(Math.floor(reach.fact))
      : null;
    row.reachCompletion = reach.plan && reach.fact && (
      <div
        style={{
          color:
            colorCodeRelativeCompletion(
              daysPassedArray[0],
              daysPassedArray[1],
              reach.plan,
              reach.fact,
            ) || "rgb(36, 36, 36)",
        }}
      >
        {metricsLib.percentify(reach.fact, reach.plan, "string")}
      </div>
    );

    const {
      video_views /* video_views_p100, video_views_p25, video_views_p50, video_views_p75 */,
    } = stats;

    row.viewsPlan = video_views.plan
      ? metricsLib.prettifyNumber(Math.floor(video_views.plan))
      : null;
    row.viewsFact = video_views.fact
      ? metricsLib.prettifyNumber(Math.floor(video_views.fact))
      : null;
    row.viewsCompletion = video_views.plan && video_views.fact && (
      <div
        style={{
          color:
            colorCodeRelativeCompletion(
              daysPassedArray[0],
              daysPassedArray[1],
              video_views.plan,
              video_views.fact,
            ) || "rgb(36, 36, 36)",
        }}
      >
        {metricsLib.percentify(video_views.fact, video_views.plan, "string")}
      </div>
    );

    row.edit = userRole === "director" && (
      <IconButton onClick={() => handleEditRow(rowIndex)}>
        <IconEdit strokeWidth={1.5} color={"#BDBDBD"} size={18} />
      </IconButton>
    );

    const currencySymbol = getCurrencySymbol(displayCurrency);

    if (currencyAvailable) {
      for (const key of [...rowMonetaryMetrics, ...rowPlanMetrics]) {
        row[key] = row[key] ? `${row[key]} ${currencySymbol}` : null;
        if (!stats.currency && row[key]) console.log(row[key]);
      }
      if (stats.key_metric === "budget") {
        row.kpiPlan = row.kpiPlan ? `${row.kpiPlan} ${currencySymbol}` : null;
        row.kpiFact = row.kpiFact ? `${row.kpiFact} ${currencySymbol}` : null;
      }
    } else {
      for (const key of rowMonetaryMetrics) {
        currency &&
          (row[key] = row[key]
            ? `${row[key]} ${getCurrencySymbol(currency as Currency)}`
            : null);
      }
      for (const key of rowPlanMetrics) {
        row[key] = row[key] ? `${row[key]} ${getCurrencySymbol("KZT")}` : null;
      }
      if (stats.key_metric === "budget") {
        row.kpiPlan = row.kpiPlan
          ? `${row.kpiPlan} ${getCurrencySymbol("KZT")}`
          : null;
        currency &&
          (row.kpiFact = row.kpiFact
            ? `${row.kpiFact} ${getCurrencySymbol(currency as Currency)}`
            : null);
      }
    }

    (Object.keys(row) as ColumnName[]).forEach((key) => {
      if (row[key] === null || row[key] === undefined) {
        row[key] = /* long hyphen character, please do not delete */ "—";
      }
    });

    return row;
  };
};

export default transformer;
