import { useCallback, useEffect, useState } from "react";
import { Token } from "shared/types";
import { deleteMemory, getMemory, setMemory } from "shared/lib";
import jwtDecode, { JwtPayload } from "jwt-decode";

export interface JWT {
  created: number;
}

export const useSession = (): [Token, any] => {
  let serviceAuth: Token = {} as Token;
  const json = getMemory("serviseAuth");
  if (json) {
    serviceAuth = JSON.parse(json);
  }

  const [state, setState] = useState(serviceAuth);

  useEffect(() => {
    if (verifyToken(state.access)) {
      setMemory("serviseAuth", JSON.stringify(state));
      return;
    }
    deleteMemory("serviseAuth");
  }, [state]);

  const setStateCallback = useCallback(
    (prepare: Token) => {
      if (verifyToken(prepare.access)) {
        setState(prepare);
      }
      setState((state) => state);
    },
    [state],
  );

  return [state, setStateCallback];
};

export const verifyToken = (serviceToken: string): boolean => {
  if (!/^[\w-]+\.[\w-]+\.[\w-]+$/.test(serviceToken)) {
    return false;
  }
  const { exp } = jwtDecode<JwtPayload>(serviceToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return exp ? exp > Date.now() / 1000 : false;
};
