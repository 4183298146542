import { MainLayout, ProfileMenu } from "widgets";
import { LigthRoutes } from "shared/types";
import { AuthGuard } from "entities/auth/ui";
import { LocalePicker } from "features/i18n";
import {
  SystemAdminList,
  SystemAdminCreation,
  SystemAdminEdit,
} from "pages/superAdmin";

const SuperAdminRoutes: LigthRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard>
          <MainLayout
            headerWidget={
              <div className="flex flex-row gap-8">
                <LocalePicker />
                <ProfileMenu />
              </div>
            }
          />
        </AuthGuard>
      ),
      children: [
        {
          path: "/system-admin",
          element: <SystemAdminList />,
        },
        {
          path: "/system-admin/create",
          element: <SystemAdminCreation />,
        },
        {
          path: "/system-admin/:id",
          element: <SystemAdminEdit />,
        },
      ],
    },
  ],
};

export default SuperAdminRoutes;
