import { JSX } from "react";
import { AuthBackground, Logo, Paralax } from 'shared/ui';
import { Footer } from "widgets/layout";

import { Box, Grid } from "@mui/material";

interface AuthPageProps { component: JSX.Element, isAdmin?: boolean }

export default ({ component, isAdmin }: AuthPageProps) => {
  return (
    <Box sx={{ minHeight: "100vh" }}>
      <Paralax><AuthBackground /></Paralax>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        sx={{
          minHeight: "100vh"
        }}
      >
        <Grid item xs={12}
          container
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 4}}
        >
          <Logo />
        </Grid>
        <Grid item xs={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          {component}
        </Grid>
        <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
          <Footer isAdminOverride={isAdmin} />
        </Grid>
      </Grid>
    </Box>
  );
}
