import { metricsEntry, calculatorOption } from "./metricsTypes";

const returnByOption = (fun: Function) => {
  return (n1: number, n2: number, option?: calculatorOption) => {
    switch (option) {
      case "crop":
        return fun(n1, n2).toFixed(2);
      case "string":
        return fun(n1, n2).toString();
      case "float":
        return fun(n1, n2);
      default:
        return fun(n1, n2).toFixed(2);
    }
  };
};

// Функции для расчета метрик. Возвращают числа
const metricNumberFunctions = {
  cpm: (cost: number, impressions: number) => (cost / impressions) * 1000,
  cpc: (cost: number, clicks: number) => cost / clicks,
  ctr: (clicks: number, impressions: number) => (clicks / impressions) * 100,
  cpu: (cost: number, reach: number) => cost / reach,
  cpv: (cost: number, views: number) => cost / views,
  vtr: (views: number, impressions: number) => (views / impressions) * 100,
  cr: (conversions: number, clicks: number) => (conversions / clicks) * 100,
  cpa: (cost: number, conversions: number) => cost / conversions,
  cpi: (cost: number, installations: number) => cost / installations,
};

// Функции для расчета метрик. Возвращают числа, строки или обрезанные строки,
// в зависимости от переданной option.

/**
 * Функция для расчета CPM.
 * @param n1 - стоимость.
 * @param n2 - количество показов.
 */
export const calcCPM = returnByOption(metricNumberFunctions.cpm);
/**
 * Функция для расчета CPC.
 * @param n1 - стоимость.
 * @param n2 - количество кликов.
 */
export const calcCPC = returnByOption(metricNumberFunctions.cpc);
/**
 * Функция для расчета CTR.
 * @param n1 - количество кликов.
 * @param n2 - количество показов.
 */
export const calcCTR = returnByOption(metricNumberFunctions.ctr);
/**
 * Функция для расчета CPU.
 * @param n1 - стоимость.
 * @param n2 - охват.
 */
export const calcCPU = returnByOption(metricNumberFunctions.cpu);
/**
 * Функция для расчета CPV.
 * @param n1 - стоимость.
 * @param n2 - количество просмотров.
 */
export const calcCPV = returnByOption(metricNumberFunctions.cpv);
/**
 * Функция для расчета VTR.
 * @param n1 - количество просмотров.
 * @param n2 - количество показов.
 */
export const calcVTR = returnByOption(metricNumberFunctions.vtr);
/**
 * Функция для расчета CR.
 * @param n1 - количество конверсий.
 * @param n2 - количество кликов.
 */
export const calcCR = returnByOption(metricNumberFunctions.cr);
/**
 * Функция для расчета CPA.
 * @param n1 - стоимость.
 * @param n2 - количество конверсий.
 */
export const calcCPA = returnByOption(metricNumberFunctions.cpa);
/**
 * Функция для расчета CPI.
 * @param n1 - стоимость.
 * @param n2 - количество установок.
 */
export const calcCPI = returnByOption(metricNumberFunctions.cpi);


export const { prettifyNumber, percentify } = {
  // Функция для добавления пробелов между разрядами чисел.
  // Возвращает строку.
  prettifyNumber: (number: number, fixed?: number) => {
    // Разбиваем число на целую и дробную части.
    // При применении регулярного выражения к числу с плавающей точкой,
    // дробная часть тоже разделяется пробелами, 
    // а это нам не нужно.
    const splitted = fixed ? number.toFixed(fixed).split(".") : number.toString().split(".");
    // Регулярное выражение для добавления пробелов между разрядами чисел.
    // \B - не граница слова;
    // (?=(\d{3})+(?!\d)) - совпадение, если за текущей позицией следует
    // любое количество групп по 3 цифры, за которыми не следуют цифры;
    // g - глобальный поиск;
    const formattedWhole = splitted[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    // Если дробная часть есть, то возвращаем ее тоже,
    // иначе возвращаем только целую часть.
    return splitted.length > 1
      ? formattedWhole + "." + splitted[1]
      : formattedWhole;
  },

  // Функция для расчета процентов.
  percentify: (
    fact: number | undefined,
    plan: number | undefined,
    option: calculatorOption = "crop"
  ) => {
    if (!plan || !fact) {
      switch (option) {
        case "crop":
          return "0.00";
        case "string":
          return "0.00 %";
        case "float":
          return 0;
      }
    }
    const percent: number = plan && fact ? (fact / plan) * 100 : 0;
    switch (option) {
      case "crop":
        return percent.toFixed(2);
      case "string":
        return percent.toFixed(2) + " %";
      case "float":
        return percent;
      default:
        return percent.toFixed(2);
    }
  },
};

// Функция для расчета метрик. Возвращает объект с метриками.
// В зависимости от переданных показателей, возвращает те метрики,
// которые можно посчитать. Остальные -- undefined.
// Возвращает числа, строки или обрезанные строки, в зависимости от переданной option.
export const { metricsCalculator } = {
  metricsCalculator: (entry: metricsEntry, option: calculatorOption) => {
    const {
      cost,
      impressions,
      clicks,
      reach,
      views,
      conversions,
      installations,
    } = entry;
    return {
      cpm: cost && impressions && calcCPM(cost, impressions, option),
      cpc: cost && clicks && calcCPC(cost, clicks, option),
      ctr: clicks && impressions && calcCTR(clicks, impressions, option),
      cpu: cost && reach && calcCPU(cost, reach, option),
      cpv: cost && views && calcCPV(cost, views, option),
      vtr: views && impressions && calcVTR(views, impressions, option),
      cr: clicks && conversions && calcCR(conversions, clicks, option),
      cpa: cost && conversions && calcCPA(cost, conversions, option),
      cpi: cost && installations && calcCPI(cost, installations, option),
    };
  },
};

export { type metricsEntry, type calculatorOption };