import { APIBadge, APIBadgeProps } from "shared/ui/icons";

export type APIIconStackProps = {
    data: APIBadgeProps[];
};

export const APIIconStack = ({ data }: APIIconStackProps) => {
  return (
    <div className="flex flex-row gap-2 items-center justify-center">
      {data.map((item, index) => (
        <APIBadge key={index} {...item} />
      ))}
    </div>
  );
}