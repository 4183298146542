import { useMemo } from "react";
import { Footer as FooterUI } from "shared/ui/layout";
import { SUPPORT_ADMIN_URL, SUPPORT_CLIENT_URL } from "shared/constants";
import { useAuthSelector } from "entities/auth";
import { useLocale } from "entities/i18n";
import { Typography, TypographyProps } from "@mui/material";

type Props = {
  noCenterPiece?: boolean;
  isAdminOverride?: boolean;
};

const getCurrentYear = () => new Date().getFullYear();

const Footer = ({ noCenterPiece, isAdminOverride }: Props) => {
  const i18n = useLocale();
  const auth = useAuthSelector();

  const adminRoles = ["ROOT", "MAIN_ADMIN", "SYSTEM_ADMIN", "AGENCY_DIRECTOR"];
  const isAdmin = useMemo(
    () =>
      isAdminOverride ||
      auth.user?.roles.reduce(
        (acc, role) => acc || adminRoles.includes(role.code),
        false,
      ),
    [auth],
  );

  const currentYear = useMemo(() => getCurrentYear(), []);

  const typographyProps = {
    variant: "subtitle2",
    color: "dark",
    component: "span",
    className: "font-inter",
  } as TypographyProps;

  return (
    <FooterUI
      matchDownSM={false}
      leftTypography={i18n.footer.support}
      leftHref={isAdmin ? SUPPORT_ADMIN_URL : SUPPORT_CLIENT_URL}
      centerNode={
        noCenterPiece ? undefined : (
          <div className="flex flex-row items-center justify-center gap-4">
            <a
              href="./privacy-policy"
              target="_blank"
              rel="noreferrer"
              className="no-underline text-black hover:underline"
            >
              <Typography {...typographyProps}>
                {i18n.footer.privacyPolicy}
              </Typography>
            </a>
            {" | "}
            <a
              href="./user-agreement"
              target="_blank"
              rel="noreferrer"
              className="no-underline text-black hover:underline"
            >
              <Typography {...typographyProps}>
                {i18n.footer.userAgreement}
              </Typography>
            </a>

            {" | "}
            <a
              href="./app-description"
              target="_blank"
              rel="noreferrer"
              className="no-underline text-black hover:underline"
            >
              <Typography {...typographyProps}>
                {i18n.footer.howItWorks}
              </Typography>
            </a>
          </div>
        )
      }
      rightTypography={"© Danametrics, " + currentYear.toString()}
      className="bottom-0 py-6"
    />
  );
};

export default Footer;
