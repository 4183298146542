import { useRef, useEffect, MouseEventHandler } from "react";
import { Button, Stack, FormHelperText } from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";

import { TextInput } from "shared/ui/form";
import { getByErrorCode } from "shared/lib";
import { useLocale } from "entities/i18n";
import { useUpdateClientMutation } from "entities/clients";

import { useLocation } from "react-router-dom";

export const EditClient = ({
  handleCancel,
  id,
  client
}: {
  handleCancel: MouseEventHandler;
  id: number;
  agencyId?: number;
  client: Client;
}) => {
  const i18nGlobal = useLocale();
  const i18n = i18nGlobal.admin;
  const i18nErrors = i18nGlobal.errors;
  const [query, queryState] = useUpdateClientMutation();
  const scriptedRef = useRef(true);
  const location = useLocation();
  const {client: { name: initialName, bin: initialBin }} = location.state as {client: Client};

  useEffect(
    () => () => {
      scriptedRef.current = false;
    },
    []
  );

  const initialValues: Omit<RequestUpdateClient, "id"> & { submit: any } = {
    name: initialName ?? client.name,
    bin: initialBin ?? client.bin,
    submit: null,
  };

  const latestValues = useRef(initialValues);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(i18nErrors.form.fieldRequired).max(255),
        bin: Yup.string().required(i18nErrors.form.fieldRequired).max(255),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        if (JSON.stringify(latestValues.current) !== JSON.stringify(values)) {
          try {
            await query({ name: values.name, bin: values.bin, id });
            if (scriptedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
          latestValues.current = values;
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} className="p-8">
          <div className="flex flex-col gap-8">
            <div className="w-1/2">
              <TextInput
                fullWidth
                id="name"
                label={i18n.clientName}
                placeholder={i18n.clientNamePlaceholder}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
              {touched.name && errors.name && (
                <FormHelperText error>{errors.name}</FormHelperText>
              )}
            </div>
            <div className="w-1/2">
              <TextInput
                fullWidth
                id="bin"
                label={i18n.BIN}
                placeholder={i18n.BINPlaceholder}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bin}
              />
              {touched.bin && errors.bin && (
                <FormHelperText error>{errors.bin}</FormHelperText>
              )}
              {errors.submit && (
                <FormHelperText error>{errors.submit as string}</FormHelperText>
              )}
              {isSubmitting && (
                <FormHelperText>{i18n.submitting}</FormHelperText>
              )}
            </div>
            <Stack direction="row" spacing="2rem" width="20rem">
              <Button
                disableElevation
                type="submit"
                disabled={queryState.isLoading || isSubmitting}
                fullWidth
                size="large"
                variant="contained"
                className="bg-primary text-white normal-case"
              >
                {i18n.edit}
              </Button>

              <Button
                disableElevation
                disabled={false}
                fullWidth
                size="large"
                variant="outlined"
                className="bg-primary text-white normal-case"
                onClick={handleCancel}
              >
                {i18n.cancel}
              </Button>
            </Stack>
            <span>
              {queryState.data &&
                queryState.data.status_code !== 0 &&
                i18nErrors[getByErrorCode(queryState.data.status_code)]}
              {queryState.data &&
                queryState.data.status_code === 0 &&
                i18n.clients.editSuccess}
            </span>
          </div>
        </form>
      )}
    </Formik>
  );
};
