import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

/**
 * Хук для выполнения логики при переходе на другую страницу (но не при перезагрузке).
 * @param {Function} fn - функция, которая будет вызвана при переходе на другую страницу.
 */
const useLeavePageLogic = (fn: () => void) => {
  const location = useLocation();
  useEffect(() => {
    sessionStorage.setItem("locationPathname", location.pathname);
  }, []);
  useEffect(() => () => {
    location.pathname === sessionStorage.getItem("locationPathname") || (() => {
      fn();
      console.log("fn called");
    })();
  }, [location.pathname]);
};

/**
 * Хук для проверки доступа к странице. Если доступа нет, то редирект на главную страницу.
 * Если доступ есть, то сохраняет в sessionStorage флаг, что доступ есть,
 * чтобы при перезагрузке страницы не было редиректа.
 * @returns {boolean} allowedAccess - флаг, есть ли доступ к странице. Отрисовывайте страницу, только если он true.
 */
export const useAllowedAccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [allowedAccess, setAllowedAccess] = useState(true);

  useEffect(() => {
    if (
      location.state?.fromAllowedPath || sessionStorage.getItem("allowedAccess") || location.pathname === sessionStorage.getItem("locationPathname")    ) {
      setAllowedAccess(true);
      sessionStorage.setItem("allowedAccess", "true");
    } else {
      setAllowedAccess(false);
      navigate("/");
    }
  }, []);

  const cleanup = () => sessionStorage.removeItem("allowedAccess");
  useLeavePageLogic(cleanup);

  return allowedAccess;
};
