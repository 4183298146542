import { useRef, useEffect, MouseEventHandler } from "react";
import { Button, Stack, FormHelperText } from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";

import { TextInput } from "shared/ui/form";
import { useLazyCreateSystemAdminQuery } from "entities/superAdmin";
import { getByErrorCode } from "shared/lib";
import { useLocale } from "entities/i18n";


const CreateSystemAdmin = ({
  handleCancel,
}: {
  handleCancel: MouseEventHandler;
}) => {
  const i18nGlobal = useLocale();
  const i18n = i18nGlobal.superAdmin;
  const i18nErrors = i18nGlobal.errors;
  const [query, queryState] = useLazyCreateSystemAdminQuery();
  const scriptedRef = useRef(true);

  useEffect(
    () => () => {
      scriptedRef.current = false;
    },
    []
  );

  const initialValues = {
    full_name: "",
    email: "",
    submit: null,
  };

  const latestValues = useRef(initialValues);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        full_name: Yup.string()
          .required(i18nErrors.form.fieldRequired)
          .max(255),
        email: Yup.string()
          .email(i18nErrors.form.emailFormat)
          .required(i18nErrors.form.fieldRequired),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        if (JSON.stringify(latestValues.current) !== JSON.stringify(values)) {
          try {
            await query(values);
            if (scriptedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
          latestValues.current = values;
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} className="p-8">
          <div className="flex flex-col gap-8">
            <div className="w-1/2">
              <TextInput
                fullWidth
                id="full_name"
                label={i18n.listHeaders.name}
                placeholder={i18n.listHeaders.name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.full_name}
              />
              {touched.full_name && errors.full_name && (
                <FormHelperText error>{errors.full_name}</FormHelperText>
              )}
            </div>
            <div className="w-1/2">
              <TextInput
                fullWidth
                id="email"
                label={i18n.listHeaders.email}
                placeholder="agency@agency.xxx"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              {touched.email && errors.email && (
                <FormHelperText error>{errors.email}</FormHelperText>
              )}
              {errors.submit && (
                <FormHelperText error>{errors.submit}</FormHelperText>
              )}
              {isSubmitting && (
                <FormHelperText>{i18n.submitting}</FormHelperText>
              )}
            </div>
            <Stack direction="row" spacing="2rem" width="20rem">
              <Button
                disableElevation
                type="submit"
                disabled={false}
                fullWidth
                size="large"
                variant="contained"
                className="bg-primary text-white normal-case"
              >
                {i18n.add}
              </Button>

              <Button
                disableElevation
                disabled={false}
                fullWidth
                size="large"
                variant="outlined"
                className="bg-primary text-white normal-case"
                onClick={handleCancel}
              >
                {i18n.cancel}
              </Button>
            </Stack>
            <span>
              {
                queryState.data &&
                queryState.data.status_code !== 0 &&
                i18nErrors[getByErrorCode(queryState.data.status_code)]
              }
            </span>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default CreateSystemAdmin;
