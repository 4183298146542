import { useMemo } from "react";

import { ShadowCard } from "shared/ui/components";
import { BarChart, LineChart } from "shared/ui/charts";
import { BarLineChart } from "shared/ui/charts";

type ChartCardProps = {
  chartType: "bar" | "bar-line" | "line";
  title: React.ReactNode;
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  xAxis: string[];
  yAxis?: ApexYAxis;
  className?: string;
};

// Виджет карточки с графиком
const ChartCard = ({
  chartType,
  title,
  series,
  xAxis,
  yAxis,
  className,
}: ChartCardProps) => {
  // Здесь по компоненту на каждый вариант chartType.
  const chartComponent = useMemo(() => {
    switch (chartType) {
      case "bar": 
        return <BarChart series={series} xAxis={xAxis} dataLabels />
      case "bar-line": 
        return <BarLineChart series={series} xAxis={xAxis} />
      case "line": 
        return <LineChart series={series} xAxis={xAxis} yAxis={yAxis} />
    }
  }, [chartType, series, xAxis, yAxis]);

  return (
    <ShadowCard
      className={`p-6 flex-1 w-[calc(25%-0.75rem)] ${
        className ? className : ""
      }`}
    >
      <span className="font-bold text-sm leading-none text-center block">
        {title}
      </span>
      {chartComponent}
    </ShadowCard>
  );
};

export default ChartCard;
