import { useDispatch } from "react-redux";
import AuthSlice, { logoutWithInvalidation } from "./authSlice";
import { useMemo } from "react";
import { bindActionCreators } from "@reduxjs/toolkit";

export const useAuthAction = () => {
  const dispatch = useDispatch();

  return useMemo(() => {
    return bindActionCreators(
      { ...AuthSlice.actions, logout: logoutWithInvalidation },
      dispatch,
    );
  }, [dispatch]);
};
