import { baseApi } from "shared/baseApi";
// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { getMemory } from "shared/lib";
// import { GlobalState } from "shared/types";
import {
  // PUBLIC_API_URL,
  QUERY_CREATE_CLIENT,
  QUERY_READ_CLIENTS,
  QUERY_READ_CLIENTS_BY_BIN,
  QUERY_UPDATE_CLIENT_STATUS,
  QUERY_UPDATE_CLIENT,
  QUERY_DELETE_CLIENT,
  QUERY_READ_CLIENTS_BY_AGENCY,
  QUERY_BRANDS,
  QUERY_READ_CONNECTORS_BY_AGENCY,
} from "shared/constants";

// export const ClientsApi = createApi({
export const ClientsApi = baseApi.injectEndpoints({
  // reducerPath: "ClientsApi",
  // baseQuery: fetchBaseQuery({
  //   baseUrl: PUBLIC_API_URL,
  //   prepareHeaders(headers, { getState }) {
  //     const state = getState() as GlobalState;
  //     headers.append("Authorization", `Bearer ${state.auth.accessToken}`)
  //     return headers;
  //   },
  //   // prepareHeaders: (headers) => {
  //   //   const token = getMemory("serviseAuth");
  //   //   if (token) {
  //   //     headers.set("Authorization", `Bearer ${JSON.parse(token).access}`);
  //   //   }
  //   //   return headers;
  //   // },
  // }),
  endpoints: (builder) => ({
    createClient: builder.mutation<ResponseCreateClient, RequestCreateClient>({
      query: (data) => ({
        url: QUERY_CREATE_CLIENT,
        method: "POST",
        body: { ...data },
      }),
    }),
    readClients: builder.query<ResponseReadClients, RequestReadClients>({
      query: (data) => ({
        url: QUERY_READ_CLIENTS,
        method: "GET",
        params: { ...data },
      }),
    }),
    readClientsByAgency: builder.query<
      ResponseReadClientsByAgency,
      RequestReadClientsByAgency
    >({
      query: (data) => ({
        url: QUERY_READ_CLIENTS_BY_AGENCY,
        method: "GET",
        params: { ...data },
      }),
    }),

    readClientsByAgencyWithApi: builder.query<
      ResponseReadClientsByAgencyWithApi,
      RequestReadClientsByAgency
    >({
      query: (data) => ({
        url: QUERY_READ_CONNECTORS_BY_AGENCY,
        method: "GET",
        params: { ...data },
      }),
    }),

    readClientsByBin: builder.query<
      ResponseReadClients,
      RequestReadClientsByBin
    >({
      query: (data) => ({
        url: QUERY_READ_CLIENTS_BY_BIN,
        method: "GET",
        params: { ...data },
      }),
    }),
    updateClientStatus: builder.mutation<
      ResponseCommon,
      RequestUpdateClientStatus
    >({
      query: (data) => ({
        url: QUERY_UPDATE_CLIENT_STATUS,
        method: "PUT",
        body: { ...data },
      }),
    }),
    updateClient: builder.mutation<ResponseUpdateClient, RequestUpdateClient>({
      query: (data) => ({
        url: QUERY_UPDATE_CLIENT,
        method: "PUT",
        body: { ...data },
      }),
    }),
    deleteClient: builder.mutation<ResponseCommon, RequestDeleteClient>({
      query: (data) => ({
        url: QUERY_DELETE_CLIENT,
        method: "DELETE",
        params: { ...data },
      }),
    }),

    //! ======
    //! Brands
    //! ======
    createBrand: builder.mutation<
      ResponseBrand,
      { name: string; client_id: number }
    >({
      query: (data) => ({
        url: QUERY_BRANDS,
        method: "POST",
        body: { ...data },
      }),
    }),
    readBrands: builder.query<
      ResponseReadBrands,
      { name?: string; client_id: number }
    >({
      query: (data) => ({
        url: QUERY_BRANDS,
        method: "GET",
        params: { ...data },
      }),
    }),
    updateBrand: builder.mutation<
      ResponseBrand,
      { name: string; client_id: number; id: number }
    >({
      query: (data) => ({
        url: QUERY_BRANDS,
        method: "PUT",
        body: { ...data },
      }),
    }),
    deleteBrand: builder.mutation<ResponseCommon, { brand_id: number }>({
      query: (data) => ({
        url: QUERY_BRANDS,
        method: "DELETE",
        params: { ...data },
      }),
    }),
  }),
});

export const {
  useCreateClientMutation,
  useReadClientsQuery,
  useReadClientsByAgencyQuery,
  useReadClientsByBinQuery,
  useUpdateClientStatusMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useReadClientsByAgencyWithApiQuery,

  useCreateBrandMutation,
  useReadBrandsQuery,
  useUpdateBrandMutation,
  useDeleteBrandMutation,
} = ClientsApi;
