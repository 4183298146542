import { Palette } from "@mui/material/styles";
import { useLocale } from "entities/i18n";
import { useNavigate } from "react-router-dom";
import { APIIconStack, APIIconStackProps } from "./APIIconStack";
import {
  IconAd,
  IconApi,
  IconInfoCircle,
  IconPower,
  IconTrash,
} from "@tabler/icons-react";
import { IconButtonStack } from "shared/ui/components";
import { ComplexListProps } from "shared/ui/list";

// const dataForAPIStack: APIIconStackProps["data"] = [
//   {
//     icon: "FACEBOOK",
//     color: "primary",
//     label: 100,
//   },
//   {
//     icon: "VK",
//     color: "primary",
//     label: 5,
//   },
//   {
//     icon: "TIKTOK",
//     color: "primary",
//     label: 25,
//   },
//   {
//     icon: "YANDEX",
//     color: "primary",
//     label: 17,
//   },
//   {
//     icon: "GOOGLE",
//     color: "primary",
//     label: 999,
//   },
//   {
//     icon: "MYTARGET",
//     color: "primary",
//     label: 1,
//   },
// ];

interface IDataForAPI {
  icon: "META" | "VK" | "TIKTOK" | "YANDEX" | "GOOGLE" | "MYTARGET";
  color: "primary";
  label: number;
}

const makeDataForAPIStack = (connectors?: Client["connectors"]) => {
  const result: IDataForAPI[] = [];
  if (!connectors || !connectors?.length) return result;

  const resultMap = new Map();

  ["FACEBOOK", "VK", "TIKTOK", "YANDEX", "GOOGLE", "MYTARGET"].forEach(
    (key: string) => resultMap.set(key, 0),
  );

  connectors.forEach((connector) => {
    const typeInResult = resultMap.get(connector.type);
    resultMap.set(connector.type, typeInResult + 1);
  });

  resultMap.forEach((val, key) => {
    // if (val) { //INFO: uncomment this if you dont want to show empty connectors
    result.push({
      icon: key,
      label: val,
      color: "primary",
    });
    // } //INFO: and also uncomment this
  });

  return result;
};

export const compileListData = (
  i18n: ReturnType<typeof useLocale>["admin"],
  clients: Client[],
  dialogOpenHandler: (action: DialogAction, index: number) => () => void,
  palette: Palette,
  activated: boolean[],
  navigate: ReturnType<typeof useNavigate>,
) => {
  const actionsProps = (
    i18n: ReturnType<typeof useLocale>["admin"],
    index: number,
  ) => ({
    iconButtons: [
      {
        icon: IconAd,
        /*             onClick: () => {navigate(`/system-admin/${item.id}`, { state: item })}, */
        onClick: () =>
          navigate(`/dashboard/${clients[index].id}`, {
            state: { client: clients[index], fromAllowedPath: true },
          }),
        // // navigate(`/clients/${clients[index].id}/api`, {
        // //   state: { client: clients[index] },
        // // }),
        // {
        //   ;
        //   // { state: { client: clients[index] } };
        // },
        tooltip: i18n.clients.activeCampaigns,
      },
      /* {
        icon: IconUserCircle,
        onClick: () =>
          navigate(`/clients/${clients[index].id}/users`, {
            state: { client: clients[index] },
          }),
        //? эта кнопка/вкладка вообще нужна, раз мы выпилили учетку клиента?
        tooltip: i18n.clients.usersTab,
      }, */
      {
        icon: IconApi,
        /*             onClick: handleButtonClick(index, "delete"), */
        onClick: () =>
          navigate(`/clients/${clients[index].id}/api`, {
            state: { client: clients[index] },
          }),
        tooltip: i18n.clients.api,
      },
      {
        icon: IconInfoCircle,
        onClick: () =>
          navigate(`/clients/${clients[index].id}/info`, {
            state: { client: clients[index] },
          }),
        tooltip: i18n.clients.info,
        iconProps: {
          color: palette.text.primary,
        },
      },
      {
        icon: IconPower,
        onClick: dialogOpenHandler("deactivate", index),
        //* will change by ternary
        tooltip: i18n.clients[activated[index] ? "deactivate" : "activate"],
      },
      {
        icon: IconTrash,
        onClick: dialogOpenHandler("delete", index),
        tooltip: i18n.clients.delete,
        iconProps: {
          color: palette.text.primary,
        },
      },
    ],
    iconProps: {
      size: 20,
      strokeWidth: 1.5,
      color: activated[index] ? palette.text.primary : palette.grey[400],
    },
    className: "gap-3 justify-end",
  });

  return {
    cols: [
      {
        name: "name",
        title: i18n.organizationName,
        specialColStyles: "w-[17rem]",
      },
      {
        name: "bin",
        title: i18n.BIN,
      },
      {
        name: "api",
        title: i18n.api,
        specialColStyles: "text-center",
      },
      /* {
        name: "campaigns",
        title: i18n.clients.activeCampaigns,
        specialColStyles: "text-center",
      }, */
      {
        name: "actions",
        title: "",
      },
    ],
    rows: clients.map((client, index) => ({
      name: {
        content: client.name,
        specialCellStyles: activated[index] ? "" : "text-grey-400",
      },
      bin: {
        content: client.bin,
        specialCellStyles: activated[index] ? "" : "text-grey-400",
      },
      api: {
        content: <APIIconStack data={makeDataForAPIStack(client.connectors)} />,
        specialCellStyles: activated[index]
          ? ""
          : "filter grayscale text-grey-400",
      },
      campaigns: {
        content: "(пока недоступно)",
        specialCellStyles: activated[index] ? "" : "text-grey-400",
      },
      actions: {
        content: <IconButtonStack {...actionsProps(i18n, index)} />,
      },
    })),
  } as ComplexListProps;
};
