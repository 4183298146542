export const formUrl = ({ link, state }: { link: string; state: string }) => {
  //WARN: link should end on &state
  return `${link}${state}`;
};

type ConnectorCreateProps = {
  query: any;
  type: string;
  name: string;
  agency_id: number;
  client_id: number;
  link: string;
  state: string;
};

export const connectorCreate = async ({
  query,
  type,
  name,
  agency_id,
  client_id,
  link,
  state,
}: ConnectorCreateProps) => {
  let error = "";
  //createConnector
  await query({
    type,
    name,
    agency_id,
    client_id,
  }).then((res: any) => {
    // }).then((res: {data: {message: string, result: {id: number}}}) => {
    //get connectorId
    if (res.data?.status_code === 0) {
      const connectorId = res.data?.result?.id;

      //form url and redirect
      const urlToGo = formUrl({ link, state: `${connectorId}||${state}` });
      window.location.href = urlToGo;
      return;
    }

    error = res.data?.message;
  });

  if (error) return error;
};
