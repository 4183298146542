import * as mui from "@mui/material";
import { useState } from "react";

type SwitchProps = {
  left: string;
  right: string;
  defaultChecked?: boolean;
  className?: string;
  onChange: (value: boolean) => void;
};

const sx = {
  "& .MuiSwitch-track": {
    backgroundColor: "grey.300",
    borderColor: "grey.400",
    borderWidth: "1px",
    borderStyle: "solid",
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "white",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "grey.300",
    boxShadow: "none",
    height: 10,
    width: 10,
    transform: "translate(50%, 50%)",
  },
};

const Switch = ({
  left,
  right,
  defaultChecked,
  className,
  onChange,
}: SwitchProps) => {
  const [isChecked, setIsChecked] = useState(defaultChecked || false);

  return (
    <div
      className={`h-5 w-content leading-0 text-sm ${
        className ? className : ""
      }`}
    >
      <span className={`${!isChecked && "font-bold"}`}>{left}</span>
      <mui.Switch
        onChange={(e) => {
          const state = e.target.checked;
          onChange(state);
          setIsChecked(state);
        }}
        defaultChecked={defaultChecked}
        className="mx-1"
        sx={sx}
        disableRipple
        disableTouchRipple
      />
      <span className={`${isChecked && "font-bold"}`}>{right}</span>
    </div>
  );
};

export default Switch;
