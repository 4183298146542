import MuiIconButton from "@mui/material/IconButton";
import { IconArrowLeft } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

import { CommonLayout } from "shared/ui";
import { CreateClient as CreateClientForm } from "features/clients";
import { useLocale } from "entities/i18n";
import { useAuthSelector } from "entities/auth";
import { useEffect, useState } from "react";

const iconProps = {
  color: "black",
  size: "20",
  strokeWidth: "1.5",
};

export const CreateClient = () => {
  const navigate = useNavigate();
  const i18n = useLocale().admin;

  const user = useAuthSelector().user;
  const [userIsAdmin, setUserIsAdmin] = useState(false);
  useEffect(() => {
    setUserIsAdmin(
      user?.roles.find((
        r,
      ) => (["ROOT", "MAIN_ADMIN", "SYSTEM_ADMIN"].includes(r.code))) !==
        undefined,
    );
  });

  const handleCancel = () => {
    userIsAdmin ? navigate(-1) : navigate("/clients");
  };

  return (
    <CommonLayout
      title={
        <div className="inline-flex flex-row items-center">
          <MuiIconButton
            aria-label="go back"
            edge="start"
            onClick={handleCancel}
          >
            <IconArrowLeft {...iconProps} />
          </MuiIconButton>
          <h1 className="block my-0 ml-4 leading-normal text-[2.25rem]">
            {i18n.clients.create}
          </h1>
        </div>
      }
    >
      <CreateClientForm handleCancel={handleCancel} />
    </CommonLayout>
  );
};
