import { SxProps, Theme } from "@mui/material/styles";
import TextInput from "./TextInput";

const AutocompleteSelectInput = (props: {
    options: string[];
    value?: string;
    className?: string;
    placeholder?: string;
    label?: React.ReactNode;
    onChange?: (value: string) => void;
    onPaste?: () => void;
    onBlur?: () => void;
    sx?: SxProps<Theme>;
  }) => {
  return (
    <TextInput
      fullWidth
      id="bin"
      label={props.label ? props.label : null}
      placeholder={props.placeholder}
      onChange={(e) => {
        props.onChange && props.onChange(e.target.value);
      }}
      onPaste={props.onPaste}
      onBlur={props.onBlur}
      className={props.className}
      sx={props.sx}
      autocomplete={{
        options: props.options,
        getLabel: (option) => option,
        onChange: (e, value) => {
          props.onChange && props.onChange(value);
        },
        value: props.value
      }}
      value={props.value}
    />
  );
};

export default AutocompleteSelectInput;
