import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { IconArrowLeft } from "@tabler/icons-react";

import { ProfileForm } from "widgets/profile";
import { useLocale } from "entities/i18n";
import { CommonLayout } from "shared/ui/layout";
import { backIconProps } from "shared/constants";

export default () => {
  
  const navigate = useNavigate();
  const handleCancel = () => navigate(-1);
  const {core: i18n} = useLocale();

  return (
    <CommonLayout
      title={
        <div className="inline-flex flex-row items-center">
          <IconButton aria-label="go back" edge="start" onClick={handleCancel}>
            <IconArrowLeft {...backIconProps} />
          </IconButton>
          <h1>{i18n.profile}</h1>
        </div>
      }
    >
      <ProfileForm />
    </CommonLayout>
  );
};
