export enum ROUTES {
  adminAgency = "/admin/agency",
  adminAgencies = "/admin/agencies",
  adminClientsOfAgency = "/admin/clientsOfAgency",
  createApi = "/create-api",
  ya_callback = "/api/v1/ya_callback",
  callback_page = "/api/v1",
  mediaplan = "/mediaplan",
}

//https://app.danametrics.ai/api/v1/ya_callback/#state=17%7C%7C3%7C%7Cdata.metrics%7C%7Cyandex%20test&access_token=y0_AgAAAABxtCTIAAiNpQAAAADwPF2IYE-KQoDuTEGJcmr9HxMi2NFJfto&token_type=bearer&expires_in=31535916
