import { Theme } from "@mui/material";

import * as dateLib from "shared/lib/dates";
import * as metricsLib from "shared/lib/metrics";
import { CabinetBadge } from "shared/ui/icons";

export const getDashboardContent = (data: StatEntry[], theme: Theme) => {
  const [startDate, endDate] = [
    /*     new Date(data[0].date.date_start),
    new Date(data[0].date.date_end), */
    //! temporary hardcoded dates until schema is fixed
    new Date(/* aug 15 */ 2023, 7, 15),
    new Date(/* sept 3 */ 2023, 8, 3),
  ];
  const content = {
    // mediaplan: data[0].mediaplan.name,
    //!temporary
    mediaplan: data[0].campaign_name,
    status: data[0].status,
    //!temporary
    brand: data[0].campaign_name,
    dates: dateLib.formatBetweenDates(startDate, endDate),
    daysPassed: dateLib.getDaysPassed(startDate, endDate),
    //!temporary
    // budget: metricsLib.prettifyNumber(data[0].budget.fact),
    budget: metricsLib.prettifyNumber(1000000),
    cabinets: Array.from(
      //!temporary
      // new Set(data.map((entry) => entry.cabinet.type))
      //eslint-disable-next-line
      new Set(data.map((entry) => entry.cabinet.type))
    ).map((type) => (
      <CabinetBadge key={type} icon={type} title="" color="primary" />
    )),
    chartMetrics: {
      budget: {
        series: [
          {
            name: "План",
            // data: data.map((entry) => entry.budget.plan),
            //!temporary
            //eslint-disable-next-line
            data: data.map((entry) => 1000000),
            color: theme.palette.primary.main,
          },
          {
            name: "Факт",
            // data: data.map((entry) => entry.budget.fact),
            //!temporary
            //eslint-disable-next-line
            data: data.map((entry) => 1234567),
            color: theme.palette.secondary.main,
          },
        ],
        // xAxis: data.map((entry) => entry.cabinet.name),
        //!temporary
        //eslint-disable-next-line
        xAxis: data.map((entry) => entry.cabinet.type),
      },
      ctr: {
        series: [
          {
            name: "План",
            data: data.map((entry) => Number(10)),
            color: theme.palette.primary.main,
          },
          {
            name: "Факт",
            data: data.map((entry) =>
              Number(
                metricsLib.calcCTR(entry.clicks, entry.impressions, "crop")
              )
            ),
            color: theme.palette.secondary.main,
          },
        ],
        // xAxis: data.map((entry) => entry.cabinet.name),
        xAxis: data.map((entry) => entry.cabinet.type),
      },
      vtr: {
        series: [
          {
            name: "План",
            data: data.map((entry) =>
              Number(
                metricsLib.calcVTR(
                  entry.video_views_p25 ?? 0,
                  entry.impressions,
                  "crop"
                )
              )
            ),
            color: theme.palette.primary.main,
          },
          {
            name: "Факт",
            data: data.map((entry) =>
              Number(
                metricsLib.calcVTR(
                  entry.video_views_p25 ?? 0,
                  entry.impressions,
                  "crop"
                )
              )
            ),
            color: theme.palette.secondary.main,
          },
        ],
        // xAxis: data.map((entry) => entry.cabinet.name),
        xAxis: data.map((entry) => entry.cabinet.type),
      },
      cr: {
        series: [
          {
            name: "План",
            data: data.map((entry) =>
              Number(metricsLib.calcCR(entry.conversions, entry.clicks, "crop"))
            ),
            color: theme.palette.primary.main,
          },
          {
            name: "Факт",
            data: data.map((entry) =>
              Number(metricsLib.calcCR(entry.conversions, entry.clicks, "crop"))
            ),
            color: theme.palette.secondary.main,
          },
        ],
        // xAxis: data.map((entry) => entry.cabinet.name),
        xAxis: data.map((entry) => entry.cabinet.type),
      },
      impressionsCpm: {
        series: [
          {
            name: "Показы",
            data: data.map((entry) => entry.impressions),
            color: theme.palette.secondary.main,
            type: "column",
          },
          {
            name: "CPM",
            data: data.map((entry) =>
              // Number(metricsLib.calcCPM(entry.budget.fact, entry.impressions))
              Number(metricsLib.calcCPM(entry.cost, entry.impressions))
            ),
            color: theme.palette.primary.main,
            type: "line",
          },
        ],
        // xAxis: data.map((entry) => entry.cabinet.name),
        xAxis: data.map((entry) => entry.cabinet.type),
      },
      clicksCpc: {
        series: [
          {
            name: "Клики",
            data: data.map((entry) => entry.clicks),
            color: theme.palette.secondary.main,
            type: "column",
          },
          {
            name: "CPC",
            data: data.map((entry) =>
              // Number(metricsLib.calcCPC(entry.budget.fact, entry.clicks))
              Number(metricsLib.calcCPC(entry.cost, entry.clicks))
            ),
            color: theme.palette.primary.main,
            type: "line",
          },
        ],
        // xAxis: data.map((entry) => entry.cabinet.name),
        xAxis: data.map((entry) => entry.cabinet.type),
      },
      viewsCpv: {
        series: [
          {
            name: "Просмотры",
            data: data.map((entry) => entry.video_views_p25 ?? 0),
            color: theme.palette.secondary.main,
            type: "column",
          },
          {
            name: "CPV",
            data: data.map((entry) =>
              // Number(metricsLib.calcCPV(entry.budget.fact, entry.video_views))
              Number(metricsLib.calcCPV(entry.cost, entry.video_views_p25 ?? 0))
            ),
            color: theme.palette.primary.main,
            type: "line",
          },
        ],
        // xAxis: data.map((entry) => entry.cabinet.name),
        xAxis: data.map((entry) => entry.cabinet.type),
      },
      conversionsCpa: {
        series: [
          {
            name: "Конверсии",
            data: data.map((entry) => entry.conversions),
            color: theme.palette.secondary.main,
            type: "column",
          },
          {
            name: "CPA",
            data: data.map((entry) =>
              // Number(metricsLib.calcCPA(entry.budget.fact, entry.conversions))
              Number(metricsLib.calcCPA(entry.cost, entry.conversions))
            ),
            color: theme.palette.primary.main,
            type: "line",
          },
        ],
        // xAxis: data.map((entry) => entry.cabinet.name),
        xAxis: data.map((entry) => entry.cabinet.type),
      },
      /*       installsCpu: {   //! installs currently not in schema
        series: [               //! not even nullish
          {
            name: "Установки",
            data: data.map((entry) => entry.installs),
            color: theme.palette.secondary.main,
            type: "column",
          },
          {
            name: "CPU",
            data: data.map((entry) =>
              Number(metricsLib.calcCPU(entry.budget.fact, entry.reach))
            ),
            color: theme.palette.primary.main,
            type: "line",
          },
        ],
        xAxis: data.map((entry) => entry.cabinet.name),
      }, */
      installsCpu: undefined,
    },
  };
  return content;
};
type returns = ReturnType<typeof getDashboardContent>;
export type DashboardContent = Omit<returns, "chartMetrics"> & {
  chartMetrics: Partial<returns["chartMetrics"]>;
};

export default getDashboardContent;
