import { WrappedComplexTable } from "shared/ui/table";
import { IconUserCircle, IconShieldChevron, Icon3dCubeSphere } from "@tabler/icons-react"

export const dataForTabBox = [
  /*   <div className="bg-blue-400 h-48" key={0}>content 1</div>,
  <div className="bg-blue-500 h-48" key={1}>content 2</div>,
  <div className="bg-blue-600 h-48" key={2}>content 3</div>,
   */
  {
  //  icon: <img src="/icons/user_circle.svg" alt="icon" className="w-full h-full"/>,
    icon: <IconUserCircle size={16} strokeWidth={1.5} />,
    title: "Item One",
    content: <div className="bg-blue-400 h-48">😵‍💫🤯⚛️content 1</div>,
  },
  {
    icon: <IconShieldChevron size={16} strokeWidth={1.5} />,
    title: "Item Two",
    content: <span>Item Two</span>,
  },
  {
    icon: <Icon3dCubeSphere size={16} strokeWidth={1.5} />,
    title: "Item Three",
    content: <div className="bg-blue-400 h-48">🥳content 3❤️</div>,
  }
];
