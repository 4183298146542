import { AgencyForm } from "widgets/admin";
import { CommonLayout } from "shared/ui/layout";
import { useLocale } from "entities/i18n";
import { useAuthSelector } from "entities/auth";
import { useNavigate } from "react-router-dom";
import { backIconProps } from "shared/constants";
import { IconButton } from "@mui/material";
import { IconArrowLeft } from "@tabler/icons-react";

const allowedRoles = ["ROOT", "SYSTEM_ADMIN"];

const CreateAgency = () => {
  const { admin, errors } = useLocale();
  const allowedIn = useAuthSelector().user?.roles.some((role) =>
    allowedRoles.includes(role.code)
  );

  const navigate = useNavigate();
  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <>
      <CommonLayout
        title={
          <div className="inline-flex flex-row items-center">
            <IconButton
              aria-label="go back"
              edge="start"
              onClick={handleCancel}
            >
              <IconArrowLeft {...backIconProps} />
            </IconButton>
            <h1>{admin.newAgency}</h1>
          </div>
        }
        className="bg-grey-200 py-8"
      >
        {allowedIn ? (
          <AgencyForm
            userRole="admin"
            type="create"
            handleCancel={handleCancel}
          />
        ) : (
          <div className="pt-8">{errors.notEnoughPrivileges}</div>
        )}
      </CommonLayout>
    </>
  );
};

export default CreateAgency;
