const contrastText = "#fff";
const primary = [
  "#fff4e6",
  "#ffdfb8",
  "#ffc98f",
  "#ffb066",
  "#ff943d",
  "#FFA900",
  "#cc5206",
  "#a63a00",
  "#802800",
  "#591900",
];
const secondary = ["#6CD9A3"];
const error = ["#FDE8E7", "#F25E52", "#F04134", "#EE3B2F", "#E92A21"];
const warning = ["#FFF7E0", "#FFC926", "#FFBF00", "#FFB900", "#FFA900"];
const info = ["#E0F4F5", "#26B0BA", "#00A2AE", "#009AA7", "#008694"];
const success = ["#E0F5EA", "#26B56E", "#00A854", "#00A04D", "#008D3A"];
const grey = {
  0: "#ffffff",
  50: "#fafafa",
  100: "#f5f5f5",
  200: "#f0f0f0",
  300: "#d9d9d9",
  400: "#bfbfbf",
  500: "#8c8c8c",
  600: "#595959",
  700: "#262626",
  800: "#141414",
  900: "#000000",
  A50: "#121212",
  A100: "#fafafa",
  A200: "#bfbfbf",
  A400: "#434343",
  A700: "#1f1f1f",
  A800: "#d3d8db",
};

const colors = {
  primary: {
    lighter: primary[0],
    100: primary[1],
    200: primary[2],
    light: primary[3],
    400: primary[4],
    main: primary[5],
    dark: primary[6],
    700: primary[7],
    darker: primary[8],
    900: primary[9],
    contrast: contrastText,
  },
  secondary: {
    main: secondary[0],
    contrast: grey[0],
  },
  error: {
    lighter: error[0],
    light: error[1],
    main: error[2],
    dark: error[3],
    darker: error[4],
    contrast: contrastText,
  },
  warning: {
    lighter: warning[0],
    light: warning[1],
    main: warning[2],
    dark: warning[3],
    darker: warning[4],
    contrast: grey[100],
  },
  info: {
    lighter: info[0],
    light: info[1],
    main: info[2],
    dark: info[3],
    darker: info[4],
    contrast: contrastText,
  },
  success: {
    lighter: success[0],
    light: success[1],
    main: success[2],
    dark: success[3],
    darker: success[4],
    contrast: contrastText,
  },
  grey
};

export default colors;
