import { Link as RouterLink } from "react-router-dom";
import { Grid, Link, Stack, Typography } from "@mui/material";

interface AnswerMessageProps extends React.SuspenseProps {
  title: string
  linkTo: string
  linkText: string
}

export default ({ title, children, linkTo, linkText }: AnswerMessageProps) => {
  return (
    <Grid container spacing={3} className="font-open-sans">
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          className="font-inter"
        >
          <Typography
            variant="subtitle2"
            color="dark"
            component="span"
            justifyContent="center"
            alignItems="center"
            className="font-inter"
            sx={{
              fontWeight: 700,
              fontSize: "36px",
            }}
          >
            {title}
          </Typography>
        </Stack>
      </Grid>
      <Typography
        variant="subtitle2"
        color="dark"
        component="span"
        justifyContent="center"
        alignItems="center"
        className="font-inter"
        sx={{
          mt: 8,
          mb: 8,
          font: "Open Sans",
          fontSize: "20px",
          lineHeight: "31px"
        }}
      >
        {children}
      </Typography>
      <Grid item xs={12} sx={{ mt: -1 }}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          className="font-inter"
        >
          <Link
            component={RouterLink}
            to={linkTo}
            color="text.primary"
            className="no-underline"
          >
            {linkText}
          </Link>
        </Stack>
      </Grid>
    </Grid> 
  );
}