import { baseApi } from "shared/baseApi";
// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { getMemory } from "shared/lib";
import {
  // PUBLIC_API_URL,
  QUERY_CREATE_CONNECTOR,
  QUERY_READ_CONNECTORS_BY_CLIENT,
  QUERY_READ_CONNECTORS_BY_AGENCY,
  QUERY_UPDATE_CONNECTOR_STATUS,
  QUERY_UPDATE_CONNECTOR,
  QUERY_DELETE_CONNECTOR,
  QUERY_CREATE_CREDS,
} from "shared/constants";

// export const ConnectorsApi = createApi({
export const ConnectorsApi = baseApi.injectEndpoints({
  // reducerPath: "ConnectorsApi",
  // baseQuery: fetchBaseQuery({
  //   baseUrl: PUBLIC_API_URL,
  //   prepareHeaders: (headers) => {
  //     const token = getMemory("serviseAuth");
  //     if (token) {
  //       headers.set("Authorization", `Bearer ${JSON.parse(token).access}`);
  //     }
  //     return headers;
  //   },
  // }),
  endpoints: (builder) => ({
    readConnectorsByClient: builder.query<
      PaginatedResponseReadConnectors,
      number
    >({
      query: (client_id) => ({
        url: `${QUERY_READ_CONNECTORS_BY_CLIENT}/?client_id=${client_id}`,
        method: "GET",
        // params: { ...data },
      }),
    }),

    readConnectorsByAgency: builder.query<ResponseReadConnectors, number>({
      query: (agency_id) => ({
        url: `${QUERY_READ_CONNECTORS_BY_AGENCY}/?agency_id=${agency_id}`,
        method: "GET",
        // params: { ...data },
      }),
    }),

    createConnector: builder.mutation<
      ResponseCreateConnector,
      RequestCreateConnector
    >({
      query: (data) => ({
        url: QUERY_CREATE_CONNECTOR,
        method: "POST",
        body: { ...data },
      }),
    }),

    createCredentials: builder.mutation<
      ResponseCreateCredentials,
      RequestCreateCredentials
    >({
      query: (data) => ({
        url: QUERY_CREATE_CREDS,
        method: "POST",
        body: { ...data },
      }),
    }),

    updateConnectorStatus: builder.mutation<
      ResponseCreateConnector,
      { id: number; is_active: boolean }
    >({
      query: (data) => ({
        url: QUERY_UPDATE_CONNECTOR_STATUS,
        method: "PUT",
        body: { ...data },
      }),
    }),

    updateConnector: builder.mutation<
      ResponseCreateConnector,
      { id: number; name?: string; is_active?: boolean }
    >({
      query: (data) => ({
        url: QUERY_UPDATE_CONNECTOR,
        method: "PUT",
        body: { ...data },
      }),
    }),

    deleteConnector: builder.mutation<
      ResponseCreateConnector,
      { connector_id: number }
    >({
      query: (data) => ({
        url: `${QUERY_DELETE_CONNECTOR}?connector_id=${data.connector_id}`,
        method: "DELETE",
        // body: { ...data },
      }),
    }),
  }),
});

export const {
  useReadConnectorsByClientQuery,
  useCreateConnectorMutation,
  useReadConnectorsByAgencyQuery,
  useUpdateConnectorMutation,
  useUpdateConnectorStatusMutation,
  useDeleteConnectorMutation,
  useCreateCredentialsMutation,
} = ConnectorsApi;
