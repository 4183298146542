import { useCurrentLocale } from "entities/i18n";
import { LocalePicker } from "features/i18n";

import { CommonLayout, Header } from "shared/ui/layout";
import { Footer } from "widgets/layout";

export const UserAgreement = () => {
  const locale = useCurrentLocale();

  return (
    <div
      className="flex flex-col justify-between "
      style={{
        minHeight: "100vh",
      }}
    >
      <div className="flex flex-row justify-between pr-10 items-start flex-grow-0">
        <Header startPage="/" />
        <div className="ml-5 mt-5">
          <LocalePicker />
        </div>
      </div>
      <CommonLayout title="">
        {locale === "rus" ? (
          <div
            className="flex-grow p-4 mx-auto"
            dangerouslySetInnerHTML={{
              __html: `


<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:Roboto;
	panose-1:2 0 0 0 0 0 0 0 0 0;}
 /* Style Definitions */
 a:link, span.MsoHyperlink
	{color:blue;
	text-decoration:underline;}
p
	{margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;
	}
p.MsoNoSpacing, li.MsoNoSpacing, div.MsoNoSpacing
	{margin:0cm;
	font-size:11.0pt;
	}
span.msoIns
	{mso-style-name:"";
	text-decoration:underline;
	color:teal;}
.MsoChpDefault
	{font-size:11.0pt;
	}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:2.0cm 42.5pt 2.0cm 3.0cm;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

<div class="WordSection1 font-inter">

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:1'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:18.0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>Пользовательское соглашение</span></b><b><span
lang=RU style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:18.0pt;
mso-ligatures:none;mso-ansi-language:RU;mso-fareast-language:#0C00'><o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:1'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:18.0pt;mso-ligatures:
none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Версия
1.0<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>г.
Алматы<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>28.10.2022
года<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>ТОО
«ADV Tech», в лице</span><span style='mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:RU;mso-fareast-language:
#0C00'> </span><span style='mso-ascii-font-family:Calibri;mso-hansi-font-family:
Calibri;mso-bidi-font-family:Calibri;color:#262626;letter-spacing:.1pt;
background:white'>И.О.Генерального директора Кагарова А.А.</span><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'> далее именуемый Администратор - это лицо,
предоставляющее услуги, указанные на </span><a
href="https://app.danametrics.ai/"><span style='mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;mso-font-kerning:
0pt;mso-ligatures:none;mso-fareast-language:#0C00'>https://app.danametrics.ai/</span></a><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-ansi-language:RU;mso-fareast-language:#0C00'> </span><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'><span style='mso-spacerun:yes'> </span>далее –
веб-сайт), именуемый– Сервис, и другие услуги (далее - Услуги). Danametrics
предназначен для создания, управления и анализа данных пользователей компании.
Настоящее Пользовательское соглашение является обязывающим соглашением между
Вами (далее - Пользователь) и ТОО «ADV Tech» Настоящее Пользовательское
соглашение (далее –Соглашение) распространяется на весь контент веб-сайта
https://app.danametrics.ai / , использование вами SaaS, и любую переписку между
нами. Если вы пользуетесь Сервисами, услугами, вы указываете, что принимаете
это Пользовательское соглашение независимо от того, решили ли вы
зарегистрироваться для использования Сервисов, Услуг. Если вы не принимаете это
Соглашение, пожалуйста, не используйте Услуги, Сервисы. Подписываясь на услугу,
вы соглашаетесь c Соглашением, регулирующим использование вами Услуг, включая
автономные компоненты, если таковые имеются. Это Соглашение должно объяснить и
детализировать наши обязательства как поставщика услуг и ваши обязательства как
клиента и потребителя услуги. Пожалуйста, прочитайте их внимательно. Настоящее
Соглашение является обязательным для любого использования Сервиса, Услуг и
применяется к вам с момента начала использования Сервиса, Услуг. Администратор
оставляет за собой право изменять настоящее Соглашение в любое время, которое
вступает в силу после публикации измененного Соглашения, и пользователь
считается уведомленным об изменениях после их публикации на веб-сайте, SaaS или
API. Хотя вполне вероятно, что Соглашение не изменится со временем, вы обязаны
убедиться, что вы прочитали, поняли и согласились с самой последней версией
Соглашения, доступным на Сайте.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:2'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>1. Определения<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>&quot;Плата
за доступ&quot; - означает плату (исключая любые налоги и сборы), уплачиваемую
вами в соответствии с Соглашением на Веб-сайте (которые Администратор может
время от времени изменять при уведомлении вас на Веб-сайте).
&quot;Конфиденциальная информация&quot; - включает в себя всю информацию,
которой обмениваются стороны настоящего Соглашения, в письменной, электронной
или устной форме, но не включает в себя информацию, которая является или
становится общедоступной, за исключением случаев несанкционированного раскрытия
другой стороной. &quot;Данные&quot;- означает любые данные, введенные вами на
Веб-сайт , вручную или автоматически. &quot;Право интеллектуальной
собственности&quot; - это означает любой патент, товарный знак, знак
обслуживания, авторское право, моральное право, право на дизайн, ноу-хау и
любые другие права интеллектуальной или промышленной собственности в любой
точке мира, независимо от того, зарегистрированы они или нет.
&quot;Веб-сайт&quot;- означает интернет-сайт https://app.danametrics.ai / или
любой другой сайт, управляемый Администратором. &quot;Вы&quot; - означает вас и
включает ваших подрядчиков, заказчиков, консультантов, представителей, агентов
и любые другие связанные с бизнесом стороны.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:2'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>2. Использование Сервисов<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Администратор
предоставляет вам право на доступ на использование Сервисов через Веб-сайт или
иным способом в соответствии с типом вашей подписки. Это право не является
эксклюзивным и не подлежит передаче и ограничено настоящим Соглашением.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:2'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>3. Ваши обязательства<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>3.1 Общие обязательства<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Вы
должны использовать Сервис только в своих законных внутренних коммерческих
целях, в соответствии с настоящими Условиями и любыми уведомлениями,
отправленными Администратором или размещенными на Сайте.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>3.2 Условия доступа<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Вы
обязаны обеспечить безопасность и конфиденциальность всех имен пользователей их
контактных телефонов и паролей, необходимых для доступа к Сервису. Вы
немедленно уведомите Администратора о любом несанкционированном использовании
ваших паролей или о любом другом нарушении безопасности, и Администратор
сбросит ваш пароль. Вы также можете сбросить пароль из учетной записи владельца
аккаунта. Как обязательное условие настоящего Соглашения при доступе к Сервисам
и их использовании вы должны:<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpFirst style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
auto;mso-add-space:auto;text-indent:-18.0pt;line-height:normal;mso-list:l5 level1 lfo5'><![if !supportLists]><span
style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:
#0C00'><span style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>не пытаться
подорвать безопасность или целостность вычислительных систем или сетей
Danametrics или, если Сервисы размещаются третьей стороной, вычислительные
системы и сети этой третьей стороны;<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
auto;mso-add-space:auto;text-indent:-18.0pt;line-height:normal;mso-list:l5 level1 lfo5'><![if !supportLists]><span
style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:
#0C00'><span style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>не пытаться
получить несанкционированный доступ к каким-либо материалам, кроме тех, на
которые у вас есть явное разрешение на доступ, или к компьютерной системе, на
которой размещены веб-сайт, SaaS, API;<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
auto;mso-add-space:auto;text-indent:-18.0pt;line-height:normal;mso-list:l5 level1 lfo5'><![if !supportLists]><span
style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:
#0C00'><span style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>не
передавать и не вводить в Сервисы любые файлы, которые могут повредить
компьютерные устройства или программное обеспечение любого другого лица,
контент, который может быть оскорбительным, или материал / данные в нарушение
любого закона (включая данные или другие материалы, защищенные авторским правом
или торговые секреты, которые вы не имеете права использовать); <o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
auto;mso-add-space:auto;text-indent:-18.0pt;line-height:normal;mso-list:l5 level1 lfo5'><![if !supportLists]><span
style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:
#0C00'><span style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>а также</span><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-ansi-language:RU;mso-fareast-language:#0C00'> </span><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>не изменять, не копировать, не адаптировать,
не воспроизводить, не разбирать, не декомпилировать или не перепроектировать
Сервисы, за исключением случаев, когда строго необходимо использовать любой из
них для нормальной работы</span><span lang=RU style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:RU;
mso-fareast-language:#0C00'>;</span><span style='mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p></o:p></span></p>

<p class=MsoListParagraphCxSpLast style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
auto;mso-add-space:auto;text-indent:-18.0pt;line-height:normal;mso-list:l5 level1 lfo5'><![if !supportLists]><span
style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:
#0C00'><span style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>не использовать
Сервисы каким-либо образом, который может нарушить функциональность Сервисов
или помешать любому другому пользователю использовать Сервисы.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>3.4 Возмещение<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Вы
освобождаете Администратора от всех претензий, затрат, ущерба и убытков,
возникших в результате нарушения вами любого из этих условий или любых
обязательств, которые вы можете иметь перед Администратором, включая (но не
ограничиваясь) любые расходы, связанные с восстановлением любых Платежей за
доступ, которые не были оплачены вами.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>3.5. Политика возврата<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Администратор
не производит возвраты оплаты в случае, если услуги оказаны согласно
оплаченного тарифа (подписки), указанного на странице
https://app.danametrics.ai/. Пользователь имеет право обратиться к
Администратору за возвратом оплаты в течение 14 календарных дней с момента
оплаты только в случае, если услуги не были предоставлены по условиям тарифа
(подписки), если иное не предусмотрено законодательством </span><span lang=RU
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-ansi-language:RU;mso-fareast-language:#0C00'>Республики Казахстан</span><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>. Если вы желаете осуществить возврат
оплаченных денежных средств, пожалуйста, напишите нашим специалистам на </span><a
href="mailto:dev@advtech.kz"><span lang=EN-US style='letter-spacing:.1pt;
mso-ansi-language:EN-US'>dev</span><span lang=RU style='letter-spacing:.1pt;
mso-ansi-language:RU'>@</span><span class=SpellE><span lang=EN-US
style='letter-spacing:.1pt;mso-ansi-language:EN-US'>advtech</span></span><span
lang=RU style='letter-spacing:.1pt;mso-ansi-language:RU'>.</span><span
class=SpellE><span lang=EN-US style='letter-spacing:.1pt;mso-ansi-language:
EN-US'>kz</span></span></a><span lang=RU style='color:#262626;letter-spacing:
.1pt;mso-ansi-language:RU'>.</span><span lang=RU style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:RU;
mso-fareast-language:#0C00'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:2'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>4. Конфиденциальность<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>4.1 Конфиденциальность<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Если
соответствующая сторона не имеет предварительного письменного согласия другой
стороны или если это не требуется по закону:<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>4.1.1.
Каждая сторона будет сохранять конфиденциальность всей Конфиденциальной
информации другой стороны, полученной в связи с настоящим Соглашением. Ни одна
из сторон не может без предварительного письменного согласия другой стороны
раскрывать или предоставлять какую-либо Конфиденциальную информацию любому лицу
или использовать ее в своих собственных интересах, за исключением случаев,
предусмотренных настоящим Соглашением.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>4.1.2.
Обязательства каждой стороны по данному пункту остаются в силе после
прекращения действия настоящих Условий. Положения пунктов 4.1.1 и 4.1.2 не
применяются к любой информации, которая: <o:p></o:p></span></p>

<p class=MsoListParagraphCxSpFirst style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
auto;mso-add-space:auto;text-indent:-18.0pt;line-height:normal;mso-list:l6 level1 lfo7'><![if !supportLists]><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:black;
mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>является
или становится общедоступной, кроме как в результате нарушения этого пункта;<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
auto;mso-add-space:auto;text-indent:-18.0pt;line-height:normal;mso-list:l6 level1 lfo7'><![if !supportLists]><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:black;
mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>получено от
третьего лица, которое законно приобрело его и не обязано ограничивать его
разглашение; <o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
auto;mso-add-space:auto;text-indent:-18.0pt;line-height:normal;mso-list:l6 level1 lfo7'><![if !supportLists]><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:black;
mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>находится в
распоряжении принимающей стороны без ограничений в отношении раскрытия до даты
получения от раскрывающей стороны; <o:p></o:p></span></p>

<p class=MsoListParagraphCxSpLast style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
auto;mso-add-space:auto;text-indent:-18.0pt;line-height:normal;mso-list:l6 level1 lfo7'><![if !supportLists]><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:black;
mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>или же</span><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-ansi-language:RU;mso-fareast-language:#0C00'> </span><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>самостоятельно разрабатывается без доступа к
Конфиденциальной информации.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>4.2 Приватность<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Администратор
поддерживает Политику конфиденциальности и Политику в отношении файлов cookie,
в которой изложены обязательства сторон в отношении данных. Вам следует
ознакомиться с этой Политику конфиденциальности и Политику в отношении файлов
cookie, так как вы обязаны принять её, чтобы принять условия настоящего
Соглашения.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>4.3 Законодательство<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Администратор
придерживается принципов, изложенных в законодательстве Республики Казахстан.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:2'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>5. Интеллектуальная собственность<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>5.1 Общее<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Право
собственности и все права на интеллектуальную собственность на Сервис и любую
документацию, относящуюся к Сервису, остаются собственностью Администратора.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>5.2 Данные<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Право
собственности и все права на интеллектуальную собственность на Данные остаются
вашей собственностью. Однако, если у вас есть платная учетная запись, ваш
доступ к ней зависит от полной оплаты Платы за доступ. Вы должны хранить копии
всех Данных, введенных в Сервисы. Администратор придерживается своей передовой
политики и процедур по предотвращению потери данных, включая ежедневный режим
резервного копирования данных системы, но не дает никаких гарантий того, что
потеря данных не произойдет. Администратор однозначно исключает ответственность
за любую потерю Данных независимо от их причины.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:2'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>6. Гарантии и заявления<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>6.1 Официальное заявление:<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Вы
имеете право использовать Сервисы и получать доступ к информации, к которой вы
обращаетесь, используя их. Если вы используете Сервисы от имени или в интересах
организации (независимо от того, является ли она корпоративной или нет),
Администратор будет предполагать, что вы имеете на это право, и эта организация
будет нести ответственность за ваши действия или бездействие (включая любые
нарушение настоящих Условий). Предоставление доступа и использование Сервиса
осуществляется «как есть» и на ваш собственный риск. Администратор не
гарантирует, что использование Сервисов будет бесперебойным или безошибочным.
Помимо прочего, работа и доступность систем, используемых для доступа к
Услугам, включая услуги телефонной связи общего пользования, компьютерные сети
и Интернет, могут быть непредсказуемыми и могут время от времени мешать или
препятствовать доступу к Услугам. Администратор никоим образом не несет
ответственности за любое такое вмешательство или предотвращение вашего доступа
или использования Сервисов. Несмотря на то, что система будет регулярно
обновляться - вы несете единоличную ответственность за определение того, что
Услуги соответствуют потребностям вашего бизнеса.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>6.2 Отсутствие гарантий<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Администратор
не дает никаких гарантий для Сервиса. Не ограничивая вышесказанное,
Администратор не гарантирует, что Сервис будет соответствовать вашим
требованиям или будет соответствовать вашим целям. Во избежание сомнений все
подразумеваемые условия или гарантии исключаются в той степени, в которой это
разрешено законом, включая (без ограничения) гарантии торговых возможностей,
пригодности для целей, правового титула и правонарушений.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>6.3 Потребительские гарантии<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Вы
гарантируете и подтверждаете, что приобретаете право на доступ и использование
Сервиса и соглашаетесь с настоящим Пользовательским соглашением для
коммерческих целей. В максимальной степени, разрешенной законом, любые
предусмотренные законом гарантии потребителей или законодательство,
предназначенные для защиты некоммерческих потребителей в любой юрисдикции, не
распространяются на предоставление Сервисов.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:2'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>7. Ограничение ответственности<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>В
максимальной степени, разрешенной законом, Администратор исключает всю
ответственность и ответственность перед вами (или любым другим лицом) по
договору, деликту (включая небрежность) или иным образом за любые убытки
(включая потерю Данных, прибыли и сбережения) или ущерб, возникший в результате
прямо или косвенно, от использования или использования Сервисов. Если вы
понесете убытки или ущерб в результате халатности или несоблюдения
Администратором настоящего Соглашения, а иск от вас или вашей организации к
Администратору, возникший в результате халатности или сбоя Danametrics, будет
ограничен в отношении любого отдельного инцидента или серии связанных
инцидентов, размером последней платы за доступ, оплаченной вами. Если вы не
удовлетворены Услугами, ваше единственное и исключительное средство -
прекратить действие настоящих Условий в соответствии с пунктом 8.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:2'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>8.Прекращение<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>8.1 Прекращение без вины Сторон<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Настоящие
Условия будут действовать в течение всего периода использования Сервисов
Пользователем. Настоящие Условия будут автоматически продолжаться в течение
того же периода, если только одна из сторон не прекратит действие настоящих
Условий, уведомив об этом другую сторону не менее чем за 30 дней. Нарушение:
если вы: нарушили любое из настоящих Условий и не исправляли нарушение в
течение 14 дней после получения уведомления о нарушении, если нарушение может
быть исправлено; нарушили любое положение Соглашения, и нарушение не может быть
исправлено (что включает (без ограничения) задержку в любом платеже); или же
компания, платящий пользователь проходит процедуру ликвидации или назначает
приемника или управляющего какого-либо из своих активов, становится неплатежеспособным,
заключает какие-либо соглашения со своими кредиторами или становится объектом
какого-либо аналогичного случая несостоятельности в любой юрисдикции,
Администратор может предпринять любое или все из следующих действий по своему
усмотрению: Прекратить действие настоящих Условий и использование вами Услуг; </span><span
lang=RU style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;
mso-ligatures:none;mso-ansi-language:RU;mso-fareast-language:#0C00'>п</span><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>риостановить на любое определенное или
неопределенное время использование вами Услуг;</span><span lang=RU
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-ansi-language:RU;mso-fareast-language:#0C00'> в</span><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>ыполнить любое из указанных выше действий в
отношении любых других лиц в вашей организации или тех, кто имеет доступ к
вашей информации или информации вашей организации.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>8.2 Полученные права<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Прекращение
действия настоящих Условий не наносит ущерба правам и обязанностям сторон,
приобретённые до даты расторжения включительно. При расторжении настоящего
Соглашения вы обязаны: немедленно прекратить использование Сервисов; вы
остаётесь ответственными за произведение всех необходимых оплат до или после
расторжения.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>8.3 Условия оплаты<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Оплата
услуг производится в соответствии с условиями, указанными на сайте </span><a
href="https://app.danametrics.ai"><span style='mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;mso-font-kerning:
0pt;mso-ligatures:none;mso-fareast-language:#0C00'>https://app.danametrics.ai</span></a><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-ansi-language:RU;mso-fareast-language:#0C00'> </span><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>. Оплата не возвращается. Не будет никаких
возвратов или кредитов за неполные месяцы или годы обслуживания, обновления /
понижения аккаунтов или за неиспользованные месяцы / годы. Произвести оплату
имеет право только физическое лицо от собственного имени.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>8.4 Истечение или прекращение<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Пункты
3, 4, 5, 6, 7, 8, 9 и 10 остаются в силе после истечения срока действия или
прекращения действия настоящего Соглаш��ния.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:2'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>9. Справочная служба / Поддержка<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>9.1 Технические проблемы:<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>В
случае технических проблем вы должны приложить все разумные усилия для
расследования и диагностики проблем, прежде чем обращаться к Администратору.
Если вам все еще нужна техническая помощь, пожалуйста, напишите нам по адресу </span><a
href="mailto:dev@advtech.kz"><span lang=EN-US style='letter-spacing:.1pt;
mso-ansi-language:EN-US'>dev</span><span lang=RU style='letter-spacing:.1pt;
mso-ansi-language:RU'>@</span><span class=SpellE><span lang=EN-US
style='letter-spacing:.1pt;mso-ansi-language:EN-US'>advtech</span></span><span
lang=RU style='letter-spacing:.1pt;mso-ansi-language:RU'>.</span><span
class=SpellE><span lang=EN-US style='letter-spacing:.1pt;mso-ansi-language:
EN-US'>kz</span></span></a><span style='mso-fareast-font-family:"Times New Roman";
mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;color:black;
mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>9.2 Доступность сервиса<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Хотя
Администратор предполагает, что Сервисы должны быть доступны 24 часа в сутки,
семь дней в неделю, возможно, что в некоторых случаях они могут быть недоступны
для проведения технического обслуживания или другой деятельности по разработке.
Если по какой-либо причине Администратору придется прерывать Услуги на более
длительные периоды, чем обычно, мы будем прилагать разумные усилия для
предварительной публикации подробной информации о такой деятельности.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:2'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>10. Прочее<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>10.1 Полное согласие<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Настоящее
Соглашение вместе с Политикой конфиденциальности и Политика в отношении файлов
Cookie и условиями любых других уведомлений или инструкций, предоставляемых вам
в соответствии с этими условиями, заменяют и аннулируют все предыдущие
соглашения, заявления (устные или письменные), а также договоренности и
составляют полное соглашение между Вами и Администратором, относящиеся к
Услугам и другим вопросам, рассматриваемым в настоящих Условиях.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>10.2 Отказ от права<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Если
какая-либо из сторон отказывается от любого пункта настоящего Соглашения, это
не будет означать отказ от любого другого нарушения. Никакой отказ не будет
эффективным, если не сделан в письменной форме.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>10.3 Задержки<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Ни
одна из сторон не будет нести ответственность за любую задержку или
неисполнение своих обязательств в соответствии с настоящими Условиями, если
задержка или сбой произошли по какой-либо причине, находящейся вне ее разумного
контроля. Этот пункт не распространяется на какие-либо обязательства по выплате
денег.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>10.4 Уступка прав<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Вы
не можете переуступать или передавать какие-либо права любому другому лицу без
предварительного письменного согласия Администратора. Настоящее Соглашение
будет регулироваться и толковаться в соответствии с законодательством
Республики Казахстан. Любые претензии, судебные разбирательства, связанные с
Сервисами, будут подаваться исключительно по месту нахождения Администратора, и
вы соглашаетесь с юрисдикцией таких судов.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>10.5 Недействительность положений<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Если
какая-либо часть или положение настоящего Соглашения является недействительным,
не имеющим законной силы или противоречит закону, эта часть или положение
заменяется положением, которое, насколько это возможно, выполняет
первоначальную цель этой части или положения. Остальная часть настоящего
Соглашения будет обязательной для сторон.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>10.6 Уведомления<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Любое
уведомление, предоставленное в соответствии с настоящими Условиями одной из
сторон другой стороне, должно быть отправлено в письменном виде по электронной
почте и будет считаться полученным при передаче. Уведомления Администратору
должны быть отправлены на </span><a href="mailto:dev@advtech.kz"><span
lang=EN-US style='letter-spacing:.1pt;mso-ansi-language:EN-US'>dev</span><span
lang=RU style='letter-spacing:.1pt;mso-ansi-language:RU'>@</span><span
class=SpellE><span lang=EN-US style='letter-spacing:.1pt;mso-ansi-language:
EN-US'>advtech</span></span><span lang=RU style='letter-spacing:.1pt;
mso-ansi-language:RU'>.</span><span class=SpellE><span lang=EN-US
style='letter-spacing:.1pt;mso-ansi-language:EN-US'>kz</span></span></a><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'> или на любой другой адрес электронной почты,
о котором вам сообщит Администратор. Уведомления будут отправлены на адрес
электронной почты, который вы указали при настройке доступа к Сервису.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal;mso-outline-level:3'><b><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'>10.7 Права третьих лиц<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Лицо,
не являющееся участником настоящего Соглашения, не имеет права пользоваться
преимуществами этого Соглашения или применять их.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Товарищество
с ограниченной ответственностью «ADV Tech» Адрес: Республика Казахстан, город
Алматы, Медеуский район, улица Рубинштейна, дом 48, почтовый индекс 050020 БИН
220240029882 ОКЭД 62011 АО &quot;Банк ЦентрКредит&quot; БИК KCJBKZKX
KZ508562203116332339 (KZT)<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span lang=RU style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-ansi-language:RU;
mso-fareast-language:#0C00'>И.О.</span><span style='mso-fareast-font-family:
"Times New Roman";mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
color:black;mso-font-kerning:0pt;mso-ligatures:none;mso-fareast-language:#0C00'>Генеральн</span><span
lang=RU style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;
mso-ligatures:none;mso-ansi-language:RU;mso-fareast-language:#0C00'>ого</span><span
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-fareast-language:#0C00'> директор</span><span lang=RU
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-ansi-language:RU;mso-fareast-language:#0C00'>а<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
mso-add-space:auto;line-height:normal'><span class=SpellE><span lang=RU
style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;mso-ligatures:
none;mso-ansi-language:RU;mso-fareast-language:#0C00'>Кагаров</span></span><span
lang=RU style='mso-fareast-font-family:"Times New Roman";mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;color:black;mso-font-kerning:0pt;
mso-ligatures:none;mso-ansi-language:RU;mso-fareast-language:#0C00'> А.А.<o:p></o:p></span></p>

</div>
`,
            }}
          ></div>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: `
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:Roboto;
	panose-1:2 0 0 0 0 0 0 0 0 0;}
 /* Style Definitions */
 a:link, span.MsoHyperlink
	{color:blue;
	text-decoration:underline;}
p
	{margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;
	}
p.MsoNoSpacing, li.MsoNoSpacing, div.MsoNoSpacing
	{margin:0cm;
	font-size:11.0pt;
	}
span.msoIns
	{mso-style-name:"";
	text-decoration:underline;
	color:teal;}
.MsoChpDefault
	{font-size:11.0pt;
	}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:2.0cm 42.5pt 2.0cm 3.0cm;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>
           </div>
<div class="WordSection1 pl-4">

<p><b><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>User Agreement <o:p></o:p></span></b></p>

<p><b><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'><o:p>&nbsp;</o:p></span></b></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Version
1.0<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Almaty<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>October
28, 2022<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>LLP
&quot;ADV Tech&quot;, represented by </span><span lang=EN-US style='font-size:
11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;
mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;mso-ansi-language:
EN-US'>CEO</span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'> Kagarov A.A., hereinafter referred to as the Administrator, is
the entity providing the services specified on </span><a
href="https://app.danametrics.ai/" target="_new"><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:
minor-latin;mso-bidi-theme-font:minor-latin'>https://app.danametrics.ai/</span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>
(hereinafter referred to as the Website), referred to as the Service, and other
services (hereinafter referred to as the Services). Danametrics is designed for
the creation, management, and analysis of company user data. This User
Agreement is a binding agreement between you (hereinafter referred to as the
User) and LLP &quot;ADV Tech.&quot; This User Agreement (hereinafter referred
to as the Agreement) applies to all content of the website </span><a
href="https://app.danametrics.ai/" target="_new"><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:
minor-latin;mso-bidi-theme-font:minor-latin'>https://app.danametrics.ai/</span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>,
your use of SaaS, and any correspondence between us. By using the Services, you
indicate that you accept this User Agreement whether or not you choose to
register to use the Services. If you do not accept this Agreement, please do
not use the Services. By subscribing to the service, you agree to the Agreement
governing your use of the Services, including any standalone components, if
applicable. This Agreement is intended to explain and detail our obligations as
a service provider and your obligations as a client and service consumer.
Please read them carefully. This Agreement is binding for any use of the
Service and Services and applies to you from the moment you start using the
Service and Services. The Administrator reserves the right to modify this Agreement
at any time, which becomes effective upon the publication of the revised
Agreement, and the User is deemed to be notified of the changes after they are
posted on the Website, SaaS, or API. Although it is likely that the Agreement
will not change over time, you are obligated to ensure that you have read,
understood, and agreed to the most recent version of the Agreement available on
the Website.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;mso-ansi-language:EN-US'>1. </span><b><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:
minor-latin;mso-bidi-theme-font:minor-latin'>Definitions<o:p></o:p></span></b></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>&quot;Access
Fee&quot; means the fee (excluding any taxes and charges) payable by you in
accordance with the Agreement on the Website (which the Administrator may
modify from time to time upon notice to you on the Website).</span><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
mso-ansi-language:EN-US'><o:p></o:p></span></p>

<p class=MsoNormal><span style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:
minor-latin'>&quot;Confidential Information&quot; includes all information
exchanged between the parties of this Agreement, in written, electronic, or
oral form, but does not include information that is or becomes publicly
available, except as a result of unauthorized disclosure by the other party.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>&quot;Data&quot;
means any data entered by you on the Website, manually or automatically.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>&quot;Intellectual
Property Rights&quot; means any patent, trademark, service mark, copyright,
moral right, design right, know-how, and any other intellectual or industrial
property rights anywhere in the world, whether registered or not.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>&quot;Website&quot;
means the internet site https://app.danametrics.ai/ or any other site operated
by the Administrator.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>&quot;You&quot;
means you and includes your contractors, customers, consultants,
representatives, agents, and any other business-related parties.<br>
<br>
<b>2. Use of Services</b><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>The
Administrator grants you the right to access and use the Services via the
Website or otherwise in accordance with your subscription type. This right is
non-exclusive, non-transferable, and subject to the terms of this Agreement.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>3. Your
Obligations<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>3.1
General Obligations<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>You must
use the Service solely for your lawful internal business purposes, in
accordance with these Terms and any notices sent by the Administrator or posted
on the Website.<br>
<br>
<b>3.2 Access Conditions</b><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;mso-margin-bottom-alt:
8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span style='mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin'>You must ensure the security and
confidentiality of all usernames, contact numbers, and passwords required to
access the Service. You must immediately notify the Administrator of any
unauthorized use of your passwords or any other breach of security, and the
Administrator will reset your password. You may also reset the password from
the account owner's account. As a condition of this Agreement, when accessing
and using the Services, you must:<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpFirst style='text-indent:-18.0pt;mso-list:l4 level1 lfo4'><![if !supportLists]><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin'>not attempt to undermine the security or
integrity of Danametrics' computing systems or networks or, where the Services
are hosted by a third party, that third party's computing systems and networks;<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt;mso-list:l4 level1 lfo4'><![if !supportLists]><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin'>not attempt to gain unauthorized access to any
materials other than those to which you have been expressly permitted access,
or to the computer system on which the Website, SaaS, or API is hosted;<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt;mso-list:l4 level1 lfo4'><![if !supportLists]><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin'>not transmit or input into the Services any
files that may damage any other person's computing devices or software, content
that may be offensive, or material/data in violation of any law (including data
or other material protected by copyright or trade secrets which you do not have
the right to use); <o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt;mso-list:l4 level1 lfo4'><![if !supportLists]><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin'>and</span><span style='mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;mso-ansi-language:EN-US'> </span><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>not
modify, copy, adapt, reproduce, disassemble, decompile, or reverse engineer the
Services except as strictly necessary to use any of them for normal operation;<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpLast style='text-indent:-18.0pt;mso-list:l4 level1 lfo4'><![if !supportLists]><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin'>not use the Services in any way that could
impair the functionality of the Services or interfere with any other user's use
of the Services.<o:p></o:p></span></p>

<p class=MsoNormal><b><span style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:
minor-latin'>3.4 Indemnity</span></b><span style='mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>You
indemnify the Administrator against all claims, costs, damages and losses
arising from your breach of any of these terms and conditions or any liability
you may have to the Administrator, including (but not limited to) any costs
associated with recovering any Access Fees that have not been paid by you.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>3.5 Refund
Policy<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>The
Administrator will not issue refunds of fees in the event that services are
rendered pursuant to the paid rate (subscription) listed on
https://app.danametrics.ai/. The User has the right to request a refund from
the Administrator within 14 calendar days from the date of payment only if the
services were not provided under the terms of the tariff (subscription), unless
otherwise stipulated by </span><span lang=EN-US style='mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;mso-ansi-language:EN-US'>the </span><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>Republic
of Kazakhstan law. If you wish to request a refund of the paid funds, please
contact our specialists at </span><a href="mailto:dev@advtech.kz"><span
lang=EN-US style='letter-spacing:.1pt;mso-ansi-language:EN-US'>dev@advtech.kz</span></a><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
lang=EN-US style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
mso-ansi-language:EN-US'>4. </span></b><b><span style='mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin'>Confidentiality<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>4.1
Confidentiality<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>Unless the
relevant party has prior written consent from the other party or if required by
law:<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>4.1.1.
Each party will maintain the confidentiality of all Confidential Information of
the other party obtained in connection with this Agreement. Neither party may,
without the prior written consent of the other party, disclose or make
available any Confidential Information to any person or use it for its own
benefit, except as provided in this Agreement.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;mso-margin-bottom-alt:
8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span style='mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin'>4.1.2. The obligations of each party
under this clause will remain in force after the termination of these Terms.
The provisions of clauses 4.1.1 and 4.1.2 do not apply to any information that:<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpFirst style='text-indent:-18.0pt;mso-list:l4 level1 lfo4'><![if !supportLists]><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin'>is or becomes publicly available other than as
a result of a breach of this clause;<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt;mso-list:l4 level1 lfo4'><![if !supportLists]><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin'>is obtained from a third party who lawfully
acquired it and is not obligated to restrict its disclosure;<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='text-indent:-18.0pt;mso-list:l4 level1 lfo4'><![if !supportLists]><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin'>is in the possession of the receiving party
without limitation in relation to disclosure before the date of receipt from
the disclosing party; or<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpLast style='text-indent:-18.0pt;mso-list:l4 level1 lfo4'><![if !supportLists]><span
style='mso-ascii-font-family:Calibri;mso-fareast-font-family:Calibri;
mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri'><span
style='mso-list:Ignore'>•<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span style='mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin'>is independently developed without access to
the Confidential Information.<o:p></o:p></span></p>

<p class=MsoNormal><span style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:
minor-latin'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>4.2
Privacy<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>The
Administrator upholds the Privacy Policy and the Cookie Policy, which outline
the obligations of the parties concerning data. You should review the Privacy
Policy and the Cookie Policy, as you are required to accept them in order to
agree to the terms of this Agreement.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>4.3
Legislation<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>The
Administrator adheres to the principles set forth in the legislation of the
Republic of Kazakhstan.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>5.
Intellectual Property<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>5.1
General<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>Ownership
and all intellectual property rights in the Service and any documentation
related to the Service remain the property of the Administrator.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>5.2 Data<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>Ownership
and all intellectual property rights in the Data remain your property. However,
if you have a paid account, your access to it is contingent upon full payment
of the Access Fee. You should maintain copies of all Data entered into the
Services. The Administrator adheres to best practices and procedures to prevent
data loss, including a daily system data backup regime, but makes no guarantees
that data loss will not occur. The Administrator expressly excludes liability
for any loss of Data, regardless of the cause.<br style='mso-special-character:
line-break'>
<![if !supportLineBreakNewLine]><br style='mso-special-character:line-break'>
<![endif]><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>6. </span></b><b><span
lang=EN-US style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
mso-ansi-language:EN-US'>G</span></b><b><span style='mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin'>uarantees and Representations<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>6.1
Official Statement:<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>You have
the right to use the Services and access the information you obtain through
them. If you use the Services on behalf of or in the interest of an
organization (whether it is corporate or not), the Administrator will assume
that you have the authority to do so, and that organization will be held
responsible for your actions or omissions (including any breaches of these
Terms). Access to and use of the Service is provided &quot;as is&quot; and at
your own risk. The Administrator does not warrant that the use of the Services
will be uninterrupted or error-free. Among other things, the operation and
availability of the systems used to access the Services, including public
telephone services, computer networks, and the Internet, can be unpredictable
and may from time to time interfere with or prevent access to the Services. The
Administrator is in no way responsible for any such interference or prevention
of your access to or use of the Services. Despite regular system updates, you
are solely responsible for determining that the Services meet the needs of your
business.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>6.2 No </span></b><b><span
lang=EN-US style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
mso-ansi-language:EN-US'>G</span></b><b><span style='mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin'>uarantees<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>The
Administrator makes no warranties regarding the Service. Without limiting the
foregoing, the Administrator does not warrant that the Service will meet your
requirements or be suitable for your purposes. For the avoidance of doubt, all
implied conditions or warranties are excluded to the extent permitted by law,
including (without limitation) warranties of merchantability, fitness for
purpose, title, and non-infringement.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>6.3
Consumer Guarantees<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>You
warrant and represent that you are acquiring the right to access and use the
Service and agree to this User Agreement for commercial purposes. To the
maximum extent permitted by law, any statutory consumer guarantees or
legislation intended to protect non-commercial consumers in any jurisdiction do
not apply to the provision of the Services.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>7.
Limitation of Liability<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>To the
maximum extent permitted by law, the Administrator excludes all liability and
responsibility to you (or any other person) in contract, tort (including
negligence), or otherwise for any loss (including loss of Data, profits, and
savings) or damage resulting, directly or indirectly, from the use of or
reliance on the Services. If you suffer loss or damage as a result of the
Administrator's negligence or failure to comply with this Agreement, any claim
by you or your organization against the Administrator arising from the
Administrator's negligence or failure will be limited in respect of any one
incident, or series of connected incidents, to the amount of the last Access
Fee paid by you. If you are not satisfied with the Services, your sole and
exclusive remedy is to terminate these Terms in accordance with clause 8.<br
style='mso-special-character:line-break'>
<![if !supportLineBreakNewLine]><br style='mso-special-character:line-break'>
<![endif]><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>8.Termination<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>8.1
Termination without Fault of the Parties<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>These
Terms shall remain in effect for the entire period of the User's use of the
Services. These Terms shall automatically continue for the same period unless
terminated by either party by giving notice to the other party no less than 30
days in advance. Breach: if you: breach any of these Terms and fail to remedy
the breach within 14 days of receiving notice of the breach, if the breach is capable
of being remedied; breach any provision of the Agreement, and the breach cannot
be remedied (including (without limitation) a delay in any payment); or the
company, paying user undergoes liquidation proceedings or appoints a receiver
or manager of any of its assets, becomes insolvent, enters into any arrangement
with its creditors, or becomes subject to any analogous insolvency event in any
jurisdiction, the Administrator may take any or all of the following actions at
its discretion: Terminate these Terms and your use of the Services; Suspend
your use of the Services for any specified or unspecified period of time; Take
any of the above actions in relation to any other person in your organization
or those who have access to your information or your organization's
information.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>8.2 Rights
Acquired<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>Termination
of these Terms does not affect the rights and obligations of the parties
acquired up to and including the date of termination. Upon termination of this
Agreement, you are required to: immediately cease using the Services; you
remain responsible for making all necessary payments before or after
termination.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>8.3
Payment Terms<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>Payment
for services is made in accordance with the terms specified on the website
https://app.danametrics.ai/. Payment is non-refundable. There will be no
refunds or credits for partial months or years of service, account
upgrades/downgrades, or for unused months/years. Only an individual has the
right to make a payment on behalf of themselves.<br>
<br>
<b>8.4 Expiration or Termination</b><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>Paragraphs
3, 4, 5, 6, 7, 8, 9, and 10 shall remain in force after the expiration or
termination of this Agreement.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>9.
Customer Service / Support<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>9.1
Technical Issues:<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>In case of
technical issues, you must make all reasonable efforts to investigate and
diagnose the problem before contacting the Administrator. If you still require
technical assistance, please contact us at </span><a
href="mailto:dev@advtech.kz"><span lang=EN-US style='letter-spacing:.1pt;
mso-ansi-language:EN-US'>dev@advtech.kz</span></a><span style='mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin'>.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>9.2
Service Availability<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>While the
Administrator assumes that the Services should be available 24 hours a day,
seven days a week, it is possible that in some cases they may be unavailable
for maintenance or other development activities. If, for any reason, the
Administrator needs to interrupt the Services for longer periods than usual, we
will make reasonable efforts to provide advance notice of such activity.<br>
<b><br>
10. Miscellaneous<o:p></o:p></b></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>10.1
Entire Agreement<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>This
Agreement, together with the Privacy Policy, Cookie Policy, and the terms of
any other notices or instructions provided to you under these terms, supersede
and cancel all prior agreements, statements (oral or written), as well as
arrangements and constitute the entire agreement between You and the
Administrator relating to the Services and other matters contemplated in these
Terms.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>10.2
Waiver<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>If any
party waives any provision of this Agreement, it shall not constitute a waiver
of any other breach. No waiver shall be effective unless made in writing.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>10.3
Delays<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>Neither
party shall be liable for any delay or failure to perform its obligations under
these Terms if the delay or failure arises from any cause beyond its reasonable
control. This provision does not apply to any payment obligations.<br
style='mso-special-character:line-break'>
<![if !supportLineBreakNewLine]><br style='mso-special-character:line-break'>
<![endif]><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>10.4
Assignment of Rights<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>You may
not assign or transfer any rights to any other person without the prior written
consent of the Administrator. This Agreement shall be governed by and construed
in accordance with the laws of the Republic of Kazakhstan. Any claims, legal
proceedings related to the Services shall be filed exclusively at the
Administrator's location, and you agree to the jurisdiction of such courts.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>10.5
Severability<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>If any
part or provision of this Agreement is invalid, unenforceable, or contrary to
law, that part or provision shall be replaced by a provision that, as far as
possible, achieves the original purpose of that part or provision. The
remaining part of this Agreement shall remain binding on the parties.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><b><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>10.6
Notices<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>Any notice
given in accordance with these Terms by one party to the other shall be in
writing and sent by email and shall be deemed received upon transmission.
Notices to the Administrator shall be sent to </span><a
href="mailto:dev@advtech.kz"><span lang=EN-US style='letter-spacing:.1pt;
mso-ansi-language:EN-US'>dev@advtech.kz</span></a><span style='mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin'> or to any other email address
provided by the Administrator. Notices will be sent to the email address you
provided when setting up access to the Service.<br>
<br>
<b>10.7 Rights of Third Parties</b><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>A person
who is not a party to this Agreement has no right to benefit from or enforce
any term of this Agreement.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>Limited
Liability Partnership &quot;ADV Tech&quot; Address: Republic of Kazakhstan,
Almaty city, Medeu district, Rubinstein street</span><span style='mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;mso-ansi-language:EN-US'> </span><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>48, postal
code 050020 BIN 220240029882 </span><span lang=EN-US style='mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;mso-ansi-language:EN-US'>CEA</span><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'> 62011 </span><span
lang=EN-US style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
mso-ansi-language:EN-US'>JSC</span><span style='mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin'> &quot;Bank CenterCredit&quot; BIC KCJBKZKX
KZ508562203116332339 (KZT)<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
lang=EN-US style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
mso-ansi-language:EN-US'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
lang=EN-US style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
mso-ansi-language:EN-US'>CEO</span><span lang=RU style='mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin;mso-ansi-language:RU'><o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;mso-margin-bottom-alt:
8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span style='mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin'>Kagarov A.A.<o:p></o:p></span></p>

</div>
            `,
            }}
          ></div>
        )}
      </CommonLayout>
      <div className="flex-grow-0">
        <Footer />
      </div>
    </div>
  );
};
