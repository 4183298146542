import {
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { useLocale } from "entities/i18n";
import {
  useDeleteAgencyUserMutation,
  useReadUserByEmailQuery,
  useUpdateUserMutation,
  useUpdateUserStatusMutation,
} from "entities/users";
import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { TextInput } from "shared/ui/form";
import * as Yup from "yup";
import { useLazyResetPasswordQuery } from "entities/auth";
import { useLocation } from "react-router-dom";
import { IconPower, IconTrash } from "@tabler/icons-react";
import { useTheme } from "@mui/material/styles";
import { ComplexList } from "shared/ui/list";
import { getByErrorCode } from "shared/lib/errors";

type Props = {
  handleCancel: () => void;
  userRole: "admin" | "director";
  agencyId: number;
};

export const UserProfile = (props: Props) => {
  const { errors: i18nErrors, core: i18nCore, agencyUsers: i18n } = useLocale();
  const { palette } = useTheme();

  const [updateUser, { data: dataUpdateUser }] = useUpdateUserMutation();
  const [deleteUser, { data: dataDeleteUser }] = useDeleteAgencyUserMutation();
  const [updateUserStatus, { data: dataUpdateUserStatus }] =
    useUpdateUserStatusMutation();
  const [resetPassword, { data: dataResetPassword }] =
    useLazyResetPasswordQuery();

  const location = useLocation();

  const {
    data: userData,
    isLoading: isLoadingUser,
    isError: isErrorUser,
    refetch: refetchUser,
    isUninitialized: isUninitializedUser,
  } = useReadUserByEmailQuery(
    { email: location.state ? location.state.user.email : "" },
    { skip: !location.state.user || props.userRole !== "admin" }
  );

  const initialValues: {
    name: string;
    email: string;
    role: string;
    submit: null;
  } = {
    name: userData ? userData.result.full_name : location.state.user.full_name,
    email: location ? location.state.user.email : "",
    role: userData
      ? userData.result.roles[0].code
      : location.state.user.roles[0].code,
    submit: null,
  };

  const [isEditing, setIsEditing] = useState(false);

  // is used for MUI Select
  const [role, setRole] = useState(
    userData ? userData.result.roles[0].code : location.state.user.roles[0].code
  );

  const [email, setEmail] = useState(location ? location.state.user.email : "");

  const [fullName, setFullName] = useState("");
  useEffect(() => {
    setFullName(
      userData ? userData.result.full_name : location.state.user.full_name
    );
  }, [userData]);

  const [userStatus, setUserStatus] = useState<boolean>(
    location.state.user.is_active
  );

  useEffect(() => {
    if (dataUpdateUserStatus && dataUpdateUserStatus.status_code === 0) {
      setUserStatus(!userStatus);
    }
  }, [dataUpdateUserStatus]);

  const handleDeactivate = () => {
    updateUserStatus({
      user_id: userData ? userData.result.id : location.state.user.id,
      is_active: !userStatus,
    }).finally(() => {
      setIsEditing(false);
      !isUninitializedUser && refetchUser();
    });
  };

  const handleDelete = () => {
    deleteUser({
      user_id: userData ? userData.result.id : location.state.user.id,
      agency_id: props.agencyId,
    });
    setTimeout(props.handleCancel, 1000);
  };

  const handleResetPassword = () => {
    (userData || dataUpdateUser || location.state.user) &&
      resetPassword(
        (userData && userData.result.email) ||
          (dataUpdateUser && dataUpdateUser.result.email) ||
          (location && location.state.user.email)
      );
  };

  const scriptedRef = useRef(true);

  useEffect(
    () => () => {
      scriptedRef.current = false;
    },
    []
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(i18nErrors.form.fieldRequired).max(255),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          userData
            ? updateUser({
              user_id: userData ? userData.result.id : location.state.user.id,
              full_name: fullName,
              email: email,
              phone: userData.result.phone,
            })
            : props.userRole === "director" &&
              updateUser({
                user_id: location.state.user.id,
                full_name: fullName,
                email: email,
                phone: location.state.user.phone,
              });
          if (scriptedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err: any) {
          if (scriptedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit} className="p-8">
          <div className="flex flex-row gap-12 ">
            <div
              className={
                "flex flex-col gap-8 " +
                (props.userRole === "admin" ? "flex-1" : "w-1/2")
              }
            >
              <div className={props.userRole === "admin" ? "w-1/2" : "w-full"}>
                <TextInput
                  fullWidth
                  disabled={!isEditing}
                  id="name"
                  label={i18n.fullNameTitle}
                  placeholder={i18n.fullNamePlaceHolder}
                  onChange={(e) => {
                    setFullName(e.target.value);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  value={fullName}
                />
                {touched.name && errors.name && (
                  <FormHelperText error>{errors.name}</FormHelperText>
                )}
              </div>
              <div className="flex flex-row items-end gap-4">
                <div
                  className={props.userRole === "admin" ? "w-1/2" : "w-full"}
                >
                  <TextInput
                    fullWidth
                    disabled={!isEditing}
                    id="email"
                    label={i18n.emailTitle}
                    placeholder={i18n.emailPlaceHolder}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {touched.email && errors.email && (
                    <FormHelperText error>{errors.email}</FormHelperText>
                  )}
                </div>
                {props.userRole === "admin" && (
                  <Button
                    disableElevation
                    disabled={false}
                    size="large"
                    variant="outlined"
                    className="normal-case"
                    onClick={handleResetPassword}
                  >
                    {"Сбросить пароль"}
                  </Button>
                )}
              </div>
              <div className={props.userRole === "admin" ? "w-1/2" : "w-full"}>
                <div className="font-open-sans">
                  <InputLabel
                    shrink
                    htmlFor="role"
                    className="text-lg font-bold text-black"
                  >
                    {i18n.roles.title}
                  </InputLabel>
                  <Select
                    fullWidth
                    disabled
                    id="role"
                    name="role"
                    label={i18n.roles.title}
                    onChange={(e: SelectChangeEvent) => {
                      setRole(e.target.value);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    value={userData ? userData.result.roles[0].code : role}
                    variant="filled"
                    size="small"
                    sx={{
                      background: "white !important",
                      border: "none",
                      borderRadius: "0.25rem",
                      padding: 0,
                      "& .MuiInputBase-input": {
                        paddingTop: "0.75rem",
                        paddingBottom: "0.75rem",
                      },
                      "&:after, &:before": {
                        border: "none",
                      },
                    }}
                  >
                    <MenuItem value="MANAGER">{i18n.roles.manager}</MenuItem>
                    <MenuItem value="CLIENT">{i18n.roles.client}</MenuItem>
                    {role === "AGENCY_DIRECTOR" && (
                      <MenuItem value="AGENCY_DIRECTOR">
                        {i18n.roles.director}
                      </MenuItem>
                    )}
                  </Select>
                </div>
                {errors.role && (
                  <FormHelperText error>{errors.role}</FormHelperText>
                )}
                {isSubmitting && (
                  <FormHelperText>{i18n.submitting}</FormHelperText>
                )}
              </div>
              {props.userRole !== "admin" && (
                <Button
                  disableElevation
                  disabled={false}
                  size="large"
                  variant="outlined"
                  className="normal-case w-[15rem]"
                  onClick={handleResetPassword}
                >
                  {i18n.resetPassword}
                </Button>
              )}
              <Stack direction="row" spacing="2rem" justifyContent="flex-start">
                <Button
                  disableElevation
                  type="button"
                  disabled={isSubmitting}
                  size="large"
                  variant="contained"
                  className="bg-primary text-white normal-case"
                  onClick={(e) => {
                    isEditing ? handleSubmit() : setIsEditing(true);
                  }}
                >
                  {isEditing ? i18nCore.save : i18nCore.edit}
                </Button>

                {(props.userRole === "director" &&
                  role === "AGENCY_DIRECTOR") || (
                  <>
                    <Button
                      disableElevation
                      disabled={false}
                      size="medium"
                      variant="outlined"
                      className="normal-case"
                      onClick={handleDeactivate}
                    >
                      <IconPower
                        color={palette.primary.main}
                        strokeWidth={1.4}
                        size={18}
                        className="mr-2"
                      />
                      {userStatus ? i18nCore.deactivate : i18nCore.activate}
                    </Button>

                    <Button
                      disableElevation
                      disabled={false}
                      size="medium"
                      variant="outlined"
                      className="normal-case"
                      onClick={handleDelete}
                    >
                      <IconTrash
                        color={palette.primary.main}
                        strokeWidth={1.4}
                        size={18}
                        className="mr-2"
                      />
                      {i18nCore.delete}
                    </Button>
                  </>
                )}

                <Button
                  disableElevation
                  disabled={false}
                  size="large"
                  variant="outlined"
                  className="normal-case"
                  onClick={props.handleCancel}
                >
                  {i18nCore.cancel}
                </Button>
              </Stack>
              <div>
                {dataUpdateUser && dataUpdateUser.status_code === 2002 && (
                  <div>{i18n.errors.duplicateUser}</div>
                )}
                {dataUpdateUser && dataUpdateUser.status_code === 0 && (
                  <div>{i18n.editSuccess}</div>
                )}
                {dataResetPassword && dataResetPassword.status_code === 0 && (
                  <div>{i18n.resetPasswordSuccess}</div>
                )}
                {dataResetPassword && dataResetPassword.status_code !== 0 && (
                  <div>
                    {i18nErrors[getByErrorCode(dataResetPassword.status_code)]}
                  </div>
                )}
              </div>
            </div>
            {props.userRole === "director" && role !== "AGENCY_DIRECTOR" && (
              <div className="flex-auto">
                <ComplexList
                  cols={[{ name: "client", title: i18n.userClients }]}
                  rows={(
                    location.state.user.clients as AgencyUser["clients"]
                  ).map((client) => ({ client: { content: client.name } }))}
                  className="w-full"
                />
              </div>
            )}
          </div>
        </form>
      )}
    </Formik>
  );
};
