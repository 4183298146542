export const formOptionsList = (
  defaultOption: string | null,
  options?: string[] | null,
) => {
  const result: string[] = defaultOption ? [defaultOption] : [];

  if (options && options?.length) {
    options.forEach((el) => result.push(el));
  }

  return result;
};

import { useEffect, useState } from "react";
import { URLSearchParamsInit, useSearchParams } from "react-router-dom";
import { FiltersState, initialFiltersState } from "../DashboardFilters";
import { formatDate } from "shared/lib/dates";

export const useFilterURLState = () => {
  const searchParams = useSearchParams();

  const [filters, setFilters] = useState<FiltersState>(initialFiltersState);

  // Установить параметры при загрузке страницы

  useEffect(() => {
    if (filters) {
      const params =
        (Object.keys(initialFiltersState) as (keyof FiltersState)[])
          .reduce(
            (
              acc: Record<keyof FiltersState, string | null>,
              key: keyof FiltersState,
            ) => {
              const param = searchParams[0].get(key);
              if (param && param !== "undefined") {
                acc[key] = param;
              }
              return acc;
            },
            {} as Record<keyof FiltersState, string | null>,
          );

      console.log("urlParams", params);

      setFilters(
        {
          startDate: params.startDate
            ? new Date(params.startDate.split(".").reverse().join("-"))
            : filters.startDate,
          endDate: params.endDate
            ? new Date(params.endDate.split(".").reverse().join("-"))
            : filters.endDate,
          lastDay: params.lastDay ? true : filters.lastDay,
          cabinet: params.cabinet || filters.cabinet,
          mediaplan: params.mediaplan || filters.mediaplan,
          brand: params.brand || filters.brand,
          status: params.status || filters.status,
          campaign: params.campaign || filters.campaign,
        } as FiltersState,
      );
    }
  }, []);

  // Установить параметры при смене значения фильтра

  useEffect(() => {
    if (filters) {
      const {
        startDate,
        endDate,
        lastDay,
        cabinet,
        mediaplan,
        brand,
        status,
        campaign,
      } = filters;

      const setParams = searchParams[1];

      setParams({
        startDate: startDate ? formatDate(startDate, "yyyy-MM-dd") : undefined,
        endDate: endDate ? formatDate(endDate, "yyyy-MM-dd") : undefined,
        lastDay: lastDay ? "true" : undefined,
        cabinet: cabinet || undefined,
        mediaplan: mediaplan || undefined,
        brand: brand || undefined,
        status: status || undefined,
        campaign: campaign || undefined,
      } as URLSearchParamsInit);
    }

    console.log("filtersState", filters);
  }, [filters]);

  return [filters, setFilters] as const;
};
