import { Theme } from "@mui/material/styles";
import { IconPower, IconSettings, IconTrash } from "@tabler/icons-react";
import { NavigateFunction } from "react-router-dom";
import { ComplexListProps } from "shared/ui/list";
import { IconButtonStack } from "shared/ui/components";
import { useLocale } from "entities/i18n";

type DialogAction = "delete" | "deactivate";

export const compileListData = (
  data: ResponseReadSystemAdmins,
  handleButtonClick: (index: number, action: DialogAction) => () => void,
  navigate: NavigateFunction,
  palette: Theme["palette"],
  i18n: ReturnType<typeof useLocale>["superAdmin"],
  cols: ComplexListProps["cols"]
) => {
  const rows = data.result.items && data.result.items.map((item, index) => {
    const actionsProps = {
      iconButtons: [
        {
          icon: IconSettings,
          onClick: () => {navigate(`/system-admin/${item.id}`, { state: item })},
          tooltip: i18n.edit,
        },
        {
          icon: IconPower,
          onClick: handleButtonClick(index, "deactivate"),
          tooltip: item.is_active ? i18n.deactivate : i18n.activate,
          iconProps: {
            color: palette.text.primary
          },
        },
        {
          icon: IconTrash,
          onClick: handleButtonClick(index, "delete"),
          tooltip: i18n.delete,
          iconProps: {
            color: palette.text.primary
          },
        },
      ],
      iconProps: {
        size: 20,
        strokeWidth: 1.5,
        color: item.is_active ? palette.text.primary : palette.grey[400]
      },
      className: "gap-3 justify-end",
    };
    return {
      name: {
        content: <span className={`text-${item.is_active ? 'black' : 'grey-400'}`}>{item.full_name}</span>,
      },
      email: { content: <span className={`text-${item.is_active ? 'black' : 'grey-400'}`}>{item.email}</span> },
      id: { content: <span className={`text-${item.is_active ? 'black' : 'grey-400'}`}>{item.id}</span> },
      actions: {
        content: <IconButtonStack {...actionsProps} />,
      }
    };
  });
  return { cols, rows: rows ? rows : [] } as ComplexListProps;
};