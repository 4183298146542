import { baseApi } from "shared/baseApi";
// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  ResponseToken,
  ResponseVerifyToken,
  RequestAuth,
  RequestChangePasswordWithTokenQuery,
  RequestActivateWithToken,
  RequestAdminRegister,
  ResponseEditUser,
} from "shared/types";
import {
  // PUBLIC_API_URL,
  QUERY_ACCESS_TOKEN,
  QUERY_ACTIVATE_WITH_TOKEN,
  QUERY_REFRESH_TOKEN,
  QUERY_RESET_PASSWORD,
  QUERY_VERIFY_TOKEN,
  QUERY_CHANGE_PASSWORD_WITH_TOKEN,
  QUERY_AGENCY_DIRECTOR_REGISTER,
  QUERY_ME,
} from "shared/constants";
import { getMemory } from "shared/lib/memory";

// const AuthApi = createApi({
const AuthApi = baseApi.injectEndpoints({
  // reducerPath: "authApi",
  // baseQuery: fetchBaseQuery({
  //   baseUrl: PUBLIC_API_URL,
  // }),
  //
  // refetchOnFocus: true,

  endpoints: (build) => ({
    accessToken: build.query<ResponseToken, RequestAuth>({
      query: (auth: RequestAuth) => ({
        url: QUERY_ACCESS_TOKEN,
        method: "post",
        body: { ...auth },
      }),
    }),
    activateWithToken: build.query<ResponseToken, RequestActivateWithToken>({
      query: (body: RequestActivateWithToken) => ({
        url: QUERY_ACTIVATE_WITH_TOKEN,
        method: "post",
        body,
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${body.token}`,
        },
      }),
    }),
    refreshToken: build.query<ResponseToken, string>({
      query: (refresh: string) => ({
        url: QUERY_REFRESH_TOKEN,
        method: "post",
        headers: {
          Authorization: `Bearer ${refresh}`,
        },
      }),
    }),
    resetPassword: build.query<ResponseToken, string>({
      query: (email: string) => ({
        url: QUERY_RESET_PASSWORD,
        method: "post",
        body: { email },
      }),
    }),
    verifyToken: build.query<ResponseVerifyToken, string | undefined>({
      query: (token: string | undefined) => ({
        url: QUERY_VERIFY_TOKEN,
        method: "post",
        body: { token },
      }),
    }),
    changePasswordWithToken: build.query<
      ResponseToken,
      RequestChangePasswordWithTokenQuery
    >({
      query: (body: RequestChangePasswordWithTokenQuery) => ({
        url: QUERY_CHANGE_PASSWORD_WITH_TOKEN,
        method: "post",
        body,
      }),
    }),
    agencyDirectorRegister: build.query<ResponseToken, RequestAdminRegister>({
      query: (body: RequestAdminRegister) => ({
        url: QUERY_AGENCY_DIRECTOR_REGISTER,
        method: "post",
        body,
      }),
    }),
    me: build.query<ResponseEditUser, null>({
      query: (body) => ({
        url: QUERY_ME,
        method: "GET",
        // headers: {
        //   Authorization: `Bearer ${
        //     // JSON.parse(getMemory("serviseAuth") || '{"access":""}').access
        //     JSON.parse(getMemory("serviseAuth")).access
        //   }`,
        // },
      }),
    }),
  }),
});

export const {
  useAccessTokenQuery,
  useLazyAccessTokenQuery,
  useActivateWithTokenQuery,
  useLazyActivateWithTokenQuery,
  useRefreshTokenQuery,
  useLazyRefreshTokenQuery,
  useResetPasswordQuery,
  useLazyResetPasswordQuery,
  useVerifyTokenQuery,
  useLazyVerifyTokenQuery,
  useChangePasswordWithTokenQuery,
  useLazyChangePasswordWithTokenQuery,
  useAgencyDirectorRegisterQuery,
  useLazyAgencyDirectorRegisterQuery,
  useMeQuery,
} = AuthApi;

export default AuthApi;
