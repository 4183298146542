import { Picker } from "shared/ui/components";
import { Locale } from "shared/assets/i18n";
import { currencies, defaultFallbackCurrency } from "shared/constants";

export const getColumnsByI18n = (
  i18n: Locale,
  userRole: "admin" | "director",
  setCurrency: (currency: Currency) => void
) => {
  const initialColumns = [
    {
      name: "id",
      title: "",
      specialCellStyles: "border-0 text-[0.8rem] pr-1",
    },
    {
      name: "cabinet",
      title: i18n.dashboard.cabinet,
    },
    {
      name: "brand",
      title: i18n.dashboard.brand,
    },
    {
      name: "mediaplan",
      title: i18n.dashboard.mediaplan,
    },
    //mediaplan column
    // {
    //   name: "mediaplanId",
    //   title: i18n.dashboard.mediaplanId,
    // },
    //campaign column
    {
      name: "campaignId",
      title: i18n.dashboard.campaignId,
    },
    {
      name: "campaign",
      title: i18n.dashboard.campaign,
    },
    //status column
    {
      name: "status",
      title: i18n.dashboard.status,
    },
    //dates column
    {
      name: "date",
      title: i18n.dashboard.date,
    },
    //days passed column
    {
      name: "daysPassed",
      title: i18n.dashboard.daysPassed,
    },
    //purchasing units column
    {
      name: "purchaseUnit",
      title: i18n.dashboard.purchaseUnit,
    },
    //currency column
    {
      name: "currency",
      title: (
        <Picker
          options={currencies}
          defaultOption={currencies.indexOf(defaultFallbackCurrency)}
          handleChange={(currency) => setCurrency(currency as Currency)}
        />
      ),
    },
    //key metric column
    {
      name: "keyMetric",
      title: i18n.dashboard.keyMetric,
    },
    //kpi (cpc) plan column
    {
      name: "kpiPlan",
      title: i18n.dashboard.plan,
      group: "kpi",
    },
    //kpi (cpc) fact column
    {
      name: "kpiFact",
      title: i18n.dashboard.fact,
      group: "kpi",
    },
    //kpi (cpc) completion column
    {
      name: "kpiCompletion",
      title: i18n.dashboard.completion,
      group: "kpi",
    },
    //budget plan column
    {
      name: "budgetPlan",
      title: i18n.dashboard.plan,
      group: "budget",
    },
    //budget fact column
    {
      name: "budgetFact",
      title: i18n.dashboard.fact,
      group: "budget",
    },
    //budget result column
    {
      name: "budgetResult",
      title: i18n.dashboard.result,
      group: "budget",
    },
    //budget recommended daily column
    {
      name: "budgetRecommendedDaily",
      title: i18n.dashboard.recommendedDaily,
      group: "budget",
    },
    {
      name: "impressionsPlan",
      title: i18n.dashboard.plan,
      group: "impressions",
    },
    {
      name: "impressionsFact",
      title: i18n.dashboard.fact,
      group: "impressions",
    },
    {
      name: "impressionsCompletion",
      title: i18n.dashboard.completion,
      group: "impressions",
    },
    {
      name: "cpmPlan",
      title: i18n.dashboard.plan,
      group: "cpm",
    },
    {
      name: "cpmFact",
      title: i18n.dashboard.fact,
      group: "cpm",
    },
    {
      name: "cpmCompletion",
      title: i18n.dashboard.completion,
      group: "cpm",
    },
    {
      name: "clicksPlan",
      title: i18n.dashboard.plan,
      group: "clicks",
    },
    {
      name: "clicksFact",
      title: i18n.dashboard.fact,
      group: "clicks",
    },
    {
      name: "clicksCompletion",
      title: i18n.dashboard.completion,
      group: "clicks",
    },
    // same for cpc, ctr, frequency and reach
    {
      name: "cpcPlan",
      title: i18n.dashboard.plan,
      group: "cpc",
    },
    {
      name: "cpcFact",
      title: i18n.dashboard.fact,
      group: "cpc",
    },
    {
      name: "cpcCompletion",
      title: i18n.dashboard.completion,
      group: "cpc",
    },
    {
      name: "ctrPlan",
      title: i18n.dashboard.plan,
      group: "ctr",
    },
    {
      name: "ctrFact",
      title: i18n.dashboard.fact,
      group: "ctr",
    },
    {
      name: "ctrCompletion",
      title: i18n.dashboard.completion,
      group: "ctr",
    },
    {
      name: "frequencyPlan",
      title: i18n.dashboard.plan,
      group: "frequency",
    },
    {
      name: "frequencyFact",
      title: i18n.dashboard.fact,
      group: "frequency",
    },
    {
      name: "frequencyCompletion",
      title: i18n.dashboard.completion,
      group: "frequency",
    },
    {
      name: "reachPlan",
      title: i18n.dashboard.plan,
      group: "reach",
    },
    {
      name: "reachFact",
      title: i18n.dashboard.fact,
      group: "reach",
    },
    {
      name: "reachCompletion",
      title: i18n.dashboard.completion,
      group: "reach",
    },
    // same for cpu, views, cpv and vtr
    {
      name: "cpuPlan",
      title: i18n.dashboard.plan,
      group: "cpu",
    },
    {
      name: "cpuFact",
      title: i18n.dashboard.fact,
      group: "cpu",
    },
    {
      name: "cpuCompletion",
      title: i18n.dashboard.completion,
      group: "cpu",
    },
    {
      name: "viewsPlan",
      title: i18n.dashboard.plan,
      group: "views",
    },
    {
      name: "viewsFact",
      title: i18n.dashboard.fact,
      group: "views",
    },
    {
      name: "viewsCompletion",
      title: i18n.dashboard.completion,
      group: "views",
    },
    {
      name: "cpvPlan",
      title: i18n.dashboard.plan,
      group: "cpv",
    },
    {
      name: "cpvFact",
      title: i18n.dashboard.fact,
      group: "cpv",
    },
    {
      name: "cpvCompletion",
      title: i18n.dashboard.completion,
      group: "cpv",
    },
    {
      name: "vtrPlan",
      title: i18n.dashboard.plan,
      group: "vtr",
    },
    {
      name: "vtrFact",
      title: i18n.dashboard.fact,
      group: "vtr",
    },
    {
      name: "vtrCompletion",
      title: i18n.dashboard.completion,
      group: "vtr",
    },
    {
      name: "crPlan",
      title: i18n.dashboard.plan,
      group: "cr",
    },
    {
      name: "crFact",
      title: i18n.dashboard.fact,
      group: "cr",
    },
    {
      name: "crCompletion",
      title: i18n.dashboard.completion,
      group: "cr",
    },
    {
      name: "conversionsPlan",
      title: i18n.dashboard.plan,
      group: "conversions",
    },
    {
      name: "conversionsFact",
      title: i18n.dashboard.fact,
      group: "conversions",
    },
    {
      name: "conversionsCompletion",
      title: i18n.dashboard.completion,
      group: "conversions",
    },
    {
      name: "cpaPlan",
      title: i18n.dashboard.plan,
      group: "cpa",
    },
    {
      name: "cpaFact",
      title: i18n.dashboard.fact,
      group: "cpa",
    },
    {
      name: "cpaCompletion",
      title: i18n.dashboard.completion,
      group: "cpa",
    },
    {
      name: "installsPlan",
      title: i18n.dashboard.plan,
      group: "installs",
    },
    {
      name: "installsFact",
      title: i18n.dashboard.fact,
      group: "installs",
    },
    {
      name: "installsCompletion",
      title: i18n.dashboard.completion,
      group: "installs",
    },
    // ! CPI not in the new schema
    // {
    //   name: "cpiPlan",
    //   title: i18n.dashboard.plan,
    //   group: "cpi",
    // },
    // {
    //   name: "cpiFact",
    //   title: i18n.dashboard.fact,
    //   group: "cpi",
    // },
    //edit column
  ];
  if (userRole === "director") {
    initialColumns.push({
      name: "edit",
      title: "",
      specialCellStyles: "border-0",
    });
  }
  return initialColumns as {
    name: string;
    title: string;
    group?: string;
    specialCellStyles?: string;
  }[];
};

export const getGroupTitlesByI18n = (i18n: Locale) => ({
  kpi: i18n.dashboard.kpi,
  budget: i18n.dashboard.budget,
  impressions: i18n.dashboard.impressions,
  cpm: i18n.dashboard.cpm,
  clicks: i18n.dashboard.clicks,
  cpc: i18n.dashboard.cpc,
  ctr: i18n.dashboard.ctr,
  frequency: i18n.dashboard.frequency,
  reach: i18n.dashboard.reach,
  cpu: i18n.dashboard.cpu,
  views: i18n.dashboard.views,
  cpv: i18n.dashboard.cpv,
  vtr: i18n.dashboard.vtr,
  cr: i18n.dashboard.cr,
  conversions: i18n.dashboard.conversions,
  cpa: i18n.dashboard.cpa,
  installs: i18n.dashboard.installs,
});

export type ColumnName = ArrayElement<
  ReturnType<typeof getColumnsByI18n>
>["name"];

export const getColumnsByI18nForCampTable = (i18n: Locale) => [
  {
    name: "id",
    title: "",
    specialCellStyles: "border-0 text-[0.8rem] pr-1",
  },
  {
    name: "cabinet",
    title: i18n.dashboard.cabinet,
  },
  {
    name: "brand",
    title: i18n.dashboard.brand,
  },
  {
    name: "ad_group",
    title: i18n.dashboard.adGroup,
  },
  {
    name: "ad",
    title: i18n.dashboard.ad,
  },
  // {
  //   name: "campaignId",
  //   title: i18n.dashboard.campaignId,
  // },
  // {
  //   name: "campaign",
  //   title: i18n.dashboard.campaign,
  // },
  //status column
  {
    name: "status",
    title: i18n.dashboard.status,
  },
  {
    name: "geo",
    title: i18n.dashboard.geo,
  },
  {
    name: "socDem",
    title: i18n.dashboard.socDem,
  },
  //dates column
  {
    name: "date",
    title: i18n.dashboard.date,
  },
  //days passed column
  {
    name: "daysPassed",
    title: i18n.dashboard.daysPassed,
  },
  //purchasing units column
  {
    name: "purchaseUnit",
    title: i18n.dashboard.purchaseUnit,
  },
  //currency column
  {
    name: "currency",
    title: i18n.dashboard.currency,
  },
  {
    name: "cpa",
    title: i18n.dashboard.cpa,
  },
  // cpc, cpm, cpu, ctr, cpv, vtr
  {
    name: "cpc",
    title: i18n.dashboard.cpc,
  },
  {
    name: "cpm",
    title: i18n.dashboard.cpm,
  },
  {
    name: "cpu",
    title: i18n.dashboard.cpu,
  },
  {
    name: "ctr",
    title: i18n.dashboard.ctr,
  },
  {
    name: "cpv",
    title: i18n.dashboard.cpv,
  },
  {
    name: "vtr",
    title: i18n.dashboard.vtr,
  },
  // impressions, clicks, conversions, installs, frequency, reach
  {
    name: "impressions",
    title: i18n.dashboard.impressions,
  },
  {
    name: "clicks",
    title: i18n.dashboard.clicks,
  },
  {
    name: "conversions",
    title: i18n.dashboard.conversions,
  },
  {
    name: "installs",
    title: i18n.dashboard.installs,
  },
  {
    name: "frequency",
    title: i18n.dashboard.frequency,
  },
  {
    name: "reach",
    title: i18n.dashboard.reach,
  },
  //edit column
  {
    name: "edit",
    title: "",
    specialCellStyles: "border-0",
  },
];
