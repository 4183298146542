import { useMemo } from "react";

import {
  ResponseReadExchangeRates,
  useReadExchangeRatesQuery,
} from "entities/exchange";
import { useAgency } from "entities/admin";
import { adsSystems } from "shared/constants";

const exchangeRatesResponseTransformer = (res: ResponseReadExchangeRates) => {
  if (res.status_code !== 0 || !res.result) return null;
  const { result } = res;
  const transformed_result = result.reduce((acc, curr) => {
    const { api_type, value_rub, value_usd } = curr;
    acc[api_type === "FACEBOOK" ? "META" : api_type] = {
      KZT: 1,
      RUB: value_rub,
      USD: value_usd,
    };
    return acc;
  }, {} as Record<adsSystems, Record<Currency, number>>);
  return transformed_result;
};

/**
 * @description Хук для получения курсов валют в формате {adsSystems: {Сurrency: value}}
 * Используется в таблице план/факт
 */

export const useExchangeRates = () => {
  const { agencyId } = useAgency();
  const { data, error, isLoading } = useReadExchangeRatesQuery({
    agency_id: agencyId!,
  }, { skip: !agencyId });

  const transformedData = useMemo(
    () => data ? exchangeRatesResponseTransformer(data!) : null,
    [data],
  );

  return { rates: transformedData, error, isLoading };
};
