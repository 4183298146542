import { useTheme } from "@mui/material/styles";
import {
  IconEdit,
  IconPower,
  IconSearch,
  IconTrash,
} from "@tabler/icons-react";
import { useLocale } from "entities/i18n";
import { ComponentProps, useEffect, useMemo, useState } from "react";
import { CabinetBadge } from "shared/ui/icons";
import { IconButtonStack, Picker } from "shared/ui/components";
import { ADS_SYSTEMS_READABLE, ROUTES, adsSystems } from "shared/constants";
import { TextInput } from "shared/ui/form";
import { Button } from "@mui/material";
import { ComplexListProps, PaginatedComplexList } from "shared/ui/list";
import { useReadConnectorsByAgencyQuery } from "entities/connectors";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BtnsManageApi } from "widgets/clientsApis/BtnsManageApi";

export const ClientApiList = (props: Required<ClientWidgetProps> & {clientName: string}) => {
  const { admin: i18n, core: i18nCore } = useLocale();
  const { palette } = useTheme();

  const [clientsWithConnectors, setclientsWithConnectors] = useState<
  ClientWithConnectors[]
>([]);

  const navigate = useNavigate();
  const handleAddApi = () => navigate(`${ROUTES.createApi}/${props.agencyId}`);

  const actionsProps: () => ComponentProps<typeof IconButtonStack> = () => ({
    iconButtons: [
      {
        icon: IconEdit,
        onClick: () => alert("click"),
        tooltip: "Редактировать API",
      },
      {
        icon: IconPower,
        onClick: () => alert("click"),
        tooltip: "Редактировать API",
      },
      {
        icon: IconTrash,
        onClick: () => alert("click"),
        tooltip: "Редактировать API",
      },
    ],
    iconProps: {
      size: 20,
      strokeWidth: 1.5,
      color: "black",
    },
    className: "gap-3 justify-end",
  });

  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const paramsClientName = useMemo(
    () => searchParams.get("client_name"),
    [searchParams]
  );

  const [nameFilter, setNameFilter] = useState<string | null>(paramsClientName || props.clientName || null);
  const [adsSystemFilter, setAdsSystemFilter] = useState<string | null>(null);
  const [cabinetNameFilter, setCabinetNameFilter] = useState<string | null>(
    null
  );

  const { isLoading, data, isUninitialized, refetch: rtkRefetch } = useReadConnectorsByAgencyQuery(props.agencyId);

  const refetch = () => {
    !isUninitialized && rtkRefetch();
  };

  useEffect(() => {
    if (isLoading) return;

    const arr: ClientWithConnectors[] = [];

    data && data.result && data.result.forEach((el) => {
      const { name, id, is_active, bin, connectors } = el;

      arr.push({
        name,
        id,
        connectors,
        is_active,
        bin,
      });
    });

    setclientsWithConnectors(arr);
  }, [isLoading, data]);

  const rows: ComplexListProps["rows"] = useMemo(() => {
    const clientsHaveConnectors = clientsWithConnectors.filter(
      (e) => e.connectors
    );
    const clientsFiltered = clientsHaveConnectors.filter((e) =>
      e.name.toLowerCase().includes(nameFilter?.toLowerCase() || "")
    );
    const result: ComplexListProps["rows"] = [];
    clientsFiltered.forEach((client) => {
      const { name, id, connectors } = client;
      let nameAdded = false;
      const connectorsFilteredByName = connectors.filter((e) =>
        e.name.toLowerCase().includes(cabinetNameFilter?.toLowerCase() || "")
      );
      const connectorsFilteredByAdsSystem = connectorsFilteredByName.filter(
        (e) =>
          ADS_SYSTEMS_READABLE[e.type] === adsSystemFilter ||
          adsSystemFilter === null
      );
      connectorsFilteredByAdsSystem.forEach((connector) => {
        if (result.length % 10 === 0) nameAdded = false;
        const {
          type,
          id: connectorId,
          name: connectorName,
          is_active,
        } = connector;
        result.push({
          clientName: {
            cellSx: { borderTop: nameAdded ? "none" : "1px solid #E5E5E5", borderBottom: "none" },
            content: nameAdded ? (
              <></>
            ) : (
              <Link
                className="font-bold text-grey-600 no-underline hover:underline"
                to={`/admin/dashboard/${props.agencyId}/${id}`}
              >
                {(() => {
                  nameAdded = true;
                  return name;
                })()}
              </Link>
            ),
          },
          cabinet: {
            content: type,
          },
          api: {
            content: connectorName,
          },
          btns: {
            content: (
              <BtnsManageApi
                isActive={is_active}
                apiId={connectorId}
                apiName={connectorName}
                refetch={refetch}
              />
            ),
          },
        });
      });
    });
    return result;
  }, [clientsWithConnectors, nameFilter, adsSystemFilter, cabinetNameFilter]);

  const dataForList = useMemo(() => ({
    cols: [
      { name: "clientName", title: "Клиент", specialColStyles: "w-[17rem]" },
      { name: "cabinet", title: "Рекламный кабинет" },
      { name: "api", title: "API" },
      { name: "btns", title: "" },
    ],
    rows
  }), [rows]);

  return (
    <>
      <div className="flex flex-row justify-between items-end h-15 mt-5 mb-3">
        <div className="flex flex-row gap-[2rem] items-end">
          <TextInput
            id="search"
            placeholder={i18n.clientName}
            icon={<IconSearch size={16} color={palette.grey[400]} />}
            className="w-[15rem]"
            // onChange={(e) => {
            //   setNameFilter(e.target.value);
            // }}
            value={props.clientName}
          />
          <div className="flex flex-row items-end h-15">
            <div className="flex flex-col gap-2 h-15">
              <label
                htmlFor="system"
                className="text-[0.7rem] font-bold text-black ml-4"
              >
                {i18n.cabinet}
              </label>
              <Picker
                options={["Все", ...Object.values(ADS_SYSTEMS_READABLE)]}
                defaultOption={0}
                handleChange={setAdsSystemFilter}
                colors={{
                  selected: palette.grey[500],
                  options: palette.text.primary,
                }}
              />
            </div>
            <TextInput
              id="search"
              placeholder={i18n.clients.cabinetName}
              icon={<IconSearch size={16} color={palette.grey[400]} />}
              className="w-[15rem]"
              onChange={(e) => {
                setCabinetNameFilter(e.target.value);
                // handleRefetch();
              }}
            />
          </div>
        </div>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className="bg-primary text-white normal-case h-10"
          onClick={handleAddApi}
        >
          {i18nCore.add}
        </Button>
      </div>
      <PaginatedComplexList.client data={dataForList} rowsPerPage={10} />
    </>
  );
};
