import { SuspenseProps } from "react";
import { Box, Card, CardContent } from '@mui/material';

// project import
// import MainCard from 'components/MainCard';

// ==============================|| AUTHENTICATION - CARD WRAPPER ||============================== //

export default ({ children }: SuspenseProps) => {
  return (
    <Card
      sx={{
        minHeight: { xs: 400, lg: 420},
        maxWidth: { xs: 500, lg: 600 },
        width: "100vh",
        margin: { xs: 2.5, md: 3 }, 
        '& > *': {
          flexGrow: 1,
          flexBasis: '50%'
        },
        borderRadius: "24px",
        boxShadow: "0px 1px 20px rgba(0,0,0,0.1)"
      }}
    >
      <CardContent>
        <Box sx={{ p: { xs: 2, sm: 3, md: 4, xl: 5 } }}>{children}</Box>
      </CardContent>
    </Card>
  );
};