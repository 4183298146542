const displayErrorCodes = {
  0: "success",
  1001: "unexpected",
  1002: "unexpected",
  1003: "tooMuchData",
  1004: "formatNotSupported",
  1005: "externalApi",
  1006: "externalApi",
  2001: "server",
  2002: "server",
  2003: "notFound",
  2004: "server",
  2005: "userNotActive",
  2006: "invalidCredentials",
  2007: "invalidToken",
  2008: "unexpected",
  2009: "notEnoughPrivileges",
  2010: "passwordNotMatching",
  2011: "tooEasyPassword",
  2012: "passwordTooSimilar",
  2013: "waitForToken",
  2014: "userAlreadyExists",
  2015: "invalidToken",
  2016: "userAlreadyActivated",
  2017: "agencyAlreadyExists",
  2018: "unexpected",
  9999: "unknown",
} as const;

//type of all values of displayErrorCodes
export type ErrorCode = keyof typeof displayErrorCodes;

//type of all strings in displayErrorCodes
const displayErrorCodesValues = Object.values(displayErrorCodes);
export type DisplayErrorCode = typeof displayErrorCodesValues[number]; 

export const getByErrorCode = (code: number) => {
  return ( displayErrorCodes[code as ErrorCode] || "unknown" ) as DisplayErrorCode;
};
