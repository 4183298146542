import { createContext, SuspenseProps, useEffect } from "react";
import {
  useAuthAction,
  useRefreshTokenQuery,
  useSession,
  verifyToken,
} from "entities/auth";
import { useClearAgencyUsersCache } from "entities/users";
import { useClearClientsCache } from "entities/clients";
import { ResponseToken } from "shared/types";

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }: SuspenseProps) => {
  const { init, loggein, logout } = useAuthAction();
  // const clearAgencyUsersCache = useClearAgencyUsersCache();
  const clearClientsCache = useClearClientsCache();
  const [session, setSession] = useSession();

  const { isError, data } = useRefreshTokenQuery(session.refresh, {
    pollingInterval: 1000 * 60 * 10,
    // skip: verifyToken(session.access),
  });
  useEffect(() => {
    init();
    if (isError) {
      setSession({} as ResponseToken);
      return;
    }
    if (data) {
      setSession({
        ...session,
        access: data.result.access,
        refresh: data.result.refresh,
      });
      return;
    }
    if (!verifyToken(session.refresh)) {
      // clearAgencyUsersCache();
      clearClientsCache();
      setSession({} as ResponseToken);
      logout();
      return;
    }
    if (verifyToken(session.access)) {
      loggein(session);
    }
  }, [data?.result?.access, session.access]);
  return <JWTContext.Provider value={null}>{children}</JWTContext.Provider>;
};

export default JWTContext;
