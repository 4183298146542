import {
  IconAd2,
  IconArrowLeft,
  IconInfoCircle,
  IconStar,
} from "@tabler/icons-react";
import { useLocale } from "entities/i18n";
import { useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TabBox } from "shared/ui/tabs";
import { CommonLayout } from "shared/ui/layout";
import { IconButton as MuiIconButton } from "@mui/material";
import { backIconProps, tabIconProps } from "shared/constants";
import { ClientBrandsList, ClientInfo, ClientApiList } from "widgets/clients";
import { useAgency } from "entities/admin";

const TABS = { info: 0, api: 1, brands: 2 } as const;

/**
 * Component for the entire client page.
 *
 * Expects location.state to contain instance of Client
 * (at location.state.client).
 * Expects clientId as URL param if user is director.
 * Expects agencyId and clientId as URL params if user is admin.
 *
 * @param props.activeTab - active tab in the client page
 * @param props.userRole - user role in the client page
 */

export const Client = (props: {
  activeTab: "info" | "api" | "brands";
  userRole: "director" | "admin";
}) => {
  const i18n = useLocale().admin;

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { agencyId } = useAgency();
  // const agencyId = Number(params.agencyId);
  const clientId = Number(params.clientId);

  const [activeTab, setActiveTab] = useState<number>(TABS[props.activeTab]);

  const contentForTabs: ContentTab[] = useMemo(() => [
    {
      icon: <IconInfoCircle {...tabIconProps} />,
      title: i18n.clients.infoTab,
      content: (
        <ClientInfo
          clientId={clientId}
          userRole={props.userRole}
          client={location.state.client}
          handleCancel={() => navigate(-1)}
        />
      ),
    },
    {
      icon: <IconAd2 {...tabIconProps} />,
      title: i18n.clients.adCabinetsTab,
      content: (
        agencyId && <ClientApiList
          agencyId={agencyId}
          clientId={clientId}
          userRole={props.userRole}
          clientName={location.state.client.name}
        />
      ),
    },
    {
      icon: <IconStar {...tabIconProps} />,
      title: i18n.clients.brandsTab,
      content: (
        <ClientBrandsList
          clientId={clientId}
          userRole={props.userRole}
        />
      ),
    },
  ], [agencyId, clientId, location.state.client.name, props.userRole]);

  const handleCancel = () => {
    if (props.userRole === "director") {
      navigate("/clients");
    } else if (props.userRole === "admin") {
      navigate(`/admin/clients/${agencyId}`);
    }
  };

  return (
    <CommonLayout
      title={
        <div className="inline-flex flex-row items-center">
          <MuiIconButton
            aria-label="go back"
            edge="start"
            onClick={handleCancel}
          >
            <IconArrowLeft {...backIconProps} />
          </MuiIconButton>
          <h1>{location.state.client.name}</h1>
        </div>
      }
    >
      <TabBox
        contentTabs={contentForTabs}
        activeTab={activeTab}
        onChange={setActiveTab}
      />{" "}
    </CommonLayout>
  );
};

export default Client;