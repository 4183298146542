import { useEffect, useRef, useState } from "react";
import { useVerifyTokenQuery, useLazyChangePasswordWithTokenQuery } from "entities/auth";

import { Link as RouterLink, Params, useParams } from "react-router-dom";

// material-ui
import {
  Button,
  FormHelperText,
  Grid,
  Link,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";

import { AnswerMessage, InputAdornment } from "./ui";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

// project import
import { AnimateButton } from "shared/ui";

interface ParamProps extends Params {
  token: string | undefined
}


const i18n = {
  auth: "Вход",
  password: "Пароль",
  resetPassword: "Востановление пароля",
  savePassword: "Сохранить пароль",
  resetPasswordStartText: "Cсылка для восстановления пароля устарела. Пройдите",
  resetPasswordNextText: "или обатитесь в",
  linkResetPasswordRegister: "регистрацию",
  linkResetPasswordSupport: "службу поддержки",
  resetPasswordSuccess: "Пароль успешно сохранен. Для продолжения требуется авторизация",
  passwordEmpty: "Пароль не может быть пустым",
  passwordConfirm: "Пожалуйста повторите свой пароль",
  wronkConfirm: "Пароли не совпадают",
};

export default () => {
  const scriptedRef = useRef(true);
  const { token } = useParams<ParamProps>();
  const [verify, setVerify] = useState(false)
  const { isLoading, data } = useVerifyTokenQuery(token, { skip: verify });
  const [changePassword, res] = useLazyChangePasswordWithTokenQuery();
  
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  useEffect(
    () => () => {
      scriptedRef.current = false;
    },
    [],
  );

  useEffect(() => {
    if (data?.result) {
      setVerify(data.result.valid);
    }
  }, [data]);

  if (isLoading) {
    return null;
  }

  if (!verify) {
    return <AnswerMessage
      title={i18n.resetPassword}
      linkText={i18n.auth}
      linkTo="/auth"
    >
      {i18n.resetPasswordStartText}
      <Link
        component={RouterLink}
        to="/auth"
        color="text.primary"
        className="no-underline"
      >
        &nbsp;{i18n.linkResetPasswordRegister}&nbsp;
      </Link>
      {i18n.resetPasswordNextText}
      <Link
        component={RouterLink}
        to="/auth"
        color="text.primary"
        className="no-underline"
      >
        &nbsp;{i18n.linkResetPasswordSupport}&nbsp;
      </Link>
    </AnswerMessage>;
  }

  if (res?.data?.status) {
    return <AnswerMessage 
      title={i18n.resetPassword}
      linkText={i18n.auth}
      linkTo="/auth"
    >
      {i18n.resetPasswordSuccess};
    </AnswerMessage>;
  }

  const stackProps: any = { 
    direction: "row",
    justifyContent: "center",
    alignItems: "center",
    spacing: 2,
    className: "font-inter",
  }

  const inputAdornment = <InputAdornment
    onClick={handleClickShowPassword}
    toogle={showPassword}
  />;

  return (
    <Formik
      initialValues={{
        token,
        new_password: "",
        new_password_confirm: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        new_password: Yup.string()
          .required(i18n.passwordEmpty),
        new_password_confirm: Yup.string()
          .required(i18n.passwordConfirm)
          .oneOf([Yup.ref('new_password')], i18n.wronkConfirm)
      })}
      onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
        try {
          changePassword(values);
          if (scriptedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err: any) {
          if (scriptedRef.current) {
            setStatus({ success: false });
            setErrors({ token: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3} className="font-open-sans">
            <Grid item xs={12}>
              <Stack {...stackProps}>
                <Typography
                  variant="subtitle2"
                  color="dark"
                  component="span"
                  justifyContent="center"
                  alignItems="center"
                  className="font-inter"
                  sx={{
                    fontWeight: 700,
                    fontSize: "36px",
                  }}
                >
                  {i18n.resetPassword}
                </Typography>
              </Stack>
            </Grid> 
            <Grid item xs={12}>
              <Stack spacing={0}>
                <OutlinedInput
                  fullWidth
                  error={Boolean(touched.new_password && errors.new_password)}
                  id="-new-password"
                  type={showPassword ? "text" : "password"}
                  value={values.new_password}
                  name="new_password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  startAdornment={inputAdornment}
                  placeholder={i18n.password}
                />
                {touched.new_password && errors.new_password && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-new-password"
                  >
                    {errors.new_password}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            
            <Grid item xs={12}>
              <Stack spacing={1}>
                <OutlinedInput
                  fullWidth
                  error={Boolean(touched.new_password_confirm && errors.new_password_confirm)}
                  id="-new_password_confirm"
                  type={showPassword ? "text" : "password"}
                  value={values.new_password_confirm}
                  name="new_password_confirm"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  startAdornment={inputAdornment}
                  placeholder={i18n.password}
                />
                {touched.new_password_confirm && errors.new_password_confirm && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-new-password-confirm"
                  >
                    {errors.new_password_confirm}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>

            {errors.submit && (
              <Grid item xs={12}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Grid>
            )}
            {res?.data?.status_code && res.data.status_code > 0 && (
              <Grid item xs={12}>
                <FormHelperText error>{res.data.message}</FormHelperText>
              </Grid>
            )}

            <Grid item xs={12}>
              <AnimateButton
                type="scale"
                direction="right"
                offset={0}
                scale={{
                  hover: 1.05,
                  tap: 0.95,
                }}
              >
                <Button
                  disableElevation
                  disabled={isLoading}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  className="bg-primary color-white normal-case"
                >
                  {i18n.savePassword}
                </Button>
              </AnimateButton>
            </Grid>
            <Grid item xs={12} sx={{ mt: -1 }}>
              <Stack {...stackProps}>
                <Link
                  component={RouterLink}
                  to="/auth"
                  color="text.primary"
                  className="no-underline"
                >
                  {i18n.auth}
                </Link>
              </Stack>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};