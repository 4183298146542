import { statsData, IStatsData } from "shared/models/mocks";
import { useState } from "react";

export const useDashboardTable = (): [
    data: IStatsData[] | undefined, 
    isLoading: boolean, 
    error: boolean
 ] => {

  //const [data, setData] = useState<ComplexTableProps>();
  //get a list of columns. that's just an array of all unique keys in the objects

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<IStatsData[]>();

  const promise = statsData;

  promise.then((data) => {
    setData(data.default as IStatsData[]);
    setIsLoading(false);
  }).catch((err) => {
    setError(true);
    console.error(err);
    setIsLoading(false);
  });

  return [data, isLoading, error];
};
