import { Picker, ShadowCard } from "shared/ui/components";
import { Speedometer } from "shared/ui/charts";
import { ChartCard } from "widgets/dashboard";
import { DashboardContent } from "entities/data";

import { useLocale } from "entities/i18n";
 
type DashboardChartsProps = {
    content?: DashboardContent
    isLoading: boolean
    error: boolean
}

const DashboardCharts = ({content, isLoading, error}: DashboardChartsProps) => {

  const i18n = useLocale();

  return (
    <div className="flex flex-col gap-8">

      {/* Фильтры */}
      <ShadowCard className="p-6 h-24">Фильтры здесь</ShadowCard>

      <div className="flex flex-row h-[225px] gap-4">
        {/* Саммари по медиаплану */}
        <ShadowCard className="p-6 w-[calc(100%-26rem)] flex flex-col gap-2">
          <div className="font-bold">
            {content?.mediaplan}
          </div>
          <div className="flex flex-row flex-1 gap-4 justify-between">
            <div className="flex flex-col justify-evenly gap-2">
              <div>
                <div className="text-sm text-gray-400">
                  {i18n.dashboard.status}:
                </div>
                <div className="text-sm font-bold ">
                  {content?.status}
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-400">
                  {i18n.dashboard.brand}:
                </div>
                <div className="text-sm font-bold">
                  {content?.brand}
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-evenly gap-2">
              <div>
                <div className="text-sm text-gray-400">
                  {i18n.dashboard.date}:
                </div>
                <div className="text-sm font-bold">
                  {content?.dates}
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-400">
                  {i18n.dashboard.daysPassed}:
                </div>
                <div className="text-sm font-bold">
                  {content?.daysPassed}
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-evenly gap-2">
              <div>
                <div className="text-sm text-gray-400">
                  {i18n.dashboard.budget}:
                </div>
                <div className="text-sm font-bold">
                  {content?.budget}
                </div>
              </div>
              <div>
                <div className="text-sm text-gray-400">
                  {i18n.dashboard.cabinets}:
                </div>
                <div className="text-sm font-bold">
                  {content?.cabinets}
                </div>
              </div>
            </div>
          </div>
        </ShadowCard>

        {/* Спидометр */}
        <ShadowCard className="p-6 w-[25rem]">
          <Speedometer
            kpi={75}
            title="KPI"
            picker={<Picker options={["CPC"]} handleChange={() => {}} />}
          />
        </ShadowCard>
      </div>

      {/* Строчка со столбчатыми графиками по KPI (план/факт) */}
      <div className="flex flex-row gap-4 h-[23rem]">
        {isLoading && <div>Загрузка...</div>}
        {error && <div className="text-red">Ошибка</div>}
        {!isLoading && !content && <div>Нет данных по этому клиенту</div>}
        {content && (
          <>
            {content.chartMetrics.budget && <ChartCard
              title="Бюджет"
              chartType="bar"
              series={content?.chartMetrics.budget.series}
              xAxis={content.chartMetrics.budget.xAxis}
            />}
            {content.chartMetrics.ctr && <ChartCard
              title="CTR %"
              chartType="bar"
              series={content?.chartMetrics.ctr.series}
              xAxis={content.chartMetrics.ctr.xAxis}
            />}
            {content.chartMetrics.vtr && <ChartCard
              title="VTR %"
              chartType="bar"
              series={content?.chartMetrics.vtr.series}
              xAxis={content.chartMetrics.vtr.xAxis}
            />}
            {content.chartMetrics.cr && <ChartCard
              title="CR %"
              chartType="bar"
              series={content?.chartMetrics.cr.series}
              xAxis={content.chartMetrics.cr.xAxis}
            />}
          </>
        )}
      </div>

      {/* Строчка с комбинированными графиками (метрики) */}
      <div className="overflow-x-auto overflow-y-hidden whitespace-nowrap box-border h-[25rem] pb-8">
        {isLoading && <div>Загрузка...</div>}
        {error && <div className="text-red">Ошибка</div>}
        {!isLoading && !content && <div>Нет данных по этому клиенту</div>}
        {content && (
          <>
            {content.chartMetrics.impressionsCpm && <ChartCard
              title="Показы/ CPM"
              chartType="bar-line"
              series={
                content.chartMetrics.impressionsCpm.series
              }
              xAxis={
                content.chartMetrics.impressionsCpm.xAxis
              }
              className="inline-block h-full mr-4"
            />}
            {content.chartMetrics.clicksCpc && <ChartCard
              title="Клики/ CPC"
              chartType="bar-line"
              series={
                content.chartMetrics.clicksCpc.series
              }
              xAxis={content.chartMetrics.clicksCpc.xAxis}
              className="inline-block h-full mr-4"
            />}
            {content.chartMetrics.viewsCpv && <ChartCard
              title="Просмотры/ CPV"
              chartType="bar-line"
              series={
                content.chartMetrics.viewsCpv.series
              }
              xAxis={content.chartMetrics.viewsCpv.xAxis}
              className="inline-block h-full mr-4"
            />}
            {content.chartMetrics.conversionsCpa && <ChartCard
              title="Конверсии/ CPA"
              chartType="bar-line"
              series={
                content.chartMetrics.conversionsCpa.series
              }
              xAxis={
                content.chartMetrics.conversionsCpa.xAxis
              }
              className="inline-block h-full mr-4"
            />}
            {/*             {content.chartMetrics.installsCpu && <ChartCard
              title="Установки/ CPU"
              chartType="bar-line"
              series={
                content.chartMetrics.installsCpu.series
              }
              xAxis={
                content.chartMetrics.installsCpu.xAxis
              }
              className="inline-block h-full"
            />} */}
          </>
        )}
      </div>

    </div>
  )
}

export default DashboardCharts