import { AuthPage } from "widgets/auth";
import { AuthCard, RegistrationForm } from "features/auth";

export default () => {
  return (
    <AuthPage component={
      <AuthCard>
        <RegistrationForm />
      </AuthCard>
    }/>
  );
}