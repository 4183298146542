export const dataForCharts = {
  barLineChart: {
    series: [
      {
        name: "Conversions",
        type: "column",
        data: [440, 505, 414, 671, 227, 413, 440, 505, 414, 671, 227, 413],
        color: "#6CD9A3",
      },
      {
        name: "CPA",
        type: "line",
        data: [2, 2, 4, 3, 2, 4, 5, 4, 3, 2, 4, 5],
        color: "#FFA900",
      },
    ],
    xAxis: ["Facebook", "Google", "TikTok", "Яндекс", "VK", "MyTarget", "Facebook", "Google", "TikTok", "Яндекс", "VK", "MyTarget"],
    yAxis: [
      {
        show: true,
      },
      {
        opposite: true,
        show: true,
      },
    ],
  },
  lineChart: {
    series: [
      {
        name: "s1",
        data: [1, 7, 2, 4, 3, 5, 5, 7, 5],
        color: "#44AD3E",
      },
      {
        name: "s1",
        data: [2, 4, 3, 6, 1, 3, 4, 6, 5],
        color: "#6CD9A3",
      },
      {
        name: "s1",
        data: [1, 3, 1, 5, 4, 6, 2, 4, 5],
        color: "#FFA900",
      },
    ],
    xAxis: [9, 8, 7, 6, 5, 4, 3, 2, 1],
  },
  barChart: {
    series: [
      {
        name: "План",
        data: [44, 55, 57, 56, 61, 58, 44, 55, 57, 56, 61, 58],
        color: "#FFA900",
      },
      {
        name: "Факт",
        data: [76, 85, 101, 98, 87, 105, 76, 85, 101, 98, 87, 105],
        color: "#6CD9A3",
      },
    ],
    xAxis: [
      "Facebook",
      "Google",
      "TikTok",
      "Яндекс",
      "VK",
      "MyTarget",
      "Facebook",
      "Google",
      "TikTok",
      "Яндекс",
      "VK",
      "MyTarget",
    ],
  },
};
