import Login from "./login";
import ResetPassword from "./resetPassword";
import AnswerResetPassword from "./answerResetPassword";
import Registration from "./registration";
import RegistrationSuccess from "./registrationSuccess";

export {
  Login,
  ResetPassword,
  AnswerResetPassword,
  Registration,
  RegistrationSuccess
}