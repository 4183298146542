import { AuthPage } from "widgets/auth";
import { AuthCard, AuthFormEmail } from "features/auth";

export default ({ loginRole }: { loginRole: "user" | "system-admin" | "superadmin" }) => {
  return (
    <AuthPage component={
      <AuthCard>
        <AuthFormEmail role={loginRole} />
      </AuthCard>
    }
    isAdmin={
      loginRole === "system-admin" || loginRole === "superadmin"
    }/>
  );
}