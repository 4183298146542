import React, { useEffect } from 'react'
// Компонент использует CSS модуль, поэтому импортируем стили.
import styles from './Speedometer.module.css'
import { animate } from 'framer-motion'

type SpeedometerProps = {
    kpi: number
    title: React.ReactNode
    picker: React.ReactNode
    fixedLabel?: boolean
}

const Speedometer = ({kpi, picker, title, fixedLabel}: SpeedometerProps) => {

  // Создаём ссылки на элементы DOM, которые будут анимироваться.
  // emptyRef - пустой сектор спидометра (серый).
  // labelRef - текстовое поле с KPI.
  const emptyRef = React.useRef() as React.MutableRefObject<HTMLDivElement>
  const labelRef = React.useRef() as React.MutableRefObject<HTMLDivElement>

  // Анимация спидометра на основе KPI.
  // Используется библиотека framer-motion.
  useEffect(() => {
    const emptyNode = emptyRef.current
    const labelNode = labelRef.current

    const onUpdate = (v: number) => {
      if (emptyNode && labelNode) {
        const degrees = v * 1.8
        emptyNode.style.transform = `rotate(${degrees}deg)`
        !fixedLabel && (labelNode.textContent = `${v.toFixed(0)}%`)
      }
    }

    const controls = animate(0, kpi, { duration: 1, onUpdate })

    return () => controls.stop()
  }, [kpi])

  return (
    <div className={styles.container}>
      <div className={`${styles.label} font-inter font-bold text-lg`}>{title}</div>
      <div className={`${styles.select} font-inter font-bold text-lg`}>{picker}</div>
      <div className={styles["chart-container"]}>
        <div className={styles.chart}>
          <div className={styles.fill}></div>
          <div ref={emptyRef} className={styles.empty}> </div>
          <div className={styles.cover + " flex justify-center items-center pt-[5%] box-border"}>          
            <span
              ref={labelRef}
              className={`font-inter font-bold text-[2rem] translate-y-[-2rem]`}>
              {kpi}
            </span></div>
        </div>
      </div>
    </div>
  )
}

export default Speedometer  