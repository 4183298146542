import { createAsyncThunk } from "@reduxjs/toolkit";
import { loadConfig, loadLocale } from "shared/assets/i18n";

export const fetchLocale = createAsyncThunk(
  'content/fetchLocale',
  async (name?: localeName) => {
    const localeToLoad = name || (await loadConfig()).localeToLoad;
    const translationData = await loadLocale(localeToLoad as localeName);
    return { translationData, name };
  }
);
