import { useRef, useState } from "react";

import { Table, TableBody } from "@mui/material";

import ComplexTableHeader from "shared/ui/table/utils";
import { ComplexTableRow, ComplexTableRowProps } from "./ComplexTableRow";
import ReactPaginate from "react-paginate";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";

/**
 * @param cols.name - must be unique! column id
 * @param cols.title - column title (displayed in table header)
 * @param cols.group - column group (repeat in each element of the group)
 * @param cols.specialCellStyles? - tailwind string, applied to every <TableCell> of the column
 * @param rows - [{columnName: cellContent}]
 * @param className? - class name for the wrapper, can be used for styling
 * @param groupTitles? - {groupName: groupTitle}
 */
export type ComplexTableProps<T extends object> = {
  cols: {
    name: string;
    title: React.ReactNode;
    group?: string;
    specialCellStyles?: string;
  }[];
  className?: string;
  rawData: T[];
  groupTitles?: {
    [key: string]: React.ReactNode;
  };
  rowTransformer: ComplexTableRowProps<T>["transformer"];
};

export const styles = {
  tableWrapper: "flex flex-start w-full",
  tableCell:
    "font-inter text-center px-3 py-0 text-sm border-x-1 border-y-1 border-[#e1e4e8] text-ellipsis overflow-hidden whitespace-nowrap max-w-[15rem] hover:bg-[#f6f8fa] hover:scale-[1.17]",
  tableHeaderCell: "font-bold text-md",
};

export const ComplexTable = <T extends object>({
  cols,
  className,
  groupTitles,
  rawData,
  rowTransformer,
}: ComplexTableProps<T>) => {
  return (
    <>
      <Table className={className}>
        <ComplexTableHeader cols={cols} groupTitles={groupTitles} />
        <TableBody>
          {rawData.map((row, index) => (
            <ComplexTableRow<T>
              key={index}
              index={index}
              data={row}
              cols={cols}
              styles={{ cell: styles.tableCell, row: "" }}
              transformer={rowTransformer}
            />
          ))}
        </TableBody>
      </Table>
      {!rawData.length && ("No data found")}</>
  );
}

export const WrappedComplexTable = <T extends object>({
  cols,
  className,
  groupTitles,
  rawData,
  rowTransformer,
}: ComplexTableProps<T>) => {
  const tableWrapper = useRef<HTMLDivElement>(null);

  return (
    <div className={`${className} ${styles.tableWrapper}`} ref={tableWrapper}>
      <div className="overflow-x-auto overflow-y-hidden">
        <ComplexTable<T>
          cols={cols}
          groupTitles={groupTitles}
          rawData={rawData}
          rowTransformer={rowTransformer}
        />
      </div>
    </div>
  );
};

export const PaginatedComplexTable = <T extends object>({
  cols,
  className,
  groupTitles,
  rawData: currentRows,
  rowTransformer,
  pages,
  rowsPerPage,
  totalRows,
  handlePageChange,
}: ComplexTableProps<T> & {
  pages: number;
  rowsPerPage: number;
  totalRows: number;
  handlePageChange: (page: number) => void;
}) => {
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [rowsOffset, setRowsOffset] = useState(0);

  const handlePageClick = (event: { selected: number }) => {
    handlePageChange(event.selected);
    setRowsOffset(event.selected * rowsPerPage);
  };

  const pageButtonStyles = `block box-content h-7 w-7 text-center
     cursor-pointer [&>*]:block [&>*]:h-7 [&>*]:leading-7
     border-grey-300 border-[1px] border-solid rounded-[25%] aspect-square list-none`;

  return (
    <>
      <WrappedComplexTable<T>
        cols={cols}
        className={className}
        groupTitles={groupTitles}
        rawData={currentRows}
        rowTransformer={rowTransformer}
      />
      <ReactPaginate
        breakLabel="..."
        nextLabel={<IconChevronRight size={20} className="mt-1" />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pages}
        previousLabel={<IconChevronLeft size={20} className="mt-1" />}
        renderOnZeroPageCount={null}
        className="flex justify-center gap-3"
        pageClassName={pageButtonStyles}
        previousClassName={pageButtonStyles}
        nextClassName={pageButtonStyles}
        breakClassName="block list-none"
        activeClassName="font-bold border-primary bg-grey-50"
      />
      {/*       <div className="text-center pb-4">
        Показываем строчки с {rowsOffset} по {rowsOffset + rowsPerPage}
      </div> */}
      <div className="text-center pb-4">
        Показываем строчки с {rowsOffset} по{" "}
        {rowsOffset + rowsPerPage > totalRows
          ? totalRows
          : rowsOffset + rowsPerPage}
      </div>
    </>
  );
};
