import { useEffect, useState, MutableRefObject } from "react";

// При изменении ширины окна браузера меняем ширину графика
const useChartWidthResizer = (
  xAxis: string[],
  chartRef: MutableRefObject<HTMLDivElement | null>
) => {

  // Вычисляем ширину графика в зависимости от количества элементов на оси X
  // Если ширина графика больше ширины родительского элемента, то возвращаем ширину графика
  // Иначе возвращаем ширину родительского элемента чтобы растянуть график до него
  const calculateChartWidth = () => {
    const parentWidth = chartRef.current?.parentElement?.clientWidth || 0;
    const chartWidth = xAxis.length * 50;
    if (chartWidth > parentWidth) {
      return chartWidth;
    }
    return parentWidth - 50;
  };

  // Ширина графика (state)
  const [chartWidth, setChartWidth] = useState<number>(calculateChartWidth());

  useEffect(() => {
    // Выполняется при монтировании компонента
    setChartWidth(calculateChartWidth());

    window.addEventListener("resize", () => {
      // Выполняется при изменении ширины окна браузера
      setChartWidth(calculateChartWidth());
    });

    return () => {
      // Выполняется при размонтировании компонента
      window.removeEventListener("resize", () => {
        setChartWidth(calculateChartWidth());
      });
    };
  }, []);

  return chartWidth;
};

export default useChartWidthResizer; 