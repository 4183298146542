import React from "react";
import Switch from "./Switch";

type SwitchBoxProps = {
  uncheckedNode?: React.ReactNode;
  checkedNode?: React.ReactNode;
  uncheckedTitle: string;
  checkedTitle: string;
  className?: string;
  defaultChecked?: boolean;
};

const SwitchBox = ({
  defaultChecked,
  uncheckedNode,
  checkedNode,
  uncheckedTitle,
  checkedTitle,
  className,
}: SwitchBoxProps) => {
  const [isChecked, setIsChecked] = React.useState(Boolean(defaultChecked));

  const handleSwitch = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className={className}>
      <div className="flex justify-center items-center">
        <Switch
          defaultChecked={defaultChecked}
          onChange={handleSwitch}
          left={uncheckedTitle || "Таблицей"}
          right={checkedTitle || "Графиками"}
          className="mb-2 translate-y-[-.5rem]"
        />
      </div>
      <div className="flex flex-col flex-auto">{!isChecked ? uncheckedNode : checkedNode}</div>
    </div>
  );
};

export default SwitchBox;
