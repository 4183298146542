import { Button, CircularProgress, TextField } from "@mui/material";
import { useMemo, useState } from "react";
import { useCreateConnectorMutation } from "entities/connectors";
import { adsSystems } from "shared/constants";
import { i18nLoc } from "./i18nLoc";
import { connectorCreate } from "./utils";

/*
  фб:
айди рекламного кабинета в login
code

тт:
auth_code, сейчас передавай в code
айди рекламного кабинета в login

vk:
тоже жду code
айди рекламного кабинета в login
  */

export interface IInstructionsProps {
  agencyId: number;
  clientId: number;
}

export const InstructionYandex = ({
  agencyId,
  clientId,
}: IInstructionsProps) => {
  const [yandexLogin, setYandexLogin] = useState("");
  const [apiName, setapiName] = useState("");
  const [error, seterror] = useState<string | null>(null);
  const [query, queryState] = useCreateConnectorMutation();

  const redirectUrl = "https://app.danametrics.ai/api/v1/ya_callback/";
  const urlOnSystem = `https://oauth.yandex.ru/authorize?response_type=token&client_id=2e086ae368af4903ae03959b2e6af71e&redirect_uri=${redirectUrl}&state=`;

  const state = useMemo(
    () =>
      // eslint-disable-next-line max-len
      `${yandexLogin}`,
    [yandexLogin],
  );

  const loginChangeHandle = (event: any) => setYandexLogin(event.target.value);
  const handleApiNameChange = (e: any) => setapiName(e.target.value);
  const handleConnectorCreate = async () => {
    const errorFromRes = await connectorCreate({
      query,
      type: adsSystems.yandex,
      name: apiName,
      agency_id: agencyId,
      client_id: clientId,
      link: urlOnSystem,
      state: state,
    });

    if (errorFromRes) seterror(errorFromRes);
  };

  return (
    <div>
      <ol>
        <li className="mb-2">
          <div>
            <div>{i18nLoc.yandex_type_account_login}</div>
            <div>
              <TextField
                label={i18nLoc.yandex_login}
                onChange={loginChangeHandle}
                className="w-80 bg-white my-2"
              ></TextField>
            </div>
          </div>
        </li>
        <li className="mb-2">
          <div>
            <div>{i18nLoc.set_api_name}</div>
            <div>
              <TextField
                label={i18nLoc.api_name}
                onChange={handleApiNameChange}
                className="w-80 bg-white my-2"
              ></TextField>
            </div>
          </div>
        </li>

        <li className="mb-2">
          {i18nLoc.yandex_follow_the}{" "}
          <Button
            className="lowercase text-base p-0 m-0"
            variant="text"
            disabled={
              !clientId || !apiName || !yandexLogin || queryState.isLoading
            }
            onClick={handleConnectorCreate}
          >
            {i18nLoc.yandex_link}
          </Button>
          {queryState.isLoading ? <CircularProgress /> : null}
          {error ? (
            <span className="ml-2 text-sm text-red-600 font-bold">{error}</span>
          ) : null}
        </li>
        <li className="mb-2">{i18nLoc.yandex_instruction_step_1}</li>
        <li className="mb-2">{i18nLoc.yandex_instruction_step_2}</li>
      </ol>
    </div>
  );
};

export const InstructionFb = ({ agencyId, clientId }: IInstructionsProps) => {
  const [fbCabinetId, setFbCabinetId] = useState("");
  const [apiName, setapiName] = useState("");
  const [error, seterror] = useState<string | null>(null);
  const [query, queryState] = useCreateConnectorMutation();

  const redirectUrl = "https://app.danametrics.ai/api/v1/fb_callback/";
  const urlOnSystem = `https://www.facebook.com/v15.0/dialog/oauth?client_id=1868159536909656&redirect_uri=${redirectUrl}&scope=ads_read&state=`;

  const state = useMemo(() => `${fbCabinetId}`, [fbCabinetId]);

  const handleConnectorCreate = async () => {
    const errorFromRes = await connectorCreate({
      query,
      type: "FACEBOOK",//adsSystems.fb,
      name: apiName,
      agency_id: agencyId,
      client_id: clientId,
      link: urlOnSystem,
      state,
    });

    if (errorFromRes) seterror(errorFromRes);
  };

  const companyIdChangeHandle = (event: any) =>
    setFbCabinetId(event.target.value);
  const handleApiNameChange = (e: any) => setapiName(e.target.value);

  //instructions for getting id
  const whereToGetCompanyIdLink =
    "https://www.facebook.com/business/help/1492627900875762";

  return (
    <div>
      <ol>
        <li className="mb-2">
          <div>
            <div>
              {i18nLoc.fb_type_account_id} {i18nLoc.fb_instruction}:{" "}
              <a
                href={whereToGetCompanyIdLink}
                rel="noreferrer"
                target="_blank"
              >
                {i18nLoc.fb_where_to_find_id}
              </a>
            </div>
            <div>
              <TextField
                label={i18nLoc.fb_company_id}
                onChange={companyIdChangeHandle}
                className="w-80 bg-white my-2"
              ></TextField>
            </div>
          </div>
        </li>
        <li className="mb-2">
          <div>
            <div>{i18nLoc.set_api_name}</div>
            <div>
              <TextField
                label={i18nLoc.api_name}
                onChange={handleApiNameChange}
                className="w-80 bg-white my-2"
              ></TextField>
            </div>
          </div>
        </li>

        <li className="mb-2">
          {i18nLoc.follow_the}{" "}
          <Button
            className="lowercase text-base p-0 m-0"
            variant="text"
            disabled={
              !clientId || !apiName || !fbCabinetId || queryState.isLoading
            }
            onClick={handleConnectorCreate}
          >
            {i18nLoc.link}
          </Button>
          {queryState.isLoading ? <CircularProgress /> : null}
          {error ? (
            <span className="ml-2 text-sm text-red-600 font-bold">{error}</span>
          ) : null}
        </li>
        <li className="mb-2">{i18nLoc.fb_instruction_step_1}</li>
        <li className="mb-2">{i18nLoc.fb_instruction_step_2}</li>
      </ol>
    </div>
  );
};

export const InstructionVK = ({ agencyId, clientId }: IInstructionsProps) => {
  const [vkAdsId, setVkAdsId] = useState("");
  const [apiName, setapiName] = useState("");
  const [error, seterror] = useState<string | null>(null);
  const [query, queryState] = useCreateConnectorMutation();

  const redirectUrl = "https://app.danametrics.ai/api/v1/vk_callback/";
  //                   https://oauth.vk.com/authorize?client_id=51427957&display=page&redirect_uri=https://app.danametrics.ai/api/v1/vk_callback/&scope=ads&response_type=code&v=5.154
  const urlOnSystem = `https://oauth.vk.com/authorize?client_id=51467517&redirect_uri=${redirectUrl}&scope=offline,ads&response_type=token&state=`;

  const state = useMemo(
    () =>
      // eslint-disable-next-line max-len
      `${vkAdsId}`,
    [vkAdsId],
  );

  const cabinetIdChangeHandler = (event: any) => setVkAdsId(event.target.value);

  const handleApiNameChange = (e: any) => setapiName(e.target.value);

  const handleConnectorCreate = async () => {
    const errorFromRes = await connectorCreate({
      query,
      type: adsSystems.vk,
      name: apiName,
      agency_id: agencyId,
      client_id: clientId,
      link: urlOnSystem,
      state: state,
    });

    if (errorFromRes) seterror(errorFromRes);
  };

  return (
    <div>
      <ol>
        <li className="mb-2">
          <div>
            <div>{i18nLoc.vk_type_account_id}</div>
            <div>
              <TextField
                label={i18nLoc.vk_id}
                onChange={cabinetIdChangeHandler}
                className="w-80 bg-white my-2"
              ></TextField>
            </div>
          </div>
        </li>
        <li className="mb-2">
          <div>
            <div>{i18nLoc.set_api_name}</div>
            <div>
              <TextField
                label={i18nLoc.api_name}
                onChange={handleApiNameChange}
                className="w-80 bg-white my-2"
              ></TextField>
            </div>
          </div>
        </li>

        <li className="mb-2">
          {i18nLoc.follow_the}{" "}
          <Button
            className="lowercase text-base p-0 m-0"
            variant="text"
            disabled={!clientId || !apiName || !vkAdsId || queryState.isLoading}
            onClick={handleConnectorCreate}
          >
            {i18nLoc.link}
          </Button>
          {queryState.isLoading ? <CircularProgress /> : null}
          {error ? (
            <span className="ml-2 text-sm text-red-600 font-bold">{error}</span>
          ) : null}
        </li>
        <li className="mb-2">{i18nLoc.vk_instruction_step_1}</li>
        <li className="mb-2">{i18nLoc.vk_instruction_step_2}</li>
      </ol>
    </div>
  );
};

export const InstructionTT = ({ agencyId, clientId }: IInstructionsProps) => {
  const [accId, setaccId] = useState("");
  const [apiName, setapiName] = useState("");
  const [error, seterror] = useState<string | null>(null);
  const [query, queryState] = useCreateConnectorMutation();

  const redirectUrl = "https://app.danametrics.ai/api/v1/tt_callback/";
  //7260036733889216513 - new
  //7163524381056958466
  //7163524381056958466 - old
  //
  // const urlOnSystem = `https://ads.tiktok.com/marketing_api/auth?app_id=7163524381056958466&rid=gkiezjx368&redirect_uri=${redirectUrl}&state=`;
  const urlOnSystem = `https://business-api.tiktok.com/portal/auth?app_id=7312744168378335233&redirect_uri=${redirectUrl}&state=`;

  const whereToFindAdAccId =
    "https://support.appsflyer.com/hc/en-us/articles/360002254918-TikTok-For-Business-campaign-configuration#:~:text=You%20can%20get%20your%20advertiser,you%20can%20see%20the%20ID";

  const state = useMemo(
    () =>
      // eslint-disable-next-line max-len
      `${accId}`,
    [accId],
  );

  const cabinetIdChangeHandler = (event: any) => setaccId(event.target.value);

  const handleApiNameChange = (e: any) => setapiName(e.target.value);

  const handleConnectorCreate = async () => {
    const errorFromRes = await connectorCreate({
      query,
      type: adsSystems.tiktok,
      name: apiName,
      agency_id: agencyId,
      client_id: clientId,
      link: urlOnSystem,
      state: state,
    });

    if (errorFromRes) seterror(errorFromRes);
  };

  return (
    <div>
      <ol>
        <li className="mb-2">
          <div>
            <div>
              {i18nLoc.tiktok_type_account_id} {i18nLoc.fb_instruction}:{" "}
              <a href={whereToFindAdAccId} rel="noreferrer" target="_blank">
                {i18nLoc.fb_where_to_find_id}
              </a>
            </div>

            <div>
              <TextField
                label={i18nLoc.tiktok_id}
                onChange={cabinetIdChangeHandler}
                className="w-80 bg-white my-2"
              ></TextField>
            </div>
          </div>
        </li>
        <li className="mb-2">
          <div>
            <div>{i18nLoc.set_api_name}</div>
            <div>
              <TextField
                label={i18nLoc.api_name}
                onChange={handleApiNameChange}
                className="w-80 bg-white my-2"
              ></TextField>
            </div>
          </div>
        </li>

        <li className="mb-2">
          {i18nLoc.follow_the}{" "}
          <Button
            className="lowercase text-base p-0 m-0"
            variant="text"
            disabled={!clientId || !apiName || !accId || queryState.isLoading}
            onClick={handleConnectorCreate}
          >
            {i18nLoc.link}
          </Button>
          {queryState.isLoading ? <CircularProgress /> : null}
          {error ? (
            <span className="ml-2 text-sm text-red-600 font-bold">{error}</span>
          ) : null}
        </li>
        <li className="mb-2">{i18nLoc.tiktok_instruction_step_1}</li>
        <li className="mb-2">{i18nLoc.tiktok_instruction_step_2}</li>
      </ol>
    </div>
  );
};

const clearFromDashes = (str: string): string => str.replaceAll("-", "");

export const InstructionGAds = ({ agencyId, clientId }: IInstructionsProps) => {
  const [googleLogin, setgoogleLogin] = useState("");
  const [googleMccID, setgoogleMccID] = useState("");
  const [apiName, setapiName] = useState("");
  const [error, seterror] = useState<string | null>(null);
  const [query, queryState] = useCreateConnectorMutation();

  const loginChangeHandler = (event: any) => {
    const val = event.target.value;
    if (!val) {
      setgoogleLogin("");
      return;
    }

    setgoogleLogin(clearFromDashes(val));
  };

  const mccIdChangeHandler = (event: any) => {
    const val = event.target.value;
    if (!val) {
      setgoogleMccID("");
      return;
    }

    setgoogleMccID(clearFromDashes(val));
  };

  const handleApiNameChange = (e: any) => setapiName(e.target.value);

  const redirectUrl = "https://app.danametrics.ai/api/v1/google_callback/";

  const urlOnSystem = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=43905694353-9mpjooivjmn1g2qg1ekab3qo7gb3ge3e.apps.googleusercontent.com&redirect_uri=${redirectUrl}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadwords&access_type=offline&prompt=consent&include_granted_scopes=true&state=`

  const whereToFindAccId =
    "https://support.google.com/google-ads/answer/1704344";

  const state = useMemo(
    () =>
      // eslint-disable-next-line max-len
      `${googleLogin}||${googleMccID}`,
    [googleLogin, googleMccID],
  );

  const handleConnectorCreate = async () => {
    const errorFromRes = await connectorCreate({
      query,
      type: adsSystems.google,
      name: apiName,
      agency_id: agencyId,
      client_id: clientId,
      link: urlOnSystem,
      state,
    });

    if (errorFromRes) seterror(errorFromRes);
  };

  // const link = useMemo(
  //   () =>
  //     // eslint-disable-next-line max-len
  //     `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=260366540880-i5jb42rsrqgq8rh6glt37i3nakvb7or5.apps.googleusercontent.com&redirect_uri=${redirectUrl}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadwords&access_type=offline&prompt=consent&include_granted_scopes=true&state=${agencyId}||${clientId}||${googleLogin}||${apiName}`,
  //   [agencyId, googleLogin, apiName, clientId],
  // );

  return (
    <div>
      <ol>
        <li className="mb-2">
          <div>
            <div>
              {i18nLoc.google_type_account_id}{" "}
              {i18nLoc.google_where_to_find_account_id}:{" "}
              <a href={whereToFindAccId} rel="noreferrer" target="_blank">
                {i18nLoc.google_instruction_account_id}
              </a>
            </div>
            <div>
              <TextField
                label={i18nLoc.google_client_id}
                onChange={loginChangeHandler}
                className="w-80 bg-white my-2"
              ></TextField>
            </div>
            <div>
              <TextField
                label={i18nLoc.google_account_id}
                onChange={mccIdChangeHandler}
                className="w-80 bg-white my-2"
              ></TextField>
            </div>
          </div>
        </li>
        <li className="mb-2">
          <div>
            <div>{i18nLoc.set_api_name}</div>
            <div>
              <TextField
                label={i18nLoc.api_name}
                onChange={handleApiNameChange}
                className="w-80 bg-white my-2"
              ></TextField>
            </div>
          </div>
        </li>

        <li className="mb-2">
          {i18nLoc.follow_the}{" "}
          <Button
            className="lowercase text-base p-0 m-0"
            variant="text"
            disabled={
              !clientId ||
              !apiName ||
              !googleLogin ||
              !googleMccID ||
              queryState.isLoading
            }
            onClick={handleConnectorCreate}
          >
            {i18nLoc.link}
          </Button>
          {queryState.isLoading ? <CircularProgress /> : null}
          {error ? (
            <span className="ml-2 text-sm text-red-600 font-bold">{error}</span>
          ) : null}
        </li>
        <li className="mb-2">{i18nLoc.google_instruction_step_1}</li>
        <li className="mb-2">{i18nLoc.google_instruction_step_2}</li>
      </ol>
    </div>
  );
};
