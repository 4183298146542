import { ComplexTable } from "shared/ui/table";
import { prettifyNumber } from "shared/lib/metrics";
import { EditableCell } from "shared/ui/table";
import { useMemo, useState } from "react";
import { useLocale } from "entities/i18n";

type Props = {
  mediaplan: {
    filename: string;
    data: MediaplanResponseSchema[];
  };
  onEditMetric: (
    rowIndex: number,
    metricName: MediaplanEditableMetric,
    newValue: number
  ) => void;
  editable: boolean;
};

export const MediaplanTable = (props: Props) => {
  const {dashboard: i18n} = useLocale();

  const cols = [
    {
      name: "id",
      title: "",
    },
    { name: "cabinet", title: i18n.cabinet},
    // brand, campaign_id, campaign
    { name: "brand", title: i18n.brand},
    // { name: "campaign_id", title: "Campaign ID" },
    // { name: "campaign", title: "Campaign" },
    // status, dates, days
    // { name: "status", title: "Status" },
    { name: "dates", title: i18n.date},
    // { name: "days", title: "Days" },
    // key_metric, purchase_unit, currency
    // { name: "key_metric", title: "Key Metric" },
    // { name: "purchase_unit", title: "Purchase Unit" },
    // { name: "currency", title: "Currency" },
    // budget, impressions, cpm, clicks, cpc, ctr, frequency, reach, cpu, views, cpv, vtr, conversions, cpa, installs, cpi
    { name: "budget", title: i18n.budget}, 
    { name: "impressions", title: i18n.impressions}, 
    { name: "cpm", title: i18n.cpm}, 
    { name: "clicks", title: i18n.clicks}, 
    { name: "cpc", title: i18n.cpc}, 
    { name: "ctr", title: i18n.ctr}, 
    { name: "frequency", title: i18n.frequency}, 
    { name: "reach", title: i18n.reach}, 
    { name: "cpu", title: i18n.cpu}, 
    { name: "views", title: i18n.views}, 
    { name: "cpv", title: i18n.cpv}, 
    { name: "vtr", title: i18n.vtr}, 
    { name: "conversions", title: i18n.conversions}, 
    { name: "cpa", title: i18n.cpa}, 
    { name: "installs", title: i18n.installs}, 
    { name: "cpi", title: i18n.cpi}, 
  ];

  const [touchedCells, setTouchedCells] = useState<
    Map<MediaplanEditableMetric, boolean>[]
  >(
    props.mediaplan.data.map(
      () =>
        new Map(
          Object.entries({
            budget: false,
            impressions: false,
            cpm: false,
            clicks: false,
            cpc: false,
            ctr: false,
            frequency: false,
            reach: false,
            cpu: false,
            views: false,
            cpv: false,
            vtr: false,
            conversions: false,
            cpa: false,
            installs: false,
            cpi: false,
          }) as [MediaplanEditableMetric, boolean][]
        )
    )
  );

  const handleCellEdit = (
    rowIndex: number,
    colName: MediaplanEditableMetric,
    fixed?: number
  ) => {
    return (n: number) => {
      setTouchedCells(
        ((prev) => {
          const newTouchedCells = [...prev];
          newTouchedCells[rowIndex].set(
            colName,
            n !=
              (fixed
                ? props.mediaplan.data[rowIndex][colName].toFixed(fixed)
                : Math.round(props.mediaplan.data[rowIndex][colName]))
          );
          return newTouchedCells;
        })(touchedCells)
      );
      props.onEditMetric(rowIndex, colName, n);
    };
  };

  const getCellClassName = (
    rowIndex: number,
    colName: MediaplanEditableMetric
  ) => `w-24 ${touchedCells[rowIndex].get(colName) ? "bg-[#f7d474]" : ""}`;

  const rowTransformer = useMemo(
    () => (row: MediaplanResponseSchema, rowImmutable: MediaplanResponseSchema, rowIndex: number) => {
      return {
        id: <div className="h-8 leading-8">1</div>,
        cabinet: row.ad_cabinet,
        brand: row.brand,
        campaign_id: row.campaign_id,
        campaign: row.campaign,
        status: row.status,
        dates: `${row.date_from} - ${row.date_to}`,
        days: row.days,
        key_metric: "not yet",
        purchase_unit: row.ad_pricing,
        currency: row.currency,

        //metrics
        // budget: prettifyNumber(Math.round(row.budget)),
        budget: (
          <EditableCell.number
            value={Math.round(row.budget)}
            formatter={(n: number) => prettifyNumber(Math.round(n))}
            onChange={handleCellEdit(rowIndex, "budget")}
            className={getCellClassName(rowIndex, "budget")}
            editable={props.editable}
          />
        ),
        // impressions: prettifyNumber(Math.round(row.impressions)),
        impressions:           (<EditableCell.number
          value={Math.round(row.impressions)}
          formatter={(n: number) => prettifyNumber(Math.round(n))}
          onChange={handleCellEdit(rowIndex, "impressions")}
          className={getCellClassName(rowIndex, "impressions")}
          editable={props.editable}
        />),
        // cpm: prettifyNumber(row.cpm, 2),
        cpm: (
          <EditableCell.number
            value={row.cpm}
            formatter={(n: number) => prettifyNumber(n, 2)}
            onChange={handleCellEdit(rowIndex, "cpm", 2)}
            className={getCellClassName(rowIndex, "cpm")}
            editable={props.editable}
          />
        ),
        // clicks: prettifyNumber(Math.round(row.clicks)),
        clicks: (<EditableCell.number
          value={Math.round(row.clicks)}
          formatter={(n: number) => prettifyNumber(Math.round(n))}
          onChange={handleCellEdit(rowIndex, "clicks")}
          className={getCellClassName(rowIndex, "clicks")}
          editable={props.editable}
        />),
        // cpc: prettifyNumber(row.cpc, 2),
        cpc: (
          <EditableCell.number
            value={row.cpc}
            formatter={(n: number) => prettifyNumber(n, 2)}
            onChange={handleCellEdit(rowIndex, "cpc", 2)}
            className={getCellClassName(rowIndex, "cpc")}
            editable={props.editable}
          />
        ),
        // ctr: prettifyNumber(row.ctr, 2),
        ctr: (
          <EditableCell.number
            value={Number(row.ctr.toFixed(4))}
            formatter={(n: number) => prettifyNumber(n, 4)}
            onChange={handleCellEdit(rowIndex, "ctr", 2)}
            className={getCellClassName(rowIndex, "ctr")}
            editable={props.editable}
          />
        ),
        // frequency: prettifyNumber(Math.round(row.frequency)),
        frequency: (
          <EditableCell.number
            value={Math.round(row.frequency)}
            formatter={(n: number) => prettifyNumber(Math.round(n))}
            onChange={handleCellEdit(rowIndex, "frequency")}
            className={getCellClassName(rowIndex, "frequency")}
            editable={props.editable}
          />
        ),
        // reach: prettifyNumber(Math.round(row.reach)),
        reach: (
          <EditableCell.number
            value={Math.round(row.reach)}
            formatter={(n: number) => prettifyNumber(Math.round(n))}
            onChange={handleCellEdit(rowIndex, "reach")}
            className={getCellClassName(rowIndex, "reach")}
            editable={props.editable}
          />
        ),
        // cpu: prettifyNumber(row.cpu, 2),
        cpu: (
          <EditableCell.number
            value={row.cpu}
            formatter={(n: number) => prettifyNumber(n, 2)}
            onChange={handleCellEdit(rowIndex, "cpu", 2)}
            className={getCellClassName(rowIndex, "cpu")}
            editable={props.editable}
          />
        ),
        // views: prettifyNumber(Math.round(row.views)),
        views: (
          <EditableCell.number
            value={Math.round(row.views)}
            formatter={(n: number) => prettifyNumber(Math.round(n))}
            onChange={handleCellEdit(rowIndex, "views")}
            className={getCellClassName(rowIndex, "views")}
            editable={props.editable}
          />
        ),
        // cpv: prettifyNumber(row.cpv, 2),
        cpv: (
          <EditableCell.number
            value={row.cpv}
            formatter={(n: number) => prettifyNumber(n, 2)}
            onChange={handleCellEdit(rowIndex, "cpv", 2)}
            className={getCellClassName(rowIndex, "cpv")}
            editable={props.editable}
          />
        ),
        // vtr: prettifyNumber(row.vtr, 2),
        vtr: (
          <EditableCell.number
            value={row.vtr}
            formatter={(n: number) => prettifyNumber(n, 2)}
            onChange={handleCellEdit(rowIndex, "vtr", 2)}
            className={getCellClassName(rowIndex, "vtr")}
            editable={props.editable}
          />
        ),
        // conversions: prettifyNumber(Math.round(row.conversions)),
        conversions: (
          <EditableCell.number
            value={Math.round(row.conversions)}
            formatter={(n: number) => prettifyNumber(Math.round(n))}
            onChange={handleCellEdit(rowIndex, "conversions")}
            className={getCellClassName(rowIndex, "conversions")}
            editable={props.editable}
          />
        ),
        // cpa: prettifyNumber(row.cpa, 2),
        cpa: (
          <EditableCell.number
            value={row.cpa}
            formatter={(n: number) => prettifyNumber(n, 2)}
            onChange={handleCellEdit(rowIndex, "cpa", 2)}
            className={getCellClassName(rowIndex, "cpa")}
            editable={props.editable}
          />
        ),
        // installs: prettifyNumber(Math.round(row.installs)),
        installs: (
          <EditableCell.number
            value={Math.round(row.installs)}
            formatter={(n: number) => prettifyNumber(Math.round(n))}
            onChange={handleCellEdit(rowIndex, "installs")}
            className={getCellClassName(rowIndex, "installs")}
            editable={props.editable}
          />
        ),
        // cpi: prettifyNumber(row.cpi, 2),
        cpi: (
          <EditableCell.number
            value={row.cpi}
            formatter={(n: number) => prettifyNumber(n, 2)}
            onChange={handleCellEdit(rowIndex, "cpi", 2)}
            className={getCellClassName(rowIndex, "cpi")}
            editable={props.editable}
          />
        )
      };
    },
    [props.mediaplan.data, touchedCells, props.editable]
  );

  return (
    <ComplexTable<MediaplanResponseSchema>
      cols={cols}
      rawData={props.mediaplan.data}
      rowTransformer={rowTransformer}
      className="mb-2"
    />
  );
};
