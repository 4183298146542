import { useCurrentLocale } from "entities/i18n";
import { LocalePicker } from "features/i18n";

import { CommonLayout, Header } from "shared/ui/layout";
import { Footer } from "widgets/layout";

export const AppDescription = () => {
  const locale = useCurrentLocale();

  return (
    <div
      className="flex flex-col justify-between "
      style={{
        minHeight: "100vh",
      }}
    >
      <div className="flex flex-row justify-between pr-10 items-start flex-grow-0">
        <Header startPage="/" />
        <div className="ml-5 mt-5">
          <LocalePicker />
        </div>
      </div>
      <CommonLayout
        title={
          locale === "rus"
            ? "О проекте Danametrics"
            : "About the Danametrics project"
        }
      >
        {locale === "rus" ? (
          <div
            className="flex-grow items-start p-4 mx-auto"
            dangerouslySetInnerHTML={{
              __html: `
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:Roboto;
	panose-1:2 0 0 0 0 0 0 0 0 0;}
 /* Style Definitions */
 a:link, span.MsoHyperlink
	{color:blue;
	text-decoration:underline;}
p
	{margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;
	}
p.MsoNoSpacing, li.MsoNoSpacing, div.MsoNoSpacing
	{margin:0cm;
	font-size:11.0pt;
	}
span.msoIns
	{mso-style-name:"";
	text-decoration:underline;
	color:teal;}
.MsoChpDefault
	{font-size:11.0pt;
	}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:2.0cm 42.5pt 2.0cm 3.0cm;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

<div class="WordSection1 font-inter pl-8">

<p class=MsoNormal style='margin-bottom:0cm'><span lang=EN-US style='mso-ansi-language:
EN-US'>Danametrics</span><span lang=RU style='mso-ansi-language:RU'> – инструмент,
представляющий собой </span><span lang=EN-US style='mso-ansi-language:EN-US'>Digital</span><span
lang=RU style='mso-ansi-language:RU'>-<span class=SpellE>дашборд</span>,
призванный автоматизировать сбор статистических данных о рекламных кампаниях,
запущенных в интернет-пространстве. Инструмент включает в себя ПО, состоящее из
готовых алгоритмов для сбора, обработки и загрузки данных, и помогает </span><span
lang=EN-US style='mso-ansi-language:EN-US'>Digital</span><span lang=RU
style='mso-ansi-language:RU'>-специалистам автоматизировать работу, связанную
со сбором статистических данных из различных источников.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm'><span lang=RU style='mso-ansi-language:
RU'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm'><span lang=RU style='mso-ansi-language:
RU'>Основные характеристики:<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpFirst style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:38.4pt;mso-add-space:auto;text-indent:-18.0pt;
mso-list:l1 level1 lfo2'><![if !supportLists]><span lang=RU style='font-family:
Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;mso-ansi-language:
RU'><span style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span lang=RU style='mso-ansi-language:RU'>Прямая
интеграция с рекламными кабинетами </span><span lang=EN-US style='mso-ansi-language:
EN-US'>Google</span><span lang=RU style='mso-ansi-language:RU'>, </span><span
lang=EN-US style='mso-ansi-language:EN-US'>Yandex</span><span lang=RU
style='mso-ansi-language:RU'>, </span><span lang=EN-US style='mso-ansi-language:
EN-US'>Meta</span><span lang=RU style='mso-ansi-language:RU'>, </span><span
lang=EN-US style='mso-ansi-language:EN-US'>TikTok</span><span lang=RU
style='mso-ansi-language:RU'>, </span><span class=SpellE><span lang=EN-US
style='mso-ansi-language:EN-US'>Vkontakte</span></span><span lang=EN-US
style='mso-ansi-language:RU'> </span><span lang=RU style='mso-ansi-language:
RU'>посредством </span><span lang=EN-US style='mso-ansi-language:EN-US'>API</span><span
lang=RU style='mso-ansi-language:RU'>;<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:38.4pt;mso-add-space:auto;text-indent:-18.0pt;
mso-list:l1 level1 lfo2'><![if !supportLists]><span lang=RU style='font-family:
Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;mso-ansi-language:
RU'><span style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span lang=RU style='mso-ansi-language:RU'>Автоматизация
переноса статистических показателей из рекламных кабинетов (бюджет, показы,
клики, </span><span lang=EN-US style='mso-ansi-language:EN-US'>CTR</span><span
lang=RU style='mso-ansi-language:RU'>, просмотры, </span><span lang=EN-US
style='mso-ansi-language:EN-US'>CTR</span><span lang=RU style='mso-ansi-language:
RU'>, </span><span lang=EN-US style='mso-ansi-language:EN-US'>CPM</span><span
lang=RU style='mso-ansi-language:RU'>, </span><span lang=EN-US
style='mso-ansi-language:EN-US'>CPC</span><span lang=EN-US style='mso-ansi-language:
RU'> </span><span lang=RU style='mso-ansi-language:RU'>и др.);<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:38.4pt;mso-add-space:auto;text-indent:-18.0pt;
mso-list:l1 level1 lfo2'><![if !supportLists]><span lang=RU style='font-family:
Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;mso-ansi-language:
RU'><span style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span lang=RU style='mso-ansi-language:RU'>Визуализация
загруженных данных в виде <span class=SpellE>дашборда</span>. <span
class=SpellE>Дашборд</span> имеет функции представления данных как в табличном,
так и в графическом виде;<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpLast style='margin-top:0cm;margin-right:0cm;
margin-bottom:0cm;margin-left:38.4pt;mso-add-space:auto;text-indent:-18.0pt;
mso-list:l1 level1 lfo2'><![if !supportLists]><span lang=RU style='font-family:
Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;mso-ansi-language:
RU'><span style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span></span><![endif]><span lang=RU style='mso-ansi-language:RU'>Возможность
выгрузки данных из <span class=SpellE>дашборда</span> в формате </span><span
lang=EN-US style='mso-ansi-language:EN-US'>csv</span><span lang=RU
style='mso-ansi-language:RU'>/</span><span lang=EN-US style='mso-ansi-language:
EN-US'>pdf</span><span lang=RU style='mso-ansi-language:RU'>.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm'><span lang=RU style='mso-ansi-language:
RU'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm'><span lang=RU style='mso-ansi-language:
RU'>ПО </span><span lang=EN-US style='mso-ansi-language:EN-US'>Danametrics</span><span
lang=EN-US style='mso-ansi-language:RU'> </span><span lang=RU style='mso-ansi-language:
RU'>распространяется в виде интернет-сервиса, специальные действия по установке
ПО на стороне пользователя не требуются.<o:p></o:p></span></p>

</div>

`,
            }}
          ></div>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: `
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:Roboto;
	panose-1:2 0 0 0 0 0 0 0 0 0;}
 /* Style Definitions */
 a:link, span.MsoHyperlink
	{color:blue;
	text-decoration:underline;}
p
	{margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;
	}
p.MsoNoSpacing, li.MsoNoSpacing, div.MsoNoSpacing
	{margin:0cm;
	font-size:11.0pt;
	}
span.msoIns
	{mso-style-name:"";
	text-decoration:underline;
	color:teal;}
.MsoChpDefault
	{font-size:11.0pt;
	}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
@page WordSection1
	{size:595.3pt 841.9pt;
	margin:2.0cm 42.5pt 2.0cm 3.0cm;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>
<div class="WordSection1 font-inter pl-8">

<p class=MsoNormal style='margin-bottom:0cm'><span lang=EN-US style='mso-ansi-language:
EN-US'>Danametrics is a digital dashboard tool designed to automate the
collection of advertising campaigns statistics, which were launched in the
Internet space. The tool includes software consisting of ready-made algorithms
for data collection, processing and downloading, and helps Digital specialists
to automate the work related to the collection of statistical data from
different sources.<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpFirst style='margin-bottom:0cm;mso-add-space:auto;
text-indent:-18.0pt;mso-list:l2 level1 lfo3'><![if !supportLists]><span
lang=EN-US style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;mso-ansi-language:EN-US'><span style='mso-list:Ignore'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
lang=EN-US style='mso-ansi-language:EN-US'>Direct integration with Google,
Yandex, Meta, TikTok, <span class=SpellE>Vkontakte</span> ad cabinets via API;<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-indent:-18.0pt;mso-list:l2 level1 lfo3'><![if !supportLists]><span
lang=EN-US style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;mso-ansi-language:EN-US'><span style='mso-list:Ignore'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
lang=EN-US style='mso-ansi-language:EN-US'>Automating the transfer of
statistical indicators from ad cabinets (budget, impressions, clicks, CTR,
views, CTR, CPM, CPC, etc.);<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpMiddle style='margin-bottom:0cm;mso-add-space:
auto;text-indent:-18.0pt;mso-list:l2 level1 lfo3'><![if !supportLists]><span
lang=EN-US style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;mso-ansi-language:EN-US'><span style='mso-list:Ignore'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
lang=EN-US style='mso-ansi-language:EN-US'>Visualization of uploaded data as a
dashboard. Dashboard has functions of presenting data both in a tabular and
graphical form;<o:p></o:p></span></p>

<p class=MsoListParagraphCxSpLast style='margin-bottom:0cm;mso-add-space:auto;
text-indent:-18.0pt;mso-list:l2 level1 lfo3'><![if !supportLists]><span
lang=EN-US style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
Symbol;mso-ansi-language:EN-US'><span style='mso-list:Ignore'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span></span><![endif]><span
lang=EN-US style='mso-ansi-language:EN-US'>Possibility of uploading data from
dashboard in csv/pdf format.<o:p></o:p></span></p>

<p class=MsoNormal style='margin-bottom:0cm'><span lang=EN-US style='mso-ansi-language:
EN-US'>Danametrics software is distributed in the form of an Internet service,
special actions on installing the software on the user’s side are not required.<o:p></o:p></span></p>

</div>
            `,
            }}
          ></div>
        )}
      </CommonLayout>
      <div className="flex-grow-0">
        <Footer />
      </div>
    </div>
  );
};
