// material-ui
import { createTheme } from '@mui/material/styles';

// project import
import colors from './themeColors';
import { PaletteColorOptions } from '@mui/material/styles';

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = () => {
  const paletteColor = {...colors};

  return createTheme({
    palette: {
      common: {
        black: '#000',
        white: '#fff'
      },
      ...paletteColor as PaletteColorOptions,
      text: {
        primary: paletteColor.grey[700],
        secondary: paletteColor.grey[500],
        disabled: paletteColor.grey[400]
      },
      action: {
        disabled: paletteColor.grey[300]
      },
      divider: paletteColor.grey[200],
      background: {
        paper: paletteColor.grey[0],
      }
    }
  });
};

export default Palette;
