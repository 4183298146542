// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Speedometer_container__f6t1N {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1rem auto;
    grid-template-areas: "title select" "chart chart";
    min-width: 20rem;
    min-height: 10rem;
    height: 100%;
}

.Speedometer_label__krLVX {
    grid-area: title;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.Speedometer_select__G9t3g {
    grid-area: select;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.Speedometer_chart__trB1i {    
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 95%;
}

.Speedometer_chart-container__WD3oZ {
    grid-area: chart;
    padding-top: -50%;
    position: relative;
}

.Speedometer_chart__trB1i > * {
    position: absolute;
    top: 0;
    height: 200%;
    aspect-ratio: 1 / 1;
    border-radius: 100%;
}

.Speedometer_empty__5edww {
    background: conic-gradient(from 90deg, transparent 180deg, #E1E4E8 180deg);
}

.Speedometer_fill__jndnv {
    background: conic-gradient(from 270deg, #CC0000, #FFA900, #6CD9A3, #FFA900, #CC0000);
}

.Speedometer_cover__eL88V {
    background: white;  
    z-index: 1;
    max-height: 150%;
    aspect-ratio: 1 / 1;
    top: 25%;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/charts/Speedometer.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,6BAA6B;IAC7B,iDAAiD;IACjD,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,WAAW;IACX,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,0EAA0E;AAC9E;;AAEA;IACI,oFAAoF;AACxF;;AAEA;IACI,iBAAiB;IACjB,UAAU;IACV,gBAAgB;IAChB,mBAAmB;IACnB,QAAQ;AACZ","sourcesContent":[".container {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-template-rows: 1rem auto;\n    grid-template-areas: \"title select\" \"chart chart\";\n    min-width: 20rem;\n    min-height: 10rem;\n    height: 100%;\n}\n\n.label {\n    grid-area: title;\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n}\n\n.select {\n    grid-area: select;\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n}\n\n.chart {    \n    position: absolute;\n    bottom: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    overflow: hidden;\n    width: 100%;\n    height: 95%;\n}\n\n.chart-container {\n    grid-area: chart;\n    padding-top: -50%;\n    position: relative;\n}\n\n.chart > * {\n    position: absolute;\n    top: 0;\n    height: 200%;\n    aspect-ratio: 1 / 1;\n    border-radius: 100%;\n}\n\n.empty {\n    background: conic-gradient(from 90deg, transparent 180deg, #E1E4E8 180deg);\n}\n\n.fill {\n    background: conic-gradient(from 270deg, #CC0000, #FFA900, #6CD9A3, #FFA900, #CC0000);\n}\n\n.cover {\n    background: white;  \n    z-index: 1;\n    max-height: 150%;\n    aspect-ratio: 1 / 1;\n    top: 25%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Speedometer_container__f6t1N`,
	"label": `Speedometer_label__krLVX`,
	"select": `Speedometer_select__G9t3g`,
	"chart": `Speedometer_chart__trB1i`,
	"chart-container": `Speedometer_chart-container__WD3oZ`,
	"empty": `Speedometer_empty__5edww`,
	"fill": `Speedometer_fill__jndnv`,
	"cover": `Speedometer_cover__eL88V`
};
export default ___CSS_LOADER_EXPORT___;
