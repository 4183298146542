import {
  IconApi,
  IconBriefcase,
  IconInfoCircle,
  IconPower,
  IconTrash,
  IconUserCircle,
} from "@tabler/icons-react";
import { ComplexListProps } from "shared/ui/list";
import { Palette } from "@mui/material/styles";
import { IconButtonStack } from "shared/ui/components"
import { useLocale } from "entities/i18n";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { NewBadge } from "./NewBadge";

const actionsProps = (
  agency: Agency,
  i18n: ReturnType<typeof useLocale>["admin"],
  palette: Palette,
  navigate: ReturnType<typeof useNavigate>,
  isActive: boolean,
  index: number,
  dialogActionHandler: (action: DialogAction, index: number) => () => void
) => ({
  iconButtons: [
    {
      icon: IconBriefcase,
      onClick: () => navigate(`/admin/agencies/${agency.id}/clients`, { state: agency }),
      tooltip: i18n.agency.clients,
      iconProps: {
        color: palette.text.primary,
      },
    },
    {
      icon: IconUserCircle,
      onClick: () => navigate(`/admin/agencies/${agency.id}/users`, { state: agency }),
      tooltip: i18n.agency.users,
      iconProps: {
        color: palette.text.primary,
      },
    },
    {
      icon: IconApi,
      onClick: () => navigate(`/admin/agencies/${agency.id}/api`, { state: agency }),
      tooltip: i18n.agency.api,
      iconProps: {
        color: palette.text.primary,
      },
    },
    {
      icon: IconInfoCircle,
      onClick: () => navigate(`/admin/agencies/${agency.id}/info`, { state: agency }),
      tooltip: i18n.agency.info,
      iconProps: {
        color: palette.text.primary,
      },
    },
    {
      icon: IconPower,
      onClick: dialogActionHandler("deactivate", index),
      tooltip: i18n.agency[isActive ? "deactivate" : "activate"],
    },
    {
      icon: IconTrash,
      onClick: dialogActionHandler('delete', index),
      tooltip: i18n.agency.delete,
      iconProps: {
        color: palette.text.primary,
      },
    },
  ],
  iconProps: {
    size: 20,
    strokeWidth: 1.5,
    color: isActive ? palette.text.primary : palette.grey[400],
  },
  className: "gap-3 justify-end",
});

export const compileListData = (
  agencies: Agency[],
  i18n: ReturnType<typeof useLocale>["admin"],
  palette: Palette,
  navigate: ReturnType<typeof useNavigate>,
  dialogActionHandler: (action: DialogAction, index: number) => () => void,
  approveAgency: (id: number) => void
) => {
  const cols = [
    { name: "name", title: i18n.agency.name, specialColStyles: "w-[15rem]" },
    { name: "bin", title: i18n.BIN },
    { name: "responsible_name", title: i18n.responsiblePerson },
    { name: "responsible_email", title: i18n.email },
    { name: "responsible_phone", title: i18n.phone },
    { name: "actions", title: "" },
  ] as ComplexListProps["cols"];
  const rows = agencies.map((a, index) => {
    return {
      name: {
        content: (
          <>
            {a.confirmed || (
              <NewBadge />
            )}
            {a.name}
          </>
        ),
        specialCellStyles: (a.confirmed && a.is_active) || "text-grey-400",
      },
      bin: {
        content: a.bin,
        specialCellStyles:(a.confirmed && a.is_active) || "text-grey-400",
      },
      responsible_name: {
        content: a.responsible_full_name,
        specialCellStyles: (a.confirmed && a.is_active) || "text-grey-400",
      },
      responsible_email: {
        content: a.responsible_email,
        specialCellStyles: (a.confirmed && a.is_active) || "text-grey-400",
      },
      responsible_phone: {
        content: a.responsible_phone,
        specialCellStyles: (a.confirmed && a.is_active) || "text-grey-400",
      },
      actions: {
        content: (
          <div className="flex flex-row justify-end gap-2">
            {a.confirmed || (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                disableElevation
                className="text-white bg-primary normal-case inline-block"
                onClick={() => approveAgency(a.id)}
              >
                {i18n.agency.approve}
              </Button>
            )}
            <IconButtonStack
              {...actionsProps(
                a,
                i18n,
                palette,
                navigate,
                (a.confirmed && a.is_active),
                index,
                dialogActionHandler
              )}
            />
          </div>
        ),
      },
    };
  });
  return { cols, rows } as ComplexListProps;
};