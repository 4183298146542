import { createElement } from "react";
import { IconButton } from "@mui/material";
import { Icon } from "@tabler/icons-react";

type IconProps = {
  size: number | string;
  strokeWidth: number;
  color: string;
}

type Props = {
  iconButtons: {
    icon: Icon;
    onClick: () => void;
    tooltip?: string;
    iconProps?: Partial<IconProps>;
  }[];
  iconProps: IconProps;
  iconButtonClassName?: string;
  iconButtonSx?: React.CSSProperties;
  className?: string;
};

const IconButtonStack = (props: Props) => {
  return (
    <div className={`flex flex-row ${props.className ? props.className : ""}`}>
      {props.iconButtons.map((icon, index) => (
        <IconButton
          key={index}
          className={props.iconButtonClassName}
          sx={{p: 0, ...props.iconButtonSx}}
          onClick={icon.onClick}
          title={icon.tooltip}
        >
          {createElement(icon.icon, {
            size: props.iconProps.size,
            strokeWidth: props.iconProps.strokeWidth,
            color: props.iconProps.color,
            ...icon.iconProps
          })}
        </IconButton>
      ))}
    </div>
  );
};

export default IconButtonStack;
