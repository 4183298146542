import { useState } from "react";
import ReactPaginate from "react-paginate";

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { IconChevronCompactRight, IconChevronRight } from "@tabler/icons-react";
import { IconChevronLeft } from "@tabler/icons-react";

export const styles = {
  tableWrapper: "flex flex-start w-full overflow-x-scroll",
  tableCell:
    "font-inter px-2.5 py-[0.437rem] border-[#e1e4e8] whitespace-nowrap leading-none min-h-5",
  tableHeaderCell: "font-bold",
};

export type ComplexListProps = {
  cols: {
    name: string;
    title: React.ReactNode;
    specialColStyles?: string;
  }[];
  rows: {
    [key: string]: {
      content: React.ReactNode;
      specialCellStyles?: string;
      cellSx?: React.CSSProperties;
    };
  }[];
  className?: string;
};

export const ComplexList = ({ cols, rows, className }: ComplexListProps) => {
  return (
    <div className={className}>
      <Table>
        <TableHead>
          <TableRow>
            {cols.map((col, i) => (
              <TableCell
                sx={{ borderBottom: 1 }}
                className={`${styles.tableCell} ${styles.tableHeaderCell} ${col.specialColStyles}`}
                key={`${col.name}-${i}`}
              >
                {col.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              {cols.map((col) => {
                const colStyle = col.specialColStyles || "";
                const cellStyle = row[col.name].specialCellStyles || "";
                return (
                  row[col.name] && (
                    <TableCell
                      sx={row[col.name].cellSx}
                      className={`${styles.tableCell} ${cellStyle} ${colStyle}`}
                      key={col.name}
                    >
                      {row[col.name].content}
                    </TableCell>
                  )
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export const ClientPaginatedComplexList = ({
  rowsPerPage,
  data,
}: {
  rowsPerPage: number;
  data: ComplexListProps;
}) => {
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [rowsOffset, setRowsOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = rowsOffset + rowsPerPage;
  const currentRows = data.rows.slice(rowsOffset, endOffset);
  const pageCount = Math.ceil(data.rows.length / rowsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * rowsPerPage) % data.rows.length;
    setRowsOffset(newOffset);
  };

  const pageButtonStyles = `block box-content h-7 w-7 text-center
  cursor-pointer [&>*]:block [&>*]:h-7 [&>*]:leading-7
  border-grey-300 border-[1px] border-solid rounded-[25%] aspect-square list-none`;

  return (
    <div className="flex flex-col h-full justify-between flex-auto">
      <ComplexList cols={data.cols} rows={currentRows} className="" />
      <ReactPaginate
        breakLabel="..."
        nextLabel=" >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< "
        renderOnZeroPageCount={null}
        //! old styles
        // className="flex justify-center list-none gap-3 h-10 [&>*]:w-10"
        // pageClassName={pageButtonStyles}
        // previousClassName={pageButtonStyles}
        // nextClassName={pageButtonStyles}
        // activeClassName="text-white hover:bg-primary text-bold bg-primary"
        className="flex justify-center gap-3"
        pageClassName={pageButtonStyles}
        previousClassName={pageButtonStyles}
        nextClassName={pageButtonStyles}
        breakClassName="block list-none"
        activeClassName="font-bold border-primary bg-grey-50"
      />
    </div>
  );
};

export const ServerPaginatedComplexList = (
  props: {
    data: ComplexListProps;
    pages: number;
    rowsPerPage: number;
    handlePageChange: (page: number) => void;  
    totalRows: number
  }
) => {
  const [rowsOffset, setRowsOffset] = useState(0);

  const handlePageClick = (event: { selected: number }) => {
    props.handlePageChange(event.selected);
    setRowsOffset(event.selected * props.rowsPerPage);
  };

  const pageButtonStyles = `block box-content h-7 w-7 text-center
  cursor-pointer [&>*]:block [&>*]:h-7 [&>*]:leading-7
  border-grey-300 border-[1px] border-solid rounded-[25%] aspect-square list-none`;
  return (
    <>
      <ComplexList {...props.data} />
      <ReactPaginate
        breakLabel=" ... "
        nextLabel={<IconChevronRight size={20} className="mt-1" />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={props.pages}
        previousLabel={<IconChevronLeft size={20} className="mt-1" />}
        renderOnZeroPageCount={null}
        className="flex justify-center gap-3"
        pageClassName={pageButtonStyles}
        previousClassName={pageButtonStyles}
        nextClassName={pageButtonStyles}
        breakClassName="block list-none"
        activeClassName="font-bold border-primary bg-grey-50"
      />
      <div className="text-center pb-4">
        Показываем строчки с {rowsOffset} по{" "}
        {rowsOffset + props.rowsPerPage > props.totalRows
          ? props.totalRows
          : rowsOffset + props.rowsPerPage}
      </div>
    </>
  );
};
