import SuperAdminApi, {
  useCreateSystemAdminQuery, 
  useLazyCreateSystemAdminQuery,
  useReadSystemAdminsQuery,
  useLazyReadSystemAdminsQuery,
  useUpdateSystemAdminQuery,
  useLazyUpdateSystemAdminQuery,
  useDeleteSystemAdminQuery,
  useLazyDeleteSystemAdminQuery,
  useEditUserQuery,
  useLazyEditUserQuery
} from "./superAdminApi";

export {
  SuperAdminApi,
  useCreateSystemAdminQuery, 
  useLazyCreateSystemAdminQuery,
  useReadSystemAdminsQuery,
  useLazyReadSystemAdminsQuery,
  useUpdateSystemAdminQuery,
  useLazyUpdateSystemAdminQuery,
  useDeleteSystemAdminQuery,
  useLazyDeleteSystemAdminQuery,
  useEditUserQuery,
  useLazyEditUserQuery
}