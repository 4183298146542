import { useState, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import { useLocale } from "entities/i18n";
import { Button } from "@mui/material";
import { IconSearch, IconEdit, IconTrash } from "@tabler/icons-react";
import { IconButtonStack } from "shared/ui/components";
import { TextInput } from "shared/ui/form";
import { Dialog } from "shared/ui/components";
import { Formik } from "formik";
import * as Yup from "yup";
import { PaginatedComplexList } from "shared/ui/list";
import {
  useCreateBrandMutation,
  useDeleteBrandMutation,
  useReadBrandsQuery,
  useUpdateBrandMutation,
} from "entities/clients";

export const ClientBrandsList = (props: ClientWidgetProps) => {
  const { admin: i18n, core: i18nCore } = useLocale();
  const { palette } = useTheme();

  const cols = [
    {
      name: "name",
      title: i18n.clients.brand,
    },
    {
      name: "id",
      title: i18n.clients.id,
    },
    { name: "actions", title: "" },
  ];

  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState<Brand>();
  const [selectedAction, setSelectedAction] = useState<
    "create" | "edit" | "delete"
  >("create");

  const handleAdd = () => {
    setSelectedAction("create");
    setDialogVisible(!dialogVisible);
  };

  const handleEdit = (index: number) => {
    setSelectedBrand(brandsQuery.data!.result[index]);
    setSelectedAction("edit");
    setDialogVisible(!dialogVisible);
  };

  const handleDelete = (index: number) => {
    setSelectedBrand(brandsQuery.data!.result[index]);
    setSelectedAction("delete");
    setDialogVisible(!dialogVisible);
  };

  const actionsProps = (
    i18n: ReturnType<typeof useLocale>["admin"],
    index: number
  ) => ({
    iconButtons: [
      {
        icon: IconEdit,
        tooltip: i18n.clients.edit,
        onClick: () => {
          handleEdit(index);
        },
      },
      {
        icon: IconTrash,
        tooltip: i18n.clients.delete,
        onClick: () => {
          handleDelete(index);
        },
      },
    ],
    iconProps: {
      size: 20,
      strokeWidth: 1.5,
      color: palette.text.primary,
    },
    className: "gap-3 justify-end",
  });

  const [nameFilter, setNameFilter] = useState<string>("");
  const [apiCallTimeout, setApiCallTimeout] = useState<NodeJS.Timeout | null>();

  const brandsQuery = useReadBrandsQuery(
    { client_id: props.clientId, name: nameFilter || undefined },
    { skip: !props.clientId }
  );

  const rows = useMemo(
    () =>
      brandsQuery.data?.result.map((brand, index) => ({
        name: { content: brand.name },
        id: { content: brand.id },
        actions: {
          content: <IconButtonStack {...actionsProps(i18n, index)} />,
        },
      })) || [],
    [brandsQuery.data, i18n]
  );

  const handleRefetch = () => {
    apiCallTimeout && clearTimeout(apiCallTimeout);
    setApiCallTimeout(
      setTimeout(async () => {
        brandsQuery.isUninitialized || (await brandsQuery.refetch());
      }, 200)
    );
  };

  const [createBrand, createBrandStatus] = useCreateBrandMutation();
  const [updateBrand, updateBrandStatus] = useUpdateBrandMutation();
  const [deleteBrand, deleteBrandStatus] = useDeleteBrandMutation();

  return (
    <>
      <div className="flex flex-row justify-between h-10 mt-5 mb-3">
        <TextInput
          id="search"
          placeholder={i18n.clients.brandName}
          icon={<IconSearch size={16} color={palette.grey[400]} />}
          className="w-[15rem]"
          onChange={(e) => {
            setNameFilter(e.target.value);
            handleRefetch();
          }}
        />
        <Button
          variant="contained"
          color="primary"
          size="large"
          className="bg-primary text-white normal-case"
          onClick={handleAdd}
        >
          {i18nCore.add}
        </Button>
      </div>
      <PaginatedComplexList.client data={{ cols, rows }} rowsPerPage={10} />
      {dialogVisible && (
        <Dialog
          toggler={() => {
            setDialogVisible(!dialogVisible);
          }}
        >
          <Formik
            initialValues={{
              name:
                selectedAction === "create" ? "" : selectedBrand?.name || "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().max(255).required(),
            })}
            onSubmit={async (values) => {
              switch (selectedAction) {
                case "create":
                  await createBrand({
                    client_id: props.clientId,
                    name: values.name,
                  });
                  setDialogVisible(!dialogVisible);
                  handleRefetch();
                  break;
                case "edit":
                  await updateBrand({
                    id: selectedBrand!.id,
                    client_id: props.clientId,
                    name: values.name,
                  });
                  setDialogVisible(!dialogVisible);
                  handleRefetch();
                  break;
                case "delete":
                  await deleteBrand({ brand_id: selectedBrand!.id });
                  setDialogVisible(!dialogVisible);
                  handleRefetch();
                  break;
              }
            }}
          >
            {({ handleSubmit, handleChange, values, errors, touched }) => (
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-4">
                  {selectedAction !== "delete" && (
                    <TextInput
                      id="name"
                      label={i18n.clients.brandName}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleChange}
                      helperText={touched.name ? errors.name : ""}
                      sx={{
                        input: {
                          backgroundColor: palette.grey[100],
                          borderBottom: "1px solid " + palette.primary.main,
                        },
                      }}
                      fullWidth
                      autoFocus
                    />
                  )}
                  {selectedAction === "delete" && (
                    <>
                      <div className="text-center">
                        {i18n.clients.brandDeletion}
                      </div>
                      <div className="font-bold text-center mb-2">
                        {selectedBrand?.name}
                      </div>
                    </>
                  )}
                  <div className="flex flex-row justify-end gap-4">
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      className="bg-primary text-white normal-case"
                      type="submit"
                    >
                      {(() => {
                        switch (selectedAction) {
                          case "create":
                            return i18nCore.add;
                          case "edit":
                            return i18nCore.save;
                          case "delete":
                            return i18nCore.delete;
                        }
                      })()}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      className="bg-primary text-white normal-case"
                      onClick={() => {
                        setDialogVisible(!dialogVisible);
                      }}
                    >
                      {i18nCore.cancel}
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </Dialog>
      )}
    </>
  );
};
