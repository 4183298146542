import { ApexOptions } from "apexcharts";
import { useRef } from "react";
import ReactApexChart from "react-apexcharts";
import useChartLegendHelper from "./useChartLegendHelper";
import useChartWidthResizer from "./useChartWidthResizer";

// Здесь конфиг графика.
// То, что передаётся в пропсах, перезаписывает дефолтные значения.
const defaultData = {
  type: "bar" as "bar",
  series: [] as ApexAxisChartSeries | ApexNonAxisChartSeries,
  options: {
    chart: {
      type: "bar",
      height: "100%",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: 16,
        endingShape: "rounded",
        dataLabels: {
          orientation: "vertical",
          position: "center",
          formatter: (val: string) => val + " KZT",
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
        fontFamily: "Inter",
        fontWeight: 400,
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      type: "category",
      categories: [],
      labels: {
        show: true,
        rotate: 270,
        rotateAlways: true,
        style: {
          fontSize: "10px",
          fontFamily: "Inter",
          fontWeight: 700,
        },
      },
    },
    yaxis: {
      show: false,
      labels: {
        show: false,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " KZT";
        },
      },
    },
    legend: {
      markers: {
        radius: 0,
      },
    },
  } as ApexOptions,
};

type BarChartProps = {
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  xAxis: string[];
  dataLabels?: boolean;
  formatter?: (val: any) => string;
  yAxis?: ApexYAxis;
  className?: string;
};

const BarChart = (props: BarChartProps) => {
  // Реф на div, в котором отображается график.
  // Используется далее как аргумент в хуках.
  const chartRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

  // Хук для отображения легенды графика.
  // Переносит легенду в отдельный div, не перемещающийся при скролле.
  useChartLegendHelper(chartRef);
  const chartWidth = useChartWidthResizer(props.xAxis, chartRef);

  // Перезаписываем дефолтные значения на основе props.
  // IIFE для того, чтобы не мутировать дефолтные значения.
  const data = (() => {
    let data = defaultData;
    data.series = props.series;
    data.options.xaxis = {
      type: "category",
      categories: props.xAxis,
      labels: {
        show: true,
        rotate: 270,
        rotateAlways: true,
        style: {
          fontSize: "10px",
          fontFamily: "Inter",
          fontWeight: 700,
        },
      },
    } as ApexXAxis;
    data.options.dataLabels = { enabled: props.dataLabels };
    data.options.yaxis = props.yAxis || data.options.yaxis;
    return data;
  })();

  return (
    <div
      ref={chartRef}
      className={`wrapper relative overflow-x-auto overflow-y-hidden h-[calc(100%-1rem)] h-min-[15rem] pb-4 ${
        props.className ? props.className : ""
      }`}
    >
      <ReactApexChart {...data} width={chartWidth} height="100%" />
    </div>
  );
};

export default BarChart;
