import { useLocale, useLocaleSlice, useFetchLocale, useCurrentLocale } from "./useLocale";
import { localeSlice, LocaleReducers } from "./localeSlice";
import { fetchLocale } from "./fetchLocale";

export {
  localeSlice,
  useLocale,
  useLocaleSlice,
  useFetchLocale,
  useCurrentLocale,
  type LocaleReducers,
  fetchLocale
};