import MuiIconButton from "@mui/material/IconButton";
import { IconArrowLeft } from "@tabler/icons-react";
import { useNavigate, useParams } from "react-router-dom";

import { CommonLayout } from "shared/ui";
import { CreateApiForm } from "widgets/clientsApis/CreateApiForm";

const iconProps = {
  color: "black",
  size: "20",
  strokeWidth: "1.5",
};

const i18nLoc = {
  createApi: "Create API",
};

export const CreateApi = () => {
  const navigate = useNavigate();
  const { agencyId, clientId } = useParams();
  const handleCancel = () => navigate(-1);

  return (
    <CommonLayout
      title={
        <div className="inline-flex flex-row items-center">
          <MuiIconButton
            aria-label="go back"
            edge="start"
            onClick={handleCancel}
          >
            <IconArrowLeft {...iconProps} />
          </MuiIconButton>
          <h1 className="block my-0 ml-4 leading-normal text-[2.25rem]">
            {i18nLoc.createApi}
          </h1>
        </div>
      }
    >
      <CreateApiForm
        agencyId={parseInt(agencyId as unknown as string)}
        clientId={parseInt(clientId as unknown as string)}
      />
    </CommonLayout>
  );
};
