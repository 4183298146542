import { useRef, useEffect, MouseEventHandler, useState } from "react";
import { Button, Stack, FormHelperText } from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";

import { TextInput } from "shared/ui/form";
import { getByErrorCode } from "shared/lib";
import { useLocale } from "entities/i18n";
import {
  useCreateClientMutation,
  useReadClientsByBinQuery,
} from "entities/clients";
import { useAgency } from "entities/admin";
import { useParams, useNavigate } from "react-router-dom";

export const CreateClient = ({
  handleCancel,
}: {
  handleCancel: MouseEventHandler;
}) => {
  const i18nGlobal = useLocale();
  const i18n = i18nGlobal.admin;
  const i18nErrors = i18nGlobal.errors;
  const { agencyId } = useAgency();
  const scriptedRef = useRef(true);

  const navigate = useNavigate();

  const { id: paramsAgencyId } = useParams();
  
  const [bin, setBin] = useState<string>("");
  const [debouncedBin, setDebouncedBin] = useState<string>("");
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedBin(bin);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [bin]);

  const [client, setClient] = useState<{ name: string; bin: string }>();
  const [query, queryState] = useCreateClientMutation();
  useEffect(() => {
    queryState.data?.status_code === 0 && setTimeout(
      () => navigate(-1), 1000
    );
  }, [queryState.data]);

  const clients = useReadClientsByBinQuery(
    { bin: debouncedBin, size: 10 },
  );

  // При изменении БИН, смотрим, нет ли такого у кого-нибудь из клиентов
  const handleSetClient = (binToSelect: { bin: string; name?: string }) => {
    clients.data &&
      setClient(
        clients.data.result.items.find(
          (client) => client.bin === binToSelect.bin
        )
      );
  };

  useEffect(() => {
    clients.isUninitialized || clients.refetch();
  }, [debouncedBin]);

  useEffect(() => {
    handleSetClient({ bin });
  }, [clients.data]);

  useEffect(
    () => () => {
      scriptedRef.current = false;
    },
    []
  );

  const initialValues: Omit<RequestCreateClient, "agency_id"> & {
    submit: any;
  } = {
    name: "",
    bin: "",
    submit: null,
  };

  const latestValues = useRef(initialValues);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(i18nErrors.form.fieldRequired).max(255),
        bin: Yup.string().required(i18nErrors.form.fieldRequired).max(255),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        if (JSON.stringify(latestValues.current) !== JSON.stringify(values)) {
          try {
            paramsAgencyId ? (await query({
              name: values.name,
              bin: values.bin,
              agency_id: Number(paramsAgencyId)
            })) :
              agencyId &&
              (await query({
                name: values.name,
                bin: values.bin,
                agency_id: agencyId
              }));
            if (scriptedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
          latestValues.current = values;
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} className="p-8">
          <div className="flex flex-col gap-8">
            <div className="w-1/2">
              <TextInput
                disabled={client ? true : false}
                fullWidth
                id="name"
                label={i18n.clientName}
                placeholder={i18n.clientNamePlaceholder}
                onChange={handleChange}
                onBlur={handleBlur}
                value={client ? client.name : values.name}
              />
              {touched.name && errors.name && (
                <FormHelperText error>{errors.name}</FormHelperText>
              )}
            </div>
            <div className="w-1/2">
              <TextInput
                fullWidth
                id="bin"
                label={i18n.BIN}
                placeholder={i18n.BINPlaceholder}
                onChange={(e) => {
                  handleChange(e);
                  setBin(e.target.value.trim());
                  handleSetClient({ bin: e.target.value.trim() });
                }}
                onPaste={() => {clients.isUninitialized || clients.refetch();}}
                onBlur={handleBlur}

                //! Если раскомментить эту секцию, то появится автокомплит
                /* autocomplete={{
                  options: clients.data
                    ? clients.data.result.items.map((client) => ({
                      bin: client.bin,
                      name: client.name,
                    }))
                    : [],
                  getLabel: (option) => option.bin,
                  renderOption: (props, option) => (
                    <Box
                      component="li"
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: ".5rem",
                      }}
                      {...props}
                    >
                      <span className="mr-2 block">{option.bin}</span>
                      <b className="block">{option.name}</b>
                    </Box>
                  ),
                  onChange: (e, value) => {
                    setBin(value); handleSetClient(value);
                  }
                }} */
              />
              {touched.bin && errors.bin && (
                <FormHelperText error>{errors.bin}</FormHelperText>
              )}
              {errors.submit && (
                <FormHelperText error>{errors.submit as string}</FormHelperText>
              )}
              {isSubmitting && (
                <FormHelperText>{i18n.submitting}</FormHelperText>
              )}
            </div>
            <Stack direction="row" spacing="2rem" width="20rem">
              <Button
                disableElevation
                type="submit"
                disabled={queryState.isLoading || isSubmitting}
                fullWidth
                size="large"
                variant="contained"
                className="bg-primary text-white normal-case"
              >
                {i18n.add}
              </Button>

              <Button
                disableElevation
                disabled={false}
                fullWidth
                size="large"
                variant="outlined"
                className="bg-primary text-white normal-case"
                onClick={handleCancel}
              >
                {i18n.cancel}
              </Button>
            </Stack>
            <span>
              {queryState.data &&
                queryState.data.status_code !== 0 &&
                i18nErrors[getByErrorCode(queryState.data.status_code)]}
              {queryState.data &&
                queryState.data.status_code === 0 &&
                i18n.clients.createSuccess}
            </span>
          </div>
        </form>
      )}
    </Formik>
  );
};
