import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IconSearch } from "@tabler/icons-react";

import {
  useDeleteClientMutation,
  useReadClientsByAgencyWithApiQuery,
  useUpdateClientStatusMutation,
} from "entities/clients";
import { useAgency } from "entities/admin";
import { useLocale } from "entities/i18n";

import { PaginatedComplexList } from "shared/ui/list";
import { Dialog } from "shared/ui/components";
import { TextInput } from "shared/ui/form";

import { compileListData } from "./compileListData";

/**
 * @description Компонент для отображения списка клиентов. Включает в себя фильтрацию по имени и БИНу
 */
export const ClientsList = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const { agencyId } = useAgency();
  const i18n = useLocale().admin;

  const [dialogVisible, setDialogVisible] = useState(false);
  const dialogToggler = () => setDialogVisible(!dialogVisible);
  const [selectedAction, setSelectedAction] = useState<DialogAction>("delete");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [nameFilter, setNameFilter] = useState<string>("");
  const [binFilter, setBinFilter] = useState<string>("");

  const [page, setPage] = useState(1);

  const [updateStatusMutation] = useUpdateClientStatusMutation();
  const [deleteMutation] = useDeleteClientMutation();
  // const readAllQuery = useReadClientsByAgencyQuery(
  const readAllQuery = useReadClientsByAgencyWithApiQuery(
    {
      agency_id: agencyId as number,
      name: nameFilter,
      bin: binFilter,
      page,
      size: 15,
    },
    { skip: !agencyId },
  );

  // В этом месте используется setTimeout, чтобы не делать слишком много запросов
  // В state хранится id таймера, чтобы при каждом изменении фильтра мы могли его отменить, и поставить новый
  // Если пользователь вводит текст, то мы не будем делать запросы на сервер, пока он не закончит ввод

  // Также timeout перезаписывается при деактивации пользователя и удалении пользователя
  // Эти операции требуют времени на сервере, поэтому смена статуса сразу меняет цвет строки (optimistic update)
  // И только потом делается запрос на сервер, чтобы обновить данные до актуальных

  const [apiCallTimeout, setApiCallTimeout] = useState<
    ReturnType<
      typeof setTimeout
    > | null
  >(null);

  useEffect(() => {
    if (location.state) {
      setApiCallTimeout(
        setTimeout(() => {
          readAllQuery.isUninitialized || readAllQuery.refetch();
        }, 1000),
      );
    }
  }, []);

  const dialogOpenHandler = (action: DialogAction, index: number) => () => {
    setSelectedAction(action);
    setSelectedIndex(index);
    setDialogVisible(true);
  };

  // activationOverride - массив индексов, которые нужно перезаписать
  // Если пользователь нажал на кнопку деактивации, то мы сразу меняем цвет строки
  // Здесь хранятся индексы этих строк
  // Когда приходит ответ от сервера, мы обновляем данные, и делаем массив пустым
  const [activationOverride, setActivationOverride] = useState<number[]>([]);

  const dialogActionHandler = () => {
    switch (selectedAction) {
      case "deactivate":
        return async () => {
          const isActive = readAllQuery.data?.result[selectedIndex].is_active;
          readAllQuery.data &&
            agencyId &&
            updateStatusMutation({
              client_id: readAllQuery.data?.result[selectedIndex].id,
              is_active: !isActive,
              agency_id: agencyId,
            }).then((res) => {
              (res as { data: ResponseCommon }).data.status_code === 0 &&
                (() => {
                  setActivationOverride([...activationOverride, selectedIndex]);
                  apiCallTimeout && clearTimeout(apiCallTimeout);
                  setApiCallTimeout(
                    setTimeout(async () => {
                      await readAllQuery.refetch();
                      setActivationOverride([]);
                    }, 2000),
                  );
                })();
            });
          dialogToggler();
        };
      case "delete":
        return async () => {
          readAllQuery.data &&
            agencyId &&
            deleteMutation({
              client_id: readAllQuery.data?.result[selectedIndex].id,
              agency_id: agencyId,
            }).then((res) => {
              (res as { data: ResponseCommon }).data.status_code === 0 &&
                (() => {
                  apiCallTimeout && clearTimeout(apiCallTimeout);
                  setApiCallTimeout(
                    setTimeout(async () => {
                      await readAllQuery.refetch();
                    }, 1000),
                  );
                })();
              dialogToggler();
            });
        };
    }
  };

  const listState = useMemo(() => {
    if (readAllQuery.data?.result) {
      let activated = readAllQuery.data.result.map((item) => item.is_active);
      activationOverride.forEach((value) => {
        activated[value] = !activated[value];
      });
      return compileListData(
        i18n,
        // readAllQuery.data.result.items,
        readAllQuery.data.result,
        dialogOpenHandler,
        palette,
        activated,
        navigate,
      );
    }
    if (readAllQuery.isLoading) return "loading";
    if (readAllQuery.error) {
      console.error("readAllQuery.error", readAllQuery.error);
      return "error";
    }
    return "";
  }, [readAllQuery, i18n, palette, activationOverride]);

  return (
    <>
      <div className="flex flex-row gap-[2rem]">
        <TextInput
          id="search"
          placeholder={i18n.organizationName}
          icon={<IconSearch size={16} color={palette.grey[400]} />}
          className="mt-8 mb-3 w-[15rem]"
          onChange={(e) => {
            setNameFilter(e.target.value);
            apiCallTimeout && clearTimeout(apiCallTimeout);
            setApiCallTimeout(
              setTimeout(async () => {
                readAllQuery.isUninitialized || (await readAllQuery.refetch());
              }, 1500),
            );
          }}
        />
        <TextInput
          id="search"
          placeholder={i18n.BIN}
          icon={<IconSearch size={16} color={palette.grey[400]} />}
          className="mt-8 mb-3"
          onChange={(e) => {
            setBinFilter(e.target.value);
            apiCallTimeout && clearTimeout(apiCallTimeout);
            setApiCallTimeout(
              setTimeout(async () => {
                readAllQuery.isUninitialized || (await readAllQuery.refetch());
              }, 1500),
            );
          }}
        />
      </div>
      {typeof listState === "string"
        ? listState
        : readAllQuery.data
          ? <PaginatedComplexList.client data={listState} rowsPerPage={15} />
          : null}
      {dialogVisible && (
        <Dialog toggler={dialogToggler}>
          <div className="flex flex-col items-center">
            <div>
              {selectedAction == "deactivate" &&
                (readAllQuery.data?.result[selectedIndex].is_active
                  ? i18n.clients.deactivation
                  : i18n.clients.activation)}
              {selectedAction == "delete" && i18n.clients.deletion}
            </div>
            <div className="font-bold">
              {typeof listState !== "string"
                ? listState.rows[selectedIndex].name.content
                : ""}
            </div>
            <div>
              <span className="font-bold">{i18n.BIN}:</span>
              {typeof listState !== "string"
                ? listState.rows[selectedIndex].bin.content
                : ""}
            </div>
            <div className="flex flex-row justify-center gap-4 mt-4">
              <Button
                disableElevation
                size="large"
                variant="contained"
                className="bg-primary text-white normal-case"
                //eslint-disable-next-line
                onClick={dialogActionHandler()}
              >
                {selectedAction === "deactivate" &&
                  (readAllQuery.data?.result[selectedIndex].is_active
                    ? i18n.deactivate
                    : i18n.activate)}
                {selectedAction === "delete" && i18n.delete}
              </Button>
              <Button
                disableElevation
                size="large"
                variant="contained"
                className="bg-primary text-white normal-case"
                onClick={dialogToggler}
              >
                {i18n.cancel}
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};
