export const toggleMemory = (name: string, item: string | null): void => {
  if (item) {
    setMemory(name, item);
    return
  }
  deleteMemory(name);
};

export const setMemory = (name: string, item: string): void => {
  localStorage.setItem(name, item);
};

export const deleteMemory = (name: string): void => {
  localStorage.removeItem(name);
};

export const getMemory = (name: string): string | null => {
  return localStorage.getItem(name);
};