import MuiIconButton from "@mui/material/IconButton" 
import { IconArrowLeft } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useLocale } from "entities/i18n";
import { CommonLayout } from "shared/ui";
import { EditSystemAdmin } from "features/superAdmin";

const iconProps = {
  color: "black",
  size: "20",
  strokeWidth: "1.5",
};

const SystemAdminEdit = () => {
  const i18n = useLocale().superAdmin;
  const navigate = useNavigate();
  const handleCancel = () => navigate("/system-admin");

  return (
    <CommonLayout
      title={
        <div className="inline-flex flex-row items-center">
          <MuiIconButton
            aria-label="go back"
            edge="start"
            onClick={handleCancel}
          >
            <IconArrowLeft {...iconProps} />
          </MuiIconButton>
          <h1 className="block my-0 ml-4 leading-normal text-[2.25rem]">
            {i18n.editAdmin}
          </h1>
        </div>
      }
    >
      <EditSystemAdmin />
    </CommonLayout>
  )
};

export default SystemAdminEdit;
