import { useLocation, useParams, useNavigate } from "react-router-dom";
import { CommonLayout } from "shared/ui";
import { IconButton as MuiIconButton } from "@mui/material";
import { IconArrowLeft } from "@tabler/icons-react";
import { useLocale } from "entities/i18n";
import { EditClient as EditClientForm } from "features/clients";

const iconProps = {
  color: "black",
  size: "20",
  strokeWidth: "1.5",
};

export const EditClient = () => {
  const location = useLocation();
  const params = useParams();
  const clientId = location.state.id
    ? location.state.id
    : params.clientId ?? params.id;
  const navigate = useNavigate();
  // const handleCancel = () => navigate("/clients", { replace: true, state: { clientIdByDirector } });
  const handleCancel = () => navigate(-1);

  const i18n = useLocale().admin;

  return (
    <CommonLayout
      title={
        <div className="inline-flex flex-row items-center">
          <MuiIconButton
            aria-label="go back"
            edge="start"
            onClick={handleCancel}
          >
            <IconArrowLeft {...iconProps} />
          </MuiIconButton>
          <h1 className="block my-0 ml-4 leading-normal text-[2.25rem]">
            {i18n.clients.edit}
          </h1>
        </div>
      }
    >
      <EditClientForm
        handleCancel={handleCancel}
        id={clientId}
        agencyId={Number(params.agencyId)}
        client={location.state.client}
      />
    </CommonLayout>
  );
};
