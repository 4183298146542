import AuthLayout from './authLayout';
import AuthBackground from "./authBackground";
import Paralax from "./paralax";
import Footer from "./Footer";
import CommonLayout from './CommonLayout';
import Header from './Header';

export {
  AuthBackground,
  AuthLayout,
  Footer,
  CommonLayout,
  Header,
  Paralax
}