import {
  IconPower,
  IconSearch,
  IconSettings,
  IconTrash,
} from "@tabler/icons-react";
import {
  useDeleteAgencyUserMutation,
  useLazyReadUserByEmailQuery,
  useReadAgencyUsersQuery,
  useUpdateUserStatusMutation,
} from "entities/users";
import { useLocale } from "entities/i18n";
import {
  Dialog,
  IconButtonStack,
  useDashboardDialog,
} from "shared/ui/components";
import { ComplexList, ComplexListProps } from "shared/ui/list";
import { useEffect, useMemo, useState } from "react";
import { Button, Palette } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { TextInput } from "shared/ui/form";
import { useNavigate } from "react-router-dom";
import { User } from "shared/types";

type Props = {
  agencyId: number;
  userRole: "admin" | "director"
};

const compileUsersForList = (
  users: AgencyUser[],
  i18n: ReturnType<typeof useLocale>["agencyUsers"],
  dialogOpenHandler: (action: string, index: number) => () => void,
  palette: Palette,
  navigate: ReturnType<typeof useNavigate>,
  agencyId: number,
  userRole: Props["userRole"]
) => {
  const actionsProps = (
    i18n: ReturnType<typeof useLocale>["agencyUsers"],
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    index: number,
    dialogOpenHandler: (action: string, index: number) => () => void
  ) => ({
    iconButtons: [
      {
        icon: IconSettings,
        onClick: () =>
          navigate(
            `${
              userRole === "admin" ? `/admin/agencies/${agencyId}` : ""
            }/users/manage`,
            {
              state: {
                user: users[index],
                agencyId: agencyId,
              },
            }
          ),
        tooltip: i18n.manage,
      },
      {
        icon: IconPower,
        onClick: dialogOpenHandler("deactivate", index),
        tooltip: users[index].is_active ? i18n.deactivate : i18n.activate,
        iconProps: {
          color: palette.text.primary,
        },
      },
      {
        icon: IconTrash,
        onClick: dialogOpenHandler("delete", index),
        tooltip: i18n.delete,
      },
    ],
    iconProps: {
      size: 20,
      strokeWidth: 1.5,
      color: users[index].is_active ? palette.text.primary : palette.grey[400],
    },
    className: "gap-3 justify-end",
  });

  const cols = [
    {
      name: "name",
      title: "Имя Фамилия",
    },
    {
      name: "role",
      title: "Роль",
    },
    {
      name: "client",
      title: "Клиент",
    },
    {
      name: "actions",
      title: "",
    },
  ];

  const rows: ComplexListProps["rows"] = users.map((user, index) => {
    return {
      name: {
        content: (
          <span className={user.is_active ? "text-black" : "text-grey-400"}>
            {user.full_name}
          </span>
        ),
      },
      role: {
        content: (
          <span className={user.is_active ? "text-black" : "text-grey-400"}>
            {user.roles
              .reduce((acc, curr) => acc + curr.name + ", ", "")
              .slice(0, -2)}
          </span>
        ),
      },
      client: {
        content: (
          user.roles[0].code !== 'AGENCY_DIRECTOR' ? <span className={user.is_active ? "text-black" : "text-grey-400"}>
            {user.clients.length > 0
              ? user.clients
                .reduce((acc, curr) => acc + curr.name + ", ", "")
                .slice(0, -2)
              : i18n.noUserClients}
          </span> : undefined
        ),
      },
      actions: {
        content: user.roles[0].code !==  'AGENCY_DIRECTOR' ? (
          <IconButtonStack {...actionsProps(i18n, index, dialogOpenHandler)} />
        ) : (() => {
          const actions = actionsProps(i18n, index, dialogOpenHandler);
          actions.iconButtons = actions.iconButtons.slice(0, 1);
          return <IconButtonStack {...actions} />
        })(),
      },
    } as ComplexListProps["rows"][number];
  });

  return {
    cols,
    rows,
  } as ComplexListProps;
};

export const AgencyUsersList = ({ agencyId, userRole }: Props) => {
  const { agencyUsers: i18n, core: i18nCore } = useLocale();
  const { palette } = useTheme();
  const navigate = useNavigate();

  const {
    dialogVisible,
    dialogToggler,
    action: selectedAction,
    index: selectedIndex,
  } = useDashboardDialog();

  const dialogOpenHandler = (action: string, index: number) => () => {
    selectedAction.set(action as DialogAction);
    selectedIndex.set(index);
    dialogToggler();
  };

  const [nameFilter, setNameFilter] = useState<string>("");
  const [clientFilter, setClientFilter] = useState<string>("");

  const {
    data: usersResponse,
    refetch,
    isUninitialized,
  } = useReadAgencyUsersQuery(
    {
      agency_id: agencyId,
      full_name: nameFilter,
      client_name: clientFilter,
    },
    { refetchOnMountOrArgChange: false }
  );

  useEffect(() => {
    !isUninitialized && refetch();
  }, []);

  const users = useMemo(
    () => usersResponse?.result.items || [],
    [usersResponse]
  );
  const { rows, cols } = useMemo(
    () =>
      compileUsersForList(
        users,
        i18n,
        dialogOpenHandler,
        palette,
        navigate,
        agencyId,
        userRole
      ),
    [users, i18n]
  );

  const [apiCallTimeout, setApiCallTimeout] = useState<NodeJS.Timeout>();

  const [queryByEmail, { data: userByEmail }] = useLazyReadUserByEmailQuery();

  const [deleteUser, { data: dataDeleteUser }] = useDeleteAgencyUserMutation();
  const [updateUserStatus, { data: dataUpdateUserStatus }] =
    useUpdateUserStatusMutation();

  const handleRefetchUsers = () => {
    clearTimeout(apiCallTimeout);
    setApiCallTimeout(setTimeout(() => !isUninitialized && refetch(), 500));
  };

  const handleMutation = (action: string, index: number) => {
    if (action === "deactivate") {
      userRole === "admin";
      /*         ? queryByEmail({ email: users[index].email })
          .unwrap()
          .then((res) => {
            updateUserStatus({
              user_id: res.result.id,
              is_active: !users[index].is_active,
            });
          })
          .finally(handleRefetchUsers)
        :  */ updateUserStatus({
        user_id: users[index].id,
        is_active: !users[index].is_active,
      }).finally(handleRefetchUsers);
    } else if (action === "delete") {
      /*       userRole === "admin"
        ? queryByEmail({ email: users[index].email })
          .unwrap()
          .then((res) => {
            deleteUser({ user_id: res.result.id, agency_id: agencyId });
          })
          .finally(handleRefetchUsers)
        :  */ deleteUser({
        user_id: users[index].id,
        agency_id: agencyId,
      }).finally(handleRefetchUsers);
    }
    dialogToggler();
  };

  return (
    <>
      <div className="flex flex-row gap-[2rem]">
        <TextInput
          id="search"
          placeholder={i18n.fullNamePlaceHolder}
          icon={<IconSearch size={16} color={palette.grey[400]} />}
          className="mt-8 mb-3 w-[15rem]"
          onChange={(e) => {
            setNameFilter(e.target.value);
            handleRefetchUsers();
          }}
        />
        <TextInput
          id="search"
          placeholder={i18n.userClients}
          icon={<IconSearch size={16} color={palette.grey[400]} />}
          className="mt-8 mb-3"
          onChange={(e) => {
            setClientFilter(e.target.value);
            handleRefetchUsers();
          }}
        />
      </div>
      <ComplexList cols={cols} rows={rows} />
      {dialogVisible && (
        <Dialog toggler={dialogToggler}>
          <div className="flex flex-col items-center">
            <div>
              {/* {action.get() == "deactivate" &&
                (users[index.get()].activated
                  ? i18n.deactivation
                  : i18n.activation)} */}
              {selectedAction.get() === "deactivate" &&
                (users[selectedIndex.get()].is_active
                  ? i18n.deactivation
                  : i18n.activation)}
              {selectedAction.get() === "delete" && i18n.deletion}
            </div>
            <div className="font-bold">
              {rows && rows[selectedIndex.get()].name.content}
            </div>
            <div>
              <span className="font-bold">{i18n.userClients} </span>
              {rows
                ? rows[selectedIndex.get()].client.content
                : i18n.noUserClients}
            </div>
            <div className="flex flex-row justify-center gap-4 mt-4">
              <Button
                disableElevation
                size="large"
                variant="contained"
                className="bg-primary text-white normal-case"
                //eslint-disable-next-line
                onClick={() =>
                  handleMutation(selectedAction.get(), selectedIndex.get())
                }
              >
                {selectedAction.get() === "deactivate" &&
                  (users[selectedIndex.get()].is_active
                    ? i18nCore.deactivate
                    : i18nCore.activate)}
                {selectedAction.get() === "delete" && i18nCore.delete}
              </Button>
              <Button
                disableElevation
                size="large"
                variant="contained"
                className="bg-primary text-white normal-case"
                onClick={dialogToggler}
              >
                {i18nCore.cancel}
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};
