import { JSX, SuspenseProps, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthSelector } from "entities/auth";

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: SuspenseProps): JSX.Element => {
  const auth = useAuthSelector();
  const navigate = useNavigate();
  const location = useLocation();

  const loggeinPages = [
    "auth",
    "reset-password",
    "register",
    "register-success",
    "app-description",
    "privacy-policy",
    "user-agreement",
  ];

  useEffect(() => {
    const path = location.pathname.split("/").slice(1, 2).toString();
    if (auth.isInitialized && auth.isLoggedIn && loggeinPages.includes(path)) {
      navigate("/", { replace: true });
      return;
    }
    if (
      auth.isInitialized &&
      !auth.isLoggedIn &&
      !loggeinPages.includes(path)
    ) {
      navigate("/auth", { replace: true });
    }
    if (!auth.isLoggedIn && loggeinPages.includes(path)) {
      return;
    }
    if (!auth.isLoggedIn || !auth.user) {
      navigate("/auth", { replace: true });
    }
  }, [auth, navigate]);

  return children as JSX.Element;
};

export default AuthGuard;
