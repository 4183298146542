import { Palette } from "@mui/material";
import { useLocale } from "entities/i18n";
import { Link, useNavigate } from "react-router-dom";
import { IconPower, IconSettings, IconTrash } from "@tabler/icons-react";
import { IconButtonStack } from "shared/ui/components";
import { ComplexListProps } from "shared/ui/list";

type CompileListDataProps = {
  clients: ResponseReadClients["result"]["items"];
  i18n: ReturnType<typeof useLocale>["admin"];
  navigate: ReturnType<typeof useNavigate>;
  palette: Palette;
  dialogOpenHandler: (action: DialogAction, index: number) => () => void;
};

export const compileListData = ({
  clients,
  i18n,
  navigate,
  palette,
  dialogOpenHandler,
}: CompileListDataProps) => {
  const actionsProps = (
    i18n: ReturnType<typeof useLocale>["admin"],
    index: number,
    isActive: boolean,
  ) => ({
    iconButtons: [
      {
        icon: IconSettings,
        onClick: () =>
          navigate(`/admin/clients/${clients[index].id}`, {
            state: { client: clients[index] },
          }),
        tooltip: i18n.clients.info,
      },
      {
        icon: IconPower,
        onClick: dialogOpenHandler("deactivate", index),
        //* will change by ternary
        tooltip: i18n.clients[isActive ? "deactivate" : "activate"],
        iconProps: {
          color: palette.text.primary,
        },
      },
      {
        icon: IconTrash,
        onClick: dialogOpenHandler("delete", index),
        tooltip: i18n.clients.delete,
        iconProps: {
          color: palette.text.primary,
        },
      },
    ],
    iconProps: {
      size: 20,
      strokeWidth: 1.5,
      color: isActive ? palette.text.primary : palette.grey[400],
    },
    className: "gap-3 justify-end",
  });

  return clients.map((client, index) => {
    const isActive = client.is_active;
    return {
      name: {
        content: (
          <span className={client.is_active ? "text-black" : "text-grey-400"}>
            {client.name}
          </span>
        ),
      },
      bin: {
        content: (
          <span className={client.is_active ? "text-black" : "text-grey-400"}>
            {client.bin}
          </span>
        ),
      },
      agencies: {
        content: (
          <span className={client.is_active ? "text-black" : "text-grey-400"}>
            {client.agencies.length > 0 ? (
              <>
                {client.agencies.map((agency, i) => (
                  <>
                    <Link
                      to={`/admin/agencies/${agency.id}`}
                      className={
                        client.is_active ? "text-black" : "text-grey-400"
                      }
                    >
                      {agency.name}
                    </Link>
                    {i !== client.agencies.length - 1 && ", "}
                  </>
                ))}
              </>
            ) : (
              i18n.clients.noClientAgencies
            )}
          </span>
        ),
      },
      actions: {
        content: <IconButtonStack {...actionsProps(i18n, index, isActive)} />,
      },
    };
  }) as ComplexListProps["rows"];
};
