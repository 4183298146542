import { TableCell, TableRow } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

export type ComplexTableRowProps<T extends object> = {
  index: number;
  data: T;
  transformer: (stats: T, statsImmutable: Readonly<T>, rowIndex: number, setters: SettersType) => {
    [key: string]: React.ReactNode;
  };
  styles: {
    cell: string;
    row: string;
  };
  cols: {
    name: string;
    title: React.ReactNode;
    group?: string;
    specialCellStyles?: string;
  }[];
};

export function ComplexTableRow<T extends object>({
  index,
  data,
  cols,
  transformer,
  styles,
}: ComplexTableRowProps<T>) {

  const [rowData, setRowData] = useState(data);

  useEffect(() => {
    setRowData(data);
  }, [data]);

  const setters = useMemo(() => {
    const setters = {} as SettersType;
    setters["main"] = (values: object) => {setRowData({ ...rowData, ...values });};
    return setters;
  }, [cols, data, rowData]);

  const row = useMemo(() => transformer(rowData, data, index, setters), [rowData, transformer]);

  const tableCells = useMemo(
    () =>
      cols.map((col) => (
        <TableCell
          className={`${styles.cell} ${col.specialCellStyles}`}
          key={col.name}
          sx={{ "&:hover": { boxShadow: "0 0 0 1px #ffa900 inset" } }}
        >
          {row[col.name]}
        </TableCell>
      )),
    [cols, row, styles.cell]
  );

  return (
    <TableRow
      sx={
        index % 2 === 0
          ? {
            backgroundColor: "#f7f7f7",
            "&:hover": {
              backgroundColor: "#f6f8fa",
              borderBottom: "2px solid #ffa900",
            },
          }
          : {
            backgroundColor: "#f0f0f0",
            "&:hover": {
              backgroundColor: "#f6f8fa",
              borderBottom: "2px solid #ffa900",
            },
          }
      }
    >
      {tableCells}
    </TableRow>
  );
}
