import { PaginatedComplexTable } from "shared/ui/table";
// import { useCampDashboardTable } from "entities/data";
import { useLocale } from "entities/i18n";
import { useTheme } from "@mui/material/styles";
import { getColumnsByI18nForCampTable, getGroupTitlesByI18n } from "./getColumns";
import { campTransformer } from "./campTransformer";
import { ResponsePlanFactAds } from "entities/mediaplan";

type CampDashboardTableProps = {
  className?: string;
  res: {
    data: ResponsePlanFactAds;
    isLoading: boolean;
    error: any;
  };
  fetchDataPage: (page: number) => void;
  userRole: "admin" | "director";
  clientId: string;
  agencyId: string;
};

const CampDashboardTable = ({
  className,
  res,
  fetchDataPage,
  userRole,
  clientId,
  agencyId
}: CampDashboardTableProps) => {
  // const [data, isLoading, error] = useCampDashboardTable();
  const { data, isLoading, error } = res;

  const i18n = useLocale();
  const { palette } = useTheme();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error...</div>;
  }

  return (
    <>
      {data.status_code === 0 && (
        <PaginatedComplexTable<PlanFactEntryAd>
          rawData={data.result.items}
          cols={getColumnsByI18nForCampTable(i18n)}
          groupTitles={getGroupTitlesByI18n(i18n)}
          rowTransformer={campTransformer(palette, userRole, clientId, agencyId)} //TODO: Rewrite transformer for camp table
          className={className}
          pages={data.result.pages}
          rowsPerPage={20}
          totalRows={data.result.total}
          handlePageChange={(page) => {
            fetchDataPage(page);
          }}
        />
      )}
      {data.status_code !== 0 && <div>Something went wrong...</div>}
      {data.status_code === 2022 && (
        <div className="font-bold">Campaign does not exist</div>
      )}
    </>
  );
};

export default CampDashboardTable;
