import { useTheme } from "@mui/material/styles";
import {
  IconPower,
  IconSettings,
  IconTrash,
  IconAd,
  IconUserCircle,
  IconApi,
} from "@tabler/icons-react";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButtonStack } from "shared/ui/components";
import { useLocale } from "entities/i18n";

export const BtnsManageClient = ({
  client,
  agencyId,
  dialogOpenHandler,
  setTab,
  index
}: {
  client: Client;
  agencyId: number;
  dialogOpenHandler: (action: DialogAction, index: number) => () => void;
  setTab: (tab: string) => void;
  index: number;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { palette } = useTheme();
  const {
    admin: { clients: i18n },
  } = useLocale();

  const handleAd = () => {
    navigate(`/admin/dashboard/${agencyId}/${client.id}`, {state: {client}});
  };

  const handleProfile = () => {
    // navigate("/users");
  };

  const handleApi = () => {
    console.log(location.state);
    navigate(`/admin/agencies/${agencyId}/api?client_name=${client.name}`, {state: location.state});
    setTab('api');
  };

  const handleSettings = () => {
    navigate(`/admin/agencies/${agencyId}/clients/${client.id}`, {
      state: { client },
    });
  };

  const handleDeactivate = dialogOpenHandler("deactivate", index);

  const handleDelete = dialogOpenHandler("delete", index);

  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const btnsProps = () => ({
    iconButtons: [
      {
        icon: IconAd,
        onClick: handleAd,
        tooltip: i18n.activeCampaigns,
      },
      {
        icon: IconUserCircle,
        onClick: handleProfile,
        tooltip: i18n.profile,
      },
      {
        icon: IconApi,
        onClick: handleApi,
        tooltip: i18n.api,
      },
      {
        icon: IconSettings,
        onClick: handleSettings,
        tooltip: i18n.edit,
      },
      {
        icon: IconPower,
        onClick: handleDeactivate,
        tooltip: client.is_active ? i18n.deactivate : i18n.activate,
        iconProps: {
          color: palette.text.primary,
        },
      },
      {
        icon: IconTrash,
        onClick: handleDelete,
        tooltip: i18n.delete,
        iconProps: {
          color: palette.text.primary,
        },
      },
    ],
    iconProps: {
      size: 20,
      strokeWidth: 1.5,
      color: client.is_active ?  palette.text.primary : palette.grey[400],
    },
    className: "gap-3 justify-end",
  });

  return (
    <IconButtonStack {...btnsProps()} />
    /*     <Box>
      <IconButton onClick={handleAd}>
        <IconAd className={iconsStyles} />
      </IconButton>
      <IconButton onClick={handleProfile}>
        <IconUserCircle className={iconsStyles} />
      </IconButton>
      <IconButton onClick={handleApi}>
        <IconApi className={iconsStyles} />
      </IconButton>
      <IconButton onClick={handleSettings}>
        <IconSettings className={iconsStyles} />
      </IconButton>
      <IconButton onClick={handleDeactivate}>
        <IconPower className={iconsStyles} />
      </IconButton>
      <IconButton onClick={handleDelete}>
        <IconTrash className={iconsStyles} />
      </IconButton>
    </Box> */
  );
};
