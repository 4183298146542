import { Button, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useDeleteConnectorMutation,
  useUpdateConnectorMutation,
} from "entities/connectors";
import { Dialog } from "shared/ui/components";
import { useLocale } from "entities/i18n";
import { useState } from "react";

const i18nLoc = {
  shureToDelete: "Вы уверены что хотите удалить API?",
  shureToDeactivate: "Вы уверены, что хотите деактивировать API?",
  shureToActivate: "Вы уверены, что хотите активировать API?",
  setNewName: "Укажите новое имя для API",
  rename: "Переименовать",
};

type Props = {
  apiId: number;
  open: boolean;
  setOpen: any;
  apiName: string;
  isActive: boolean;
  refetch: () => void;
  // setChanges: any;
};

export const DeleteApi = ({
  apiId,
  apiName,
  open,
  setOpen, // setChanges,
  refetch,
}: Props) => {
  const i18nGlobal = useLocale();
  const i18n = i18nGlobal.superAdmin;

  const [query, queryState] = useDeleteConnectorMutation();

  if (!open) return null;

  const handleDelete = async () => {
    await query({
      connector_id: apiId,
    });
    refetch();
    setOpen(false);
    // setChanges((prev: number) => (prev += 1));
  };

  const dialogToggler = () => setOpen(false);

  return (
    <Dialog toggler={dialogToggler}>
      <div className="flex flex-col items-center gap-4">
        <div>{i18nLoc.shureToDelete}</div>
        <div className="font-bold">{apiName}</div>
        {queryState.isLoading ? (
          <CircularProgress />
        ) : (
          <div className="flex flex-row justify-center gap-4">
            <Button
              disableElevation
              size="large"
              variant="contained"
              className="bg-primary text-white normal-case"
              onClick={handleDelete}
            >
              {i18n.delete}
            </Button>
            <Button
              disableElevation
              size="large"
              variant="contained"
              className="bg-primary text-white normal-case"
              onClick={dialogToggler}
            >
              {i18n.cancel}
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export const UpdateApiStatus = ({
  apiId,
  apiName,
  open,
  setOpen,
  isActive,
  refetch,
}: Props) => {
  const i18nGlobal = useLocale();
  const i18n = i18nGlobal.superAdmin;

  const [query, queryState] = useUpdateConnectorMutation();

  if (!open) return null;

  const handleUpdateStatus = async () => {
    await query({
      id: apiId,
      name: apiName,
      is_active: !isActive,
    });
    refetch();
    setOpen(false);
    // setChanges((prev: number) => (prev += 1));
  };

  const dialogToggler = () => setOpen((prev: boolean) => !prev);

  return (
    <Dialog toggler={dialogToggler}>
      <div className="flex flex-col items-center gap-4">
        <div>
          {isActive ? i18nLoc.shureToDeactivate : i18nLoc.shureToActivate}
        </div>
        <div className="font-bold">{apiName}</div>
        {queryState.isLoading ? (
          <CircularProgress />
        ) : (
          <div className="flex flex-row justify-center gap-4 ">
            <Button
              disableElevation
              size="large"
              variant="contained"
              className="bg-primary text-white normal-case"
              onClick={handleUpdateStatus}
            >
              {isActive ? i18n.deactivate : i18n.activate}
            </Button>
            <Button
              disableElevation
              size="large"
              variant="contained"
              className="bg-primary text-white normal-case"
              onClick={dialogToggler}
            >
              {i18n.cancel}
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export const UpdateApiName = ({
  apiId,
  apiName,
  open,
  isActive,
  // setChanges,
  setOpen,
  refetch,
}: Props) => {
  const i18nGlobal = useLocale();
  const i18n = i18nGlobal.superAdmin;

  const [name, setname] = useState<string>(apiName);

  const [query, queryState] = useUpdateConnectorMutation();

  if (!open) return null;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setname(e.target.value);

  const handleUpdateName = async () => {
    await query({
      id: apiId,
      name,
      is_active: isActive,
    });
    refetch();
    setOpen(false);
    // setChanges((prev: number) => (prev += 1));
  };

  const dialogToggler = () => setOpen(false);

  return (
    <Dialog toggler={dialogToggler}>
      <div className="flex flex-col items-center gap-4">
        <div>{i18nLoc.setNewName}</div>
        <div>
          <TextField
            value={name}
            onChange={handleOnChange}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
        {queryState.isLoading ? (
          <CircularProgress />
        ) : (
          <div className="flex flex-row justify-center gap-4 ">
            <Button
              disableElevation
              size="large"
              variant="contained"
              className="bg-primary text-white normal-case"
              onClick={handleUpdateName}
            >
              {i18nLoc.rename}
            </Button>
            <Button
              disableElevation
              size="large"
              variant="contained"
              className="bg-primary text-white normal-case"
              onClick={dialogToggler}
            >
              {i18n.cancel}
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};
