import React from 'react'
import { useTheme } from '@mui/material/styles'

type ShadowCardProps = {
    children?: React.ReactNode
    width?: string
    height?: string
    className?: string
}

const ShadowCard = ({children, width, height, className}: ShadowCardProps) => {
  const theme = useTheme()
  const shadow = theme.shadows['3']
  return (
    <div 
      className={`rounded-lg bg-white ${className ? className : ""}`} 
      style={{boxShadow: shadow, width, height}}>
      {children}
    </div>
  )
}

export default ShadowCard