import AuthCard from "./authCard";
import AuthFormEmail from "./authByEmail";
import AuthFormResetPassword from "./resetPassword";
import AuthFormSaveResetPassword from "./saveResetPassword";
import RegistrationForm from "./registration";
import RegistrationSuccess from "./registrationSuccess";

export {
  AuthCard,
  AuthFormEmail,
  AuthFormResetPassword,
  AuthFormSaveResetPassword,
  RegistrationForm,
  RegistrationSuccess
};

export * from "./AuthRouter";
