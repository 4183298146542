import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import { Header } from "shared/ui";

/**
 * @param {ReactNode} headerWidget - (опционально) Виджеты для хедера (например, ProfileMenu)
 */
type MainLayoutProps = {
  headerWidget?: React.ReactNode;
};

export default ({ headerWidget }: MainLayoutProps) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header startPage={"/"}>{headerWidget}</Header>
      <main className="flex-auto bg-grey-100 font-inter px-[3.25rem] pt-5 flex flex-col justify-start [&>*:last-child]:flex-auto">
        <Outlet />
      </main>
      <Footer noCenterPiece />
    </div>
  );
};
