import { useCurrentLocale } from "entities/i18n";
import { LocalePicker } from "features/i18n";

import { CommonLayout, Header } from "shared/ui/layout";
import { Footer } from "widgets/layout";

export const PrivacyPolicy = () => {
  const locale = useCurrentLocale();

  return (
    <div
      className="flex flex-col justify-between "
      style={{
        minHeight: "100vh",
      }}
    >
      <div className="flex flex-row justify-between pr-10 items-start flex-grow-0">
        <Header startPage="/" />
        <div className="ml-5 mt-5">
          <LocalePicker />
        </div>
      </div>
      <CommonLayout title="">
        {locale === "rus" ? (
          <div
            className="flex-grow p-4 mx-auto"
            dangerouslySetInnerHTML={{
              __html: `
<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><b><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>Политика
конфиденциальности и политика в отношении файлов <span class=SpellE>Cookie</span></span></b><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>&nbsp;</span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>Последнее обновление 18.06.2024<o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><b><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>Введение</span></b><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>Товарищество с
ограниченной ответственностью «<a name="_Hlk117855182">ADV Tech</a>» в лице <span
class=SpellE>И.О.Генерального</span> директора <span class=SpellE>Кагарова</span>
А.А. действующего на основании Устава (&quot;Администратор&quot;,
&quot;мы,&quot; &quot;наш&quot;) предлагает широкий спектр инструментов для
повышения производительности, совместной работы и организации, доступных
онлайн, в том числе через веб-сайты, включая, но не ограничиваясь,&nbsp;</span><span
style='color:black;mso-color-alt:windowtext'><a
href="https://app.danametrics.ai"><span lang=RU style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:
minor-latin;mso-bidi-theme-font:minor-latin;letter-spacing:.1pt;mso-ansi-language:
RU'>https://app.danametrics.ai</span></a></span><span lang=RU style='font-size:
11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;
mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;color:#262626;
letter-spacing:.1pt;mso-ansi-language:RU'> &nbsp;(«Веб-сайт»), Danametrics,
мобильное приложение (совместно именуемые «Сервис») и предоставляемые нами
услуги, а также любые другие приложения или услуги, которые мы можем предложить
(совместно именуемые «Услуги»). Политика конфиденциальности и Политика в
отношении файлов <span class=SpellE>Cookie</span> именуются далее Политика.<br>
<br>
Когда мы говорим «личные данные», мы имеем в виду информацию о вас, такую как
ваше имя, адрес электронной почты, физический адрес, информация об оплате,
запросы поддержки и так далее. Если вас не могут идентифицировать (например,
когда личные данные были анонимны и сгруппированы), эта Политика не
применяется.<br style='mso-special-character:line-break'>
<![if !supportLineBreakNewLine]><br style='mso-special-character:line-break'>
<![endif]></span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><b><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>1. Какие ваши данные мы
собираем и как?</span></b><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>Когда вы посещаете или
используете некоторые части наших Сервисов и Веб-сайтов, мы можем попросить вас
предоставить нам некоторые личные данные. Например, мы запрашиваем ваши
контактные данные при регистрации, посещении Сервисов и посещении Веб-сайтов, использовании
Сервисов и Веб-сайтов, оплате Услуг, для рассылки, при получении запроса на
техническую поддержку, когда вы хотите связаться с нами через форму обратной
связи. Если вы не хотите предоставлять нам личные данные, вам не нужно этого
делать, но это будет означать, что вы не сможете использовать наши Сервисы и
Веб-сайты.<br>
<br>
Большая часть информации, которую мы собираем, мы собираем непосредственно от
вас. Иногда мы можем собирать личные данные о вас из других источников, таких
как общедоступные материалы или доверенные третьи стороны, такие как наши <span
class=SpellE>партнеры</span> по маркетингу и исследованиям. Мы используем эту
информацию для дополнения уже имеющихся у вас персональных данных, чтобы лучше
информировать, персонализировать и улучшать наши услуги, а также для проверки
предоставленных вами персональных данных.<br>
<br>
Некоторая информация о вас собирается автоматически, когда вы посещаете наши
Сервисы и Веб-сайты, например, ваш IP-адрес и данные браузера. Ваш IP-адрес
собирается для обеспечения безопасности и для того, чтобы примерно узнать, где
вы находитесь в мире, чтобы персонализировать свой опыт. Информация о вашем
браузере и операционной системе хранится, чтобы помочь вам в случае
возникновения проблем с использованием наших услуг. Когда вы просматриваете
наши веб-сайты, мы собираем информацию, в том числе о том, какие страницы вы
просматривали и по каким ссылкам переходили. Эта информация важна, поскольку
она помогает нам улучшать предоставляемые вам услуги <span class=SpellE>путем</span>
мониторинга производительности и того, что необходимо улучшить.<br>
<br>
Часть этой информации собирается с использованием файлов <span class=SpellE>cookie</span>
и аналогичных технологий отслеживания, которые подробно описаны в разделе <span
class=SpellE>Cookie</span> ниже.</span><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:
minor-latin;mso-bidi-theme-font:minor-latin;color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>Тип информации, которую
мы собираем, будет зависеть от вашего взаимодействия с Сервисами и Веб-сайтами.
Это может включать в себя:</span><span style='font-size:11.0pt;font-family:
"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:
minor-latin;mso-bidi-theme-font:minor-latin;color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>Контактная информация и
информация для входа.&nbsp;Имя, должность, адрес электронной почты, физический
адрес, номер телефона и аналогичные контактные данные, имя пользователя и
пароли.<br>
<br>
<span class=SpellE>Платежная</span> информация.&nbsp;Номер кредитной карты,
банковская информация и <span class=SpellE>платежный</span> адрес.<br>
<br>
Демографическая информация.&nbsp;Занятость, род занятий, регион, семейное
положение. В некоторых случаях Сервисы и Веб-сайты могут собирать другую
демографическую информацию, такую как пол, раса и возраст.<br>
<br>
Данные об использовании и географические данные.&nbsp;Сервисы и Веб-сайты
собирают личную информацию, связанную с использованием вами наших продуктов,
услуг и веб-сайтов. Мы анализируем эти данные об использовании, чтобы мы могли
улучшить наши продукты и ваш опыт работы с нами. В некоторых случаях Сервисы и
Веб-сайты позволяют вам использовать инструменты администрирования, чтобы
отказаться от сбора некоторых данных об использовании.<br>
<br>
Данные об использовании могут включать информацию об операционной системе
вашего компьютера или мобильного устройства, типе браузера; о типе вашего
устройства; сведения о том, как вы используете наши продукты (включая запросы
на родном языке); можем просматривать адрес вашего интернет-протокола (IP) и
географические районы, полученные из вашего IP-адреса; данные сетевого подключения;
информацию о <span class=SpellE>cookie</span>-файлах Сервисов и Веб-сайтов;
информацию в файлах; метаданные; журналы с отметкой времени относительно
времени доступа и продолжительности посещений; веб-сайты, которые вы посетили
до посещения Сервисов и Веб-сайтов; и другие данные об использовании,
относящиеся к вашей<br>
деятельности на наших Сервисах и Веб-сайтах, включая запрашиваемые вами
страницы. Мы можем связать эту информацию с личной информацией, которую мы
собрали о вас, и использовать <span class=SpellE>ее</span> в целях, описанных в
настоящей Политике конфиденциальности, и мы можем предоставлять вам целевую
маркетинговую информацию и информацию о продукте на основе ваших данных об
использовании.<br>
<br>
Данные третьих лиц.&nbsp;Мы можем получать вашу личную информацию от сторонних
поставщиков или <span class=SpellE>партнеров</span>. Если вы подключаетесь к <span
class=SpellE>учетным</span> записям Сервисов и Веб-сайтов на сторонних сайтах
социальных сетей, мы можем получать информацию о ваших <span class=SpellE>учетных</span>
записях в социальных сетях, например, ваше имя, имя пользователя или
отображаемое имя, общедоступный профиль и адрес электронной почты. Мы можем
объединять предоставленную вами информацию с автоматически собираемыми данными
и с данными, полученными от третьих лиц. Некоторые третьи стороны, в том числе
аналитические компании, рекламодатели и рекламные сети, могут автоматически
собирать информацию о вас через наши веб-сайты, используя файлы <span
class=SpellE>cookie</span>, веб-маяки и идентификаторы устройств, включая
личную информацию о вашей онлайн-активности с течением времени и на разных
сайтах, устройствах, онлайн-каналах и приложениях.<br>
<br>
Изображения, видео и аудиозаписи.&nbsp;Фотографии, видео или аудиозаписи могут
быть собраны Сервисом и Сайтами. Например, если вы посещаете Сервис и
Веб-сайты, это может быть запечатлено на фотографии или видео. Если вы
позвоните в службу поддержки клиентов Сервиса и Веб-сайтов, ваш звонок может
быть записан. Если вы откажетесь предоставить свою личную информацию или
попросите нас удалить <span class=SpellE>ее</span>, мы не сможем продолжать
предоставлять или поддерживать наши продукты или услуги.<br>
<br>
Данные о вашей компании.&nbsp;Название компании, сфера деятельности компании,
веб-сайт компании, должность в компании, дата начала работы в компании, дата
создания компании, физический адрес компании, данные о сотрудниках,
экономические данные и другие показатели, которые пользователь ввёл на Сервисе
и Веб-сайте.</span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>&nbsp;</span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>Данные о ваших <span
class=SpellE>партнерах</span>.</span><span style='font-size:11.0pt;font-family:
"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:
minor-latin;mso-bidi-theme-font:minor-latin;color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>Соглашаясь с условиями
данной Политики, вы подтверждаете наличие у Вас аналогичного согласия со
стороны Ваших <span class=SpellE>Партнеров</span> и ограждаете нас от претензий
и исков с их Стороны.</span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>&nbsp;</span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><b><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>2. Для чего мы
используем ваши личные данные?</span></b><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:
minor-latin;mso-bidi-theme-font:minor-latin;color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>Ваши личные данные
используются исключительно для улучшения вашего опыта использования Сервиса и
Веб-сайтов, а также в качестве средства для предоставления нам следующего:<br>
<br>
Материалов поддержки, запрашиваемой вами информации или данные, основанные на
использовании вами приложения, чтобы помочь вам получить</span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>Чтобы держать вас в
курсе любых обновлений, уведомлений или всего, что имеет отношение к
использованию вами Сервиса и Сайтов;</span><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:
minor-latin;mso-bidi-theme-font:minor-latin;color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>Запрашивать у вас
обратную связь или принимать участие в любом проводимом нами исследовании,
которое вы сможете игнорировать или отказаться от участия;</span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>Чтобы помочь вам с
любой необходимой вам поддержкой через любой из наших каналов связи;</span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>Чтобы помочь нам
обнаружить и предотвратить любую мошенническую или злонамеренную деятельность,
а также убедиться, что все используют наши веб-сайты и услуги справедливо и в
соответствии с договором оферты.</span><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:
minor-latin;mso-bidi-theme-font:minor-latin;color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>Мы можем использовать
личные данные, которые мы собираем о вас и других пользователях наших
Веб-сайтов и Сервисов (независимо от того, получены ли они напрямую или от
третьих лиц), для создания анонимных и сгруппированных аналитических материалов
и <span class=SpellE>отчетов</span>, которыми мы можем поделиться публично.<o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><b><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>3. Как мы <span
class=SpellE>передаем</span> и храним ваши данные?</span></b><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>У нас есть юридические
обязательства по сбору любой личной информации, которую вы предоставляете нам.
Мы будем <span class=SpellE>надежно</span> хранить вашу личную информацию и
будем хранить <span class=SpellE>ее</span> до тех пор, пока вы пользуетесь
запрошенной вами услугой.<br>
<br>
Все ваши личные данные хранятся на защищенных серверах PS <span class=SpellE>Cloud</span>
и защищены методами маскирования и шифрования данных. Мы не <span class=SpellE>передаем</span>
ваши персональные данные третьим сторонним поставщикам или любым другим
приложениям или услугам.<br>
<br>
Мы будем передавать ваши персональные данные только регулирующим органам,
правоохранительным органам, государственным органам, судам или другим третьим
лицам, если мы считаем необходимым соблюдать применимые законы или нормативные
акты или осуществлять, устанавливать или защищать наши законные права. Там, где
это возможно и уместно, мы будем уведомлять вас об этом типе раскрытия.<br>
<br>
Сервис и Веб-сайты могут предоставлять информацию сторонним поставщикам услуг
или поставщикам, с которыми мы заключили договор на предоставление услуг от
нашего имени. Эти сторонние поставщики услуг или поставщики могут использовать
информацию, которую мы им предоставляем, только в соответствии с инструкциями
настоящей Политики конфиденциальности и использования файлов <span
class=SpellE>cookie</span>.<o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><b><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>4. Как долго мы храним
ваши личные данные?</span></b><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>Время, в течение
которого мы храним ваши персональные данные, зависит от того, какие это данные
и есть ли необходимость их постоянного хранения (например, чтобы предоставить
вам запрошенную вами услугу или соблюдать требования законодательства).<br>
<br>
Обычно ваши личные данные хранятся до тех пор, пока владелец компании не удалит
сотрудника или пока владелец <span class=SpellE>учетной</span> записи не удалит
его. Для бесплатных аккаунтов мы имеем право удалить вашу информацию, если она
не использовалась в течение 4 месяцев.<o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><b><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>5. Ваши права<o:p></o:p></span></b></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>По вопросам отмены
подписки на рассылку и другим вопросам, связанным с работой Сервиса и
Веб-сайтов, а также с другими вопросами, предложениями, вы можете обращаться по
электронной почте&nbsp;support@danametrics.ai.<br>
<br>
Вы также имеете право знать, какие персональные данные о вас мы храним, а также
проверять их правильность и актуальность. Вы можете запросить копию ваших
личных данных или попросить нас ограничить обработку ваших личных данных или
удалить их.<br>
<br>
Если вы недовольны тем, как мы обрабатываем ваши личные данные, сообщите нам об
этом, отправив электронное письмо по адресу&nbsp;</span><span style='color:
black;mso-color-alt:windowtext'><a href="mailto:dev@advtech.kz"><span lang=RU
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
letter-spacing:.1pt;mso-ansi-language:RU'>dev@advtech.kz</span></a></span><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'> .&nbsp;Мы рассмотрим
вашу жалобу и свяжемся с вами в разумные сроки. Вы также можете подать жалобу в
местный орган защиты данных. Они смогут посоветовать вам, как подать жалобу.</span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>&nbsp;</span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p style='margin:0cm;mso-add-space:auto;text-align:justify;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>Если Вы не согласны с
условиями Политики, то Вы должны немедленно прекратить любое использование
Сайта.</span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>&nbsp;</span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><b><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>6. Что такое <span
class=SpellE>cookie</span> и как мы их используем?</span></b><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>Файлы <span
class=SpellE>cookie</span> <span class=GramE>- это</span> небольшие текстовые
файлы, которые веб-сайты сохраняют на вашем компьютере. Файлы <span
class=SpellE>cookie</span>, используемые Сервисом и Веб-сайтами, не содержат
никакой вашей личной информации, и мы не можем использовать их, чтобы выяснить,
кто вы. Файл <span class=SpellE>cookie</span> часто содержит случайно
сгенерированный номер, который хранится на вашем устройстве. Многие <span
class=SpellE>cookie</span> автоматически удаляются после того, как вы закончите
пользоваться Сервисом и Сайтами.<br>
<br>
Когда вы заходите на наш сайт, ваш компьютер автоматически получает файл <span
class=SpellE>cookie</span>. Файлы <span class=SpellE>cookie</span> <span
class=GramE>- это</span> устройства, которые идентифицируют ваш компьютер на
нашем сервере и персонализируют сайт для вашего будущего использования. Файлы <span
class=SpellE>cookie</span> регистрируют только те области нашего сайта, которые
посещал компьютер. Файл <span class=SpellE>cookie</span> не предоставляет нам
никакой личной информации. Поэтому, если вы не предоставили нам какую-либо
личную информацию, вы все равно можете просматривать наш сайт анонимно. Если вы
не хотите куки, вы можете настроить свой браузер, чтобы запретить его. Файл <span
class=SpellE>cookie</span> необходим для входа в Сервис и на Веб-сайты, а также
для нашего безопасного хранения вашего логина для вашего сеанса.<br>
<br>
Улучшение опыта пользователей<br>
Сервис и веб-сайты работают над тем, чтобы его пользователи имели наилучшие
возможности. Это означает, что пользователю не нужно повторять некоторые
действия без необходимости. Например, если вы увеличиваете размер текста, когда
находитесь на Сервисе и Веб-сайтах, может быть установлен файл <span
class=SpellE>cookie</span>, который запоминает предпочитаемый вами размер
текста, чтобы при переходе на другую страницу сайта вам не приходилось менять
ваши настройки снова.<br>
<br>
Измерение эффективности сайтов<br>
Мы также можем установить <span class=SpellE>cookie</span> на компьютер
пользователя, чтобы отслеживать количество посетителей. Никакая личная
информация, такая как ваше имя или адрес, не собирается в рамках этого
процесса.<br>
<br>
Поддержка обмена информацией между пользователями через социальные сети.<br>
Обмен является ключевой частью того, как люди получают и используют информацию.
Социальные сети являются популярным способом сделать это, чтобы люди могли
делиться информацией о Сервисе и Веб-сайтах таким образом. Владельцы этих
социальных сетей могут размещать файлы <span class=SpellE>cookie</span> на
устройствах пользователей, и использование этих файлов <span class=SpellE>cookie</span>
часто объясняется в их собственной политике использования файлов <span
class=SpellE>cookie</span>. Администратор не контролирует политику
использования файлов <span class=SpellE>cookie</span> этих компаний.<o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><b><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>7. Google Analytics</span></b><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>Служба и веб-сайты
используют Google Analytics, службу веб-аналитики, разработанную компанией
Google, Inc. («Google»). Программное обеспечение Google Analytics использует
файлы <span class=SpellE>cookie</span> для анализа поведения пользователей. Все
данные (т. е. Ваша деятельность в Службе и на веб-сайтах) собираются с помощью
файлов <span class=SpellE>cookie</span> и хранятся на серверах Google в США.<br>
<br>
Google, в свою очередь, использует эти данные для оценки поведения
пользователей в целом, для составления <span class=SpellE>отчетов</span> для
руководителей сайтов, а также для предоставления данных службам, связанным с
активностью веб-сайта и использованием Интернета. Google может передавать
различные данные третьим лицам, если это требуется по закону или если третьи
лица обрабатывают информацию от имени Google.<br>
<br>
Google никоим образом не будет связывать ваш IP-адрес с любыми другими данными,
хранящимися в Google. Если вы не хотите предоставлять Google данные для
просмотра, вы можете заблокировать использование файлов <span class=SpellE>cookie</span>
через настройки браузера. Дополнительная информация об отказе от аналитики
Google:&nbsp;</span><span style='color:black;mso-color-alt:windowtext'><a
href="https://www.google.com/intl/en/policies/privacy/"><span lang=RU
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:black;mso-color-alt:windowtext;letter-spacing:.1pt;mso-ansi-language:
RU'>https://www.google.com/intl/en/policies/privacy/</span></a></span><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>, и&nbsp;</span><span
style='color:black;mso-color-alt:windowtext'><a
href="http://www.google.com/analytics/terms/us.html"><span lang=RU
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:black;mso-color-alt:windowtext;letter-spacing:.1pt;mso-ansi-language:
RU'>http://www.google.com/analytics/terms/us.html</span></a></span><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>.<br>
<br>
Google Analytics объясняется по следующей ссылке&nbsp;</span><span
style='color:black;mso-color-alt:windowtext'><a
href="https://www.google.com/analytics/"><span lang=RU style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:
minor-latin;mso-bidi-theme-font:minor-latin;color:black;mso-color-alt:windowtext;
letter-spacing:.1pt;mso-ansi-language:RU'>https://www.google.com/analytics/</span></a></span><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><b><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>8. Политика
ограниченного использования данных<o:p></o:p></span></b></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:EN-US'>Danametrics</span><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'> использует и передаёт информацию,
полученную от Google API. </span><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:
minor-latin;mso-bidi-theme-font:minor-latin;color:#262626;letter-spacing:.1pt;
mso-ansi-language:EN-US'>Danametrics</span><span lang=EN-US style='font-size:
11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;
mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;color:#262626;
letter-spacing:.1pt;mso-ansi-language:RU'> </span><span lang=RU
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>придерживается политики
пользовательских данных Google API Services, включая требования ограниченного
использования </span><span style='color:black;mso-color-alt:windowtext'><a
href="https://holidayesim.com/privacy-policy.html"><span lang=RU
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:black;mso-color-alt:windowtext;letter-spacing:.1pt;mso-ansi-language:
RU'>https://holidayesim.com/privacy-policy.html</span></a></span><span lang=RU
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:black;mso-color-alt:windowtext;letter-spacing:.1pt;mso-ansi-language:
RU'> </span><span lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><b><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>9. Что такое IP-адрес?</span></b><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>Если вы подключены к Интернету,
у вас будет IP-адрес. Адрес будет автоматически собран и зарегистрирован как
часть подключения вашего компьютера к нашему веб-серверу и может использоваться
для определения общего количества посещений каждой части сайта. Он также
используется для определения приблизительного местоположения страны и помогает
в выявлении потенциальной вредоносной активности.</span><span style='font-size:
11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;
mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;color:#262626;
letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:RU'>&nbsp;</span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><b><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:black;mso-color-alt:windowtext;letter-spacing:.1pt;mso-ansi-language:
RU'>10. Политика в отношении пользовательских данных служб Google API</span></b><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
style='color:black;mso-color-alt:windowtext'><a
href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
target=blank><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;color:black;mso-color-alt:windowtext;letter-spacing:.1pt'>https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes</span></a></span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:black;mso-color-alt:windowtext;letter-spacing:.1pt;background:white'>&nbsp;</span><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><b><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:black;mso-color-alt:windowtext;letter-spacing:.1pt;mso-ansi-language:
RU'>11. Кто мы и как с нами связаться?</span></b><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:
minor-latin;mso-bidi-theme-font:minor-latin;letter-spacing:.1pt'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:black;mso-color-alt:windowtext;letter-spacing:.1pt;mso-ansi-language:
RU'>Когда мы ссылаемся на «мы», это относится к Администратору и
веб-сайту&nbsp;</span><span style='color:black;mso-color-alt:windowtext'><a
href="https://app.danametrics.ai/"><span lang=RU style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:
minor-latin;mso-bidi-theme-font:minor-latin;color:black;mso-color-alt:windowtext;
letter-spacing:.1pt;mso-ansi-language:RU'>https://app.danametrics.ai/</span></a></span><span
lang=RU style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:black;mso-color-alt:windowtext;letter-spacing:.1pt;mso-ansi-language:
RU'>, Danametrics. </span><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;letter-spacing:.1pt'><o:p></o:p></span></p>

`,
            }}
          ></div>
        ) : (
          <div
            className="pl-4"
            dangerouslySetInnerHTML={{
              __html: `
<p><b><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>Privacy Policy and Cookie Policy<o:p></o:p></span></b></p>

<p><b><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'><o:p>&nbsp;</o:p></span></b></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Last
updated on </span><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;mso-ansi-language:EN-US'>June 18</span><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:
minor-latin;mso-bidi-theme-font:minor-latin'>, 2024<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p><b><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>Introduction</span></b><span style='font-size:11.0pt;font-family:
"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:
minor-latin;mso-bidi-theme-font:minor-latin'><o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Limited
Liability Company &quot;ADV Tech,&quot; represented by the, </span><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
mso-ansi-language:EN-US'>CEO </span><span style='font-size:11.0pt;font-family:
"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:
minor-latin;mso-bidi-theme-font:minor-latin'>Kagarov A.A., acting on the basis
of the Charter (&quot;Administrator,&quot; &quot;we,&quot; &quot;our&quot;),
offers a wide range of tools for enhancing productivity, collaboration, and
organization, available online, including through websites, including but not
limited to https://app.danametrics.ai (&quot;Website&quot;), Danametrics,
mobile application (collectively referred to as the &quot;Service&quot;), and
the services provided by us, as well as any other applications or services that
we may offer (collectively referred to as the &quot;Services&quot;). The
Privacy Policy and Cookie Policy are hereinafter referred to as the Policy.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>When
we refer to &quot;personal data,&quot; we mean information about you, such as
your name, email address, physical address, payment information, support
requests, and so on. If you cannot be identified (for example, when personal
data has been anonymized and aggregated), this Policy does not apply.<br>
<br>
<b>1. What data do we collect and how?</b><o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>When
you visit or utilize certain portions of our Services and Websites, we may
request that you furnish us with various personal data. For instance, we may
solicit your contact particulars during registration, website visits, service
usage, payment transactions, newsletter subscriptions, when seeking technical
support, or when engaging with us via a feedback mechanism. Your provision of
personal data is discretionary; however, abstaining from such provision will
preclude your access to our Services and Websites.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>The
bulk of the information we gather is directly sourced from you. On occasion, we
may procure personal data from alternate origins such as publicly accessible
reservoirs or reputable third-party entities, including our marketing and
research associates. This information supplements extant personal data to
enhance our service provision, tailor experiences, and refine our offerings.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Certain
information concerning you is acquired automatically upon your visitation to
our Services and Websites, encompassing your IP address and browser specifics.
IP addresses are collated to fortify security measures and to broadly ascertain
geographical locales for the purpose of personalization. Browser and operating
system data are retained to facilitate troubleshooting in the event of
operational impediments. Page viewings and hyperlink navigations are among the
browsing activities tracked, vital for performance evaluation and enhancement
identification.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>This
data assortment employs cookies and akin tracking technologies, exhaustively
expounded upon in the subsequent Cookie segment.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>The
nature of information collated is contingent upon your interaction with the
Services and Websites, potentially embracing:<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Contact
and login particulars: Name, designation, email address, physical address,
telephone number, usernames, and passwords.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Payment
particulars: Credit card numbers, banking information, and billing addresses.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Demographic
data: Employment status, occupation, region, marital status, and occasionally,
supplementary demographic facets such as gender, race, and age.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Usage
and geographical data: Personal data associated with your utilization of our
products, services, and websites. Analysis of this usage data enables product
amelioration and enhances user experiences. Administratively, certain Services
and Websites afford opt-out functionalities for specific usage data
collections.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Usage
data may encompass insights into your device's operating system, browser type,
device category, native language queries, IP address, network connectivity
specifics, cookie information, file content, metadata, access timestamps, prior
website visits, and additional usage-related facets. These data points may be
correlated with personal information in our possession, serving the purposes
outlined in this Privacy Policy. Moreover, targeted marketing and product
information may be proffered based on your usage patterns.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Third-party
data: Personal information may be sourced from third-party providers or
partners. Should you link Service and Website accounts to third-party social
media platforms, insights into your social media profiles, including name,
username, display name, and email address, may be garnered. The amalgamation of
provided information with automatically collected and third-party data is
feasible. Certain third parties, inclusive of analytics entities, advertisers,
and ad networks, might automatically capture information about you via our
websites, employing cookies, web beacons, and device identifiers, thereby
accruing personal data concerning your online engagements across diverse
websites, devices, online channels, and applications.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Images,
videos, and audio recordings: Instances of photo, video, or audio capture may
transpire during Service and Website interactions. For instance, visual or
auditory captures might occur during website visits or customer support calls.
Refusal to furnish personal information or requests for its deletion may
preclude continued product or service provision and support.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Company
data: Business names, industries, websites, positions within companies,
employment commencements, establishment dates, physical addresses, employee
details, economic metrics, and other user-entered indicators may be among the
company-centric data processed by Services and Websites.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Partner
data:<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>By
assenting to the terms herein, you affirm the concurrence of analogous
agreements from Your Partners, indemnifying us against claims and litigations
emanating from their end.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p><b><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>2. How do we use your personal data?<o:p></o:p></span></b></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Your
personal data is exclusively utilized to enhance your experience with the
Service and Websites and serves as a means to provide us with the following:<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Support
materials, requested information, or data based on your application usage to
assist you in obtaining necessary assistance.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>To
keep you informed of any updates, notifications, or relevant information
pertaining to your usage of the Service and Websites.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Soliciting
your feedback or participation in any research endeavors conducted by us, which
you may opt to ignore or decline participation in.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Assisting
you with any necessary support through any of our communication channels.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Aiding
us in detecting and preventing any fraudulent or malicious activities, ensuring
that all individuals utilize our websites and services fairly and in accordance
with the terms of service.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>We
may utilize the personal data we collect about you and other users of our
Websites and Services (whether obtained directly or from third parties) to
generate anonymous and aggregated analytical materials and reports, which we
may publicly share.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p><b><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>3. How do we transmit and store your data?<o:p></o:p></span></b></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>We
have legal obligations regarding the collection of any personal information you
provide to us. We will securely store your personal information and retain it
for as long as you continue to use the requested service.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>All
your personal data is stored on secure PS Cloud servers and is protected by
data masking and encryption methods. We do not transmit your personal data to
third-party providers or any other applications or services.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>We
will only transmit your personal data to regulatory bodies, law enforcement
agencies, government authorities, courts, or other third parties if we believe
it is necessary to comply with applicable laws or regulations or to exercise,
establish, or defend our legal rights. Where possible and appropriate, we will
notify you of this type of disclosure.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>The
Service and Websites may provide information to third-party service providers
or suppliers with whom we have contracted to provide services on our behalf.
These third-party service providers or suppliers may only use the information
we p</span><span class=SpellE><span lang=EN-US style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:
minor-latin;mso-bidi-theme-font:minor-latin;mso-ansi-language:EN-US'>rovide</span></span><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
mso-ansi-language:EN-US'> them, only in accordance with the instructions of
current Confidential Privacy and cookie-use policy<o:p></o:p></span></p>

<p><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;mso-ansi-language:EN-US'><o:p>&nbsp;</o:p></span></p>

<p><b><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>4. How long do we retain your personal data?<o:p></o:p></span></b></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>The
duration for which we retain your personal data depends on the nature of the
data and whether there is a need for its ongoing retention (for example, to
provide you with the requested service or to comply with legal requirements).<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Typically,
your personal data is retained until the company owner deletes an employee or
until the account owner deletes it. For free accounts, we reserve the right to
delete your information if it has not been used for 4 months.<br>
<br>
<b>5. Your Rights</b><o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>For
subscription cancellation and other inquiries related to the operation of the
Service and Websites, as well as for any other questions or suggestions, you
can contact us via email at </span><a href="mailto:dev@advtech.kz"><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>dev@advtech.kz</span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
mso-ansi-language:EN-US'> </span><span style='font-size:11.0pt;font-family:
"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:
minor-latin;mso-bidi-theme-font:minor-latin'>.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>You
also have the right to know what personal data we hold about you, as well as to
verify its accuracy and currency. You can request a copy of your personal data
or ask us to restrict or delete it.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>If
you are unhappy with how we process your personal data, please inform us by
sending an email to support@danametrics.ai. We will review your complaint and
respond to you promptly. You may also file a complaint with your local data
protection authority. They can advise you on how to file a complaint.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>If
you do not agree with the terms of the Policy, you must immediately cease any
use of the Site.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p><b><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>6. What are Cookies and How Do We Use Them?<o:p></o:p></span></b></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Cookies
are small text files that websites store on your computer. The cookies used by
the Service and Websites do not contain any of your personal information, and
we cannot use them to identify who you are. A cookie often contains a randomly
generated number that is stored on your device. Many cookies are automatically
deleted after you finish using the Service and Websites.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>When
you visit our site, your computer automatically receives a cookie. Cookies are
devices that identify your computer to our server and personalize the site for
your future use. Cookies only record those areas of our site that have been
visited by the computer. A cookie does not provide us with any personal
information. Therefore, if you have not provided us with any personal
information, you can still browse our site anonymously. If you do not want
cookies, you can configure your browser to refuse them. A cookie is necessary
to log in to the Service and Websites and for our secure storage of your login
for your session.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Improving
User Experience<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>The
Service and websites are working to provide their users with the best possible
experience. This means that the user does not need to repeat some actions
unnecessarily. For example, if you increase the text size when on the Service
and Websites, a cookie may be set to remember your preferred text size so that
when you navigate to another page on the site, you do not have to change your
settings again.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Measuring
Website Effectiveness<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>We
may also set cookies on the user's computer to track the number of visitors. No
personal information, such as your name or address, is collected as part of
this process.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Supporting
Information Sharing Between Users via Social Networks<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Sharing
is a key part of how people obtain and use information. Social networks are a
popular way to do this so that people can share information about the Service
and Websites in this way. Owners of these social networks may place cookies on
users' devices, and the use of these cookies is often explained in their own
cookie usage policy. The administrator does not control the cookie usage policy
of these companies.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p><b><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>7. Google Analytics<o:p></o:p></span></b></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>The
Service and Websites use Google Analytics, a web analytics service provided by
Google, Inc. (&quot;Google&quot;). Google Analytics software utilizes cookies
to analyze user behavior. All data (i.e., Your activity on the Service and
Websites) is collected using cookies and stored on Google servers in the United
States.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Google,
in turn, uses this data to evaluate user behavior overall, compile reports for
website managers, and provide data to services related to website activity and
internet usage. Google may transmit various data to third parties if required
by law or if third parties process information on behalf of Google.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Google
will not in any way associate your IP address with any other data stored by
Google. If you do not wish to provide Google with data for analysis, you can
block the use of cookies through your browser settings. Additional information
about opting out of Google Analytics can be found at<br>
https://www.google.com/intl/en/policies/privacy/ and
http://www.google.com/analytics/terms/us.html.<o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'>Google
Analytics is explained at the following link: </span><a
href="https://www.google.com/analytics/"><span style='font-size:11.0pt;
font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:
minor-latin;mso-bidi-theme-font:minor-latin'>https://www.google.com/analytics/</span></a><span
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'><o:p></o:p></span></p>

<p><span style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin'><o:p>&nbsp;</o:p></span></p>

<p style='margin:0cm;mso-add-space:auto'><b><span lang=EN-US style='font-size:
11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;
mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;mso-ansi-language:
EN-US'>8. Limited Use requirements<o:p></o:p></span></b></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:EN-US'>Danametrics use and
transfer of information received from Google APIs to any other app will adhere
to Google API Services User Data Policy, including the Limited Use requirements
</span><span style='color:black;mso-color-alt:windowtext'><a
href="https://holidayesim.com/privacy-policy.html"><span lang=EN-US
style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:black;mso-color-alt:windowtext;letter-spacing:.1pt;mso-ansi-language:
EN-US'>https://holidayesim.com/privacy-policy.html</span></a></span><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:black;mso-color-alt:windowtext;letter-spacing:.1pt;mso-ansi-language:
EN-US'> </span><span lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin;color:#262626;letter-spacing:.1pt;mso-ansi-language:EN-US'><o:p></o:p></span></p>

<p class=MsoNoSpacing style='margin:0cm;mso-add-space:auto;background:white'><span
lang=EN-US style='font-size:11.0pt;font-family:"Calibri",sans-serif;mso-ascii-theme-font:
minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:minor-latin;
color:#262626;letter-spacing:.1pt;mso-ansi-language:EN-US'><o:p>&nbsp;</o:p></span></p>

<p class=MsoNormal><b><span lang=EN-US style='mso-bidi-font-family:Calibri;
mso-bidi-theme-font:minor-latin;mso-ansi-language:EN-US'>9. What is an IP
Address?<o:p></o:p></span></b></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:0cm;margin-top:0cm;
mso-margin-bottom-alt:8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span
lang=EN-US style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
mso-ansi-language:EN-US'>If you are connected to the Internet, you will have an
IP address. This address is automatically gathered and recorded as part of your
computer's connection to our web server and can be used to determine the total
number of visits to each part of the site. It is also used to approximate the
country's location and assists in identifying potential malicious activity.<br>
<br>
<b>10. Google API Services User Data Policy<br>
</b>Link to the policy: </span><a
href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"><span
lang=EN-US style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
mso-ansi-language:EN-US'>https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes</span></a><span
lang=EN-US style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin;
mso-ansi-language:EN-US'><br>
<br>
<b>11.</b> </span><strong><span style='font-family:"Calibri",sans-serif;
mso-ascii-theme-font:minor-latin;mso-hansi-theme-font:minor-latin;mso-bidi-theme-font:
minor-latin'>Who We Are and How to Contact Us<o:p></o:p></span></strong></p>

<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:8.0pt;mso-margin-bottom-alt:
8.0pt;mso-margin-top-alt:0cm;mso-add-space:auto'><span style='mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin'>When we refer to &quot;we,&quot; it
pertains to the Administrator and the website </span><a
href="https://app.danametrics.ai/" target="_new"><span style='mso-bidi-font-family:
Calibri;mso-bidi-theme-font:minor-latin'>Danametrics</span></a><span
style='mso-bidi-font-family:Calibri;mso-bidi-theme-font:minor-latin'>.<br
style='mso-special-character:line-break'>
            `,
            }}
          ></div>
        )}
      </CommonLayout>
      <div className="flex-grow-0">
        <Footer />
      </div>
    </div>
  );
};
