import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

type LineChartProps = {
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  xAxis: string[] | number[];
  dataLabels?: boolean;
  yAxis?: ApexYAxis;
  className?: string;
  title?: string;
  typeArea?: boolean;
  customColors?: string[];
};

const LineChart = (props: LineChartProps) => {
  let data = {
    type: "line" as "area" | "line",
    series: [
      {
        data: [
          { x: "8", y: 5432456245 },
          { x: "9", y: 1767980678 },
          { x: "10", y: 26243562345 },
          { x: "11", y: 15546785679 },
          { x: "12", y: 19345673 },
          { x: "13", y: 104679567 },
          { x: "14", y: 1534573457 },
          { x: "15", y: 125679567 },
          { x: "16", y: 18243562435 },
          { x: "17", y: 1156789567 },
          { x: "18", y: 10343573456 },
          { x: "19", y: 1556895680 },
        ],
      },
    ] as ApexAxisChartSeries | ApexNonAxisChartSeries,
    options: {
      chart: {
        type: "area",
        stacked: false,
        height: "100%",
        zoom: {
          type: "x",
          enabled: false,
          autoScaleYaxis: true,
        },
        toolbar: {
          show: false,
          autoSelected: "zoom",
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 6,
        colors: ["#FFF"],
        strokeColors: ["#44AD3E"],
      },
      title: {
        text: "Line Graph",
        align: "center",
        style: {
          fontSize: "16px",
          color: "#000",
          fontWeight: 700,
          fontFamily: "Inter",
        },
      },
      stroke: {
        curve: "straight",
        width: 2,
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return `${val} KZT`;
          },
        },
      },
      xaxis: {
        type: "numeric",
      },
      tooltip: {
        shared: true,
        y: {
          formatter: function (val) {
            return `${val} KZT`;
          },
        },
      },
    } as ApexOptions,
  };

  data.series = props.series;
  data.options.xaxis = { categories: props.xAxis };
  data.options.dataLabels = { enabled: props.dataLabels };
  data.options.yaxis = props.yAxis || data.options.yaxis;
  data.options.title = { text: props.title || "" };
  data.type = props.typeArea ? "area" : "line";
  data.options.stroke = {
    colors: props.customColors || ["#44AD3E"],
    curve: "straight",
    width: 2,
  };
  data.options.markers = {
    strokeColors: props.customColors || ["#44AD3E"],
    size: 6,
    colors: ["#FFF"],
  };

  return <ReactApexChart {...data} height="100%"/>;
};

export default LineChart;
