import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import React, { useRef } from "react";
import useChartLegendHelper from "./useChartLegendHelper";
import useChartWidthResizer from "./useChartWidthResizer";

// Здесь конфиг графика.
// То, что передаётся в пропсах, перезаписывает дефолтные значения.
const defaultData = {
  type: "line" as "line",
  series: [] as ApexAxisChartSeries | ApexNonAxisChartSeries,
  options: {
    chart: {
      height: "100%",
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "75%",
        endingShape: "rounded",
        dataLabels: {
          orientation: "vertical",
          position: "center",
          formatter: (val: string) => val + " KZT",
        },
      },
    },
    stroke: {
      show: false,
    },
    markers: {
      size: 8,
      strokeWidth: 0,
      hover: {
        size: 8,
      },
    },
    xaxis: {
      type: "category",
      categories: [] as string[],
      labels: {
        show: true,
        rotate: 270,
        rotateAlways: true,
        style: {
          fontSize: "10px",
          fontFamily: "Inter",
          fontWeight: 700,
        },
      },
    },
    legend: {
      markers: {
        radius: 0,
      },
    },
  } as ApexOptions,
};

/**
 * Компонент комбинированного графика. Есть столбцы, есть линия.
 * @param series - данные для графика. Не забудьте указать тип данных ("columns" | "line").
 * @param xAxis - ось X. Массив строк. Например, рекламные кабинеты.
 * @param dataLabels - (опц.) показывать ли значения на столбцах.
 * @param formatter - (опц.) функция для форматирования значений на столбцах.
 * @param showYAxis - (опц.) показывать ли ось Y слева.
 * @param showOppositeYAxis - (опц.) показывать ли ось Y справа.
 * @param className - (опц.) класс для контейнера графика.
 */
type BarLineChartProps = {
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  xAxis: string[];
  dataLabels?: boolean;
  formatter?: (val: any) => string;
  showYAxis?: boolean;
  showOppositeYAxis?: boolean;
  className?: string;
};

const BarLineChart = (props: BarLineChartProps) => {
  // Реф на div, в котором отображается график.
  // Используется далее как аргумент в хуках.
  const chartRef = useRef<HTMLDivElement>(
    null
  ) as React.MutableRefObject<HTMLDivElement>;

  // Хук для отображения легенды графика. 
  // Переносит легенду в отдельный div, не перемещающийся при скролле.
  useChartLegendHelper(chartRef);

  // Хук для изменения ширины графика при изменении ширины окна.
  const chartWidth = useChartWidthResizer(props.xAxis, chartRef);

  // Перезаписываем дефолтные значения на основе props.
  // IIFE для того, чтобы не мутировать дефолтные значения.
  const data = (() => {
    const data = defaultData;
    data.series = props.series;
    if (data.options.xaxis) data.options.xaxis.categories = props.xAxis;
    data.options.dataLabels = { enabled: props.dataLabels };
    const yAxes = [] as ApexYAxis[];
    yAxes.push({ show: props.showYAxis as boolean });
    yAxes.push({ opposite: true, show: props.showOppositeYAxis as boolean });
    data.options.yaxis = yAxes;
    return data;
  })();

  return (
    <div
      ref={chartRef}
      className={`wrapper relative overflow-x-auto overflow-y-hidden h-[calc(100%-1rem)] min-h-[15rem] pb-4 
      ${props.className ? props.className : ""}`}
    >
      <ReactApexChart {...data} width={chartWidth} height="100%" />
    </div>
  );
};

export default BarLineChart;
