import { ReactNode } from "react";

export const TextFieldWrapper = (
  props: { label: string; children: React.ReactNode; className?: string },
) => (
  <div
    className={`flex flex-col justify-start items-start gap-1 ${
      props.className || ""
    }`}
  >
    <div className="text-sm font-semibold text-black">{props.label}</div>
    {props.children}
  </div>
);
